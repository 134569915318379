import { ReconcileModule } from './modules/reconcile/reconcile.module';
import { InterceptorService } from './core/services/interceptor.service';
import { ShareModule } from './share.module';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { LoginModule } from './modules/login/login.module';
import { OrderModule } from './modules/order/order.module';
import { ApilogModule } from './modules/apilog/apilog.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface,
    PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';

// Import routing module
import { AppRoutingModule } from './app-routing.module';

// Import app component
import { AppComponent } from './app.component';
import { AgGridModule } from 'ag-grid-angular';

// Import containers
import {
    DefaultFooterComponent,
    DefaultHeaderComponent,
    DefaultLayoutComponent,
} from './containers';

import { IconSetService } from '@coreui/icons-angular';
import { CampaignroleModule } from './modules/campaignrole/campaignrole.module';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../environments/environment';
import { LogoutModule } from './modules/logout/logout.module';
import { UserManagmentModule } from './modules/user-managment/user-managment.module';
import { MessageService } from 'primeng/api';
import { ConfigModule } from './modules/config/config.module';
import { OndemandModule } from './modules/ondemand/ondemand.module';
import { OndemandImportModule } from './modules/ondemand-import/ondemand-import.module';

const oktaAuth = new OktaAuth({
    issuer: environment.okta_issuer,
    clientId: environment.okta_client_id,
    redirectUri: environment.okta_redirect_uri
  });

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};

const APP_CONTAINERS = [
    DefaultFooterComponent,
    DefaultHeaderComponent,
    DefaultLayoutComponent,
];

@NgModule({
    declarations: [AppComponent, ...APP_CONTAINERS],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ShareModule,
        PerfectScrollbarModule,
        ReactiveFormsModule,
        DashboardModule,
        OrderModule,
        ReconcileModule,
        ApilogModule,
        HttpClientModule,
        LoginModule,
        LogoutModule,
        CampaignroleModule,
        OktaAuthModule,
        AgGridModule.withComponents([]),
        UserManagmentModule,
        ConfigModule,
        OndemandModule,
        OndemandImportModule
    ],
    providers: [
        { provide: OKTA_CONFIG, useValue: { oktaAuth } },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        },
        IconSetService,
        Title,
        MessageService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
