import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

export interface Params {
    site: string;
}


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    //site;
    params: any;
    isAuthenticated!: boolean;

    constructor(
        private router: Router,
        private userService: UserService,
        @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    ) {

    }

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ){
        if(environment.okta_login){
            this.isAuthenticated = await this.oktaAuth.isAuthenticated();
            if(!this.isAuthenticated){
                this.router.navigateByUrl("/login");
                return false;
            }
            return true;
        }else{
            if(!this.userService.isLoggedIn()){
                this.router.navigateByUrl("/login");
                return false;
            }
            return true;
        }
    }

}
