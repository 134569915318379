import { Component, Inject, isDevMode, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { AuthUser, AuthJwt, User, UserInfo, UserPerm } from "src/app/core/models/auth/user.model";
import { LoginResponseModel } from "src/app/core/models/auth/login.model";
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';

declare const bootstrap: any;

export interface OktaClaims {
    userName: string | null;
    fullName: string | null;
    email: string | null;
    profileName: string | null;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    isOktaLogin: boolean = false;
    messageAlertModal: any;
    messageAlert: any = {
        title: '',
        message: '',
        button: []
    }
    isWaiting: boolean = false;
    username: string = '';
    password: string = '';

    constructor(
        @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
        private userService: UserService,
        private router: Router
    ) {

    }

    async ngOnInit() {
        if (environment.okta_login) {
            this.isOktaLogin = true;
        }

        if (!this.isOktaLogin) {
            return;
        }

        const isAuthenticated = await this.oktaAuth.isAuthenticated();
        if (!isAuthenticated) {
            await this.oktaAuth.signInWithRedirect();
            return;
        }

        const userClaims = await this.oktaAuth.getUser();
        const body: OktaClaims = {
            userName: <string>userClaims['nativeid'] || null,
            fullName: <string>userClaims['given_name'] || null,
            email: <string>userClaims['email'] || null,
            profileName: <string>userClaims['preferred_username'] || null,
        }
        // console.log(userClaims);
        // const userName = <string>userClaims['nativeid'] || '';
        this.userService.getToken().subscribe({
            next: (v) => {
                let jwtResponse = v as AuthJwt;
                this.userService.setJwt(jwtResponse.jwtToken);
                this.userService.getUserByUserName(body).subscribe({
                    next: (v: any) => {
                        let loginResponse = v as LoginResponseModel;
                        const loginInfo: any = v.data;
                        // set data to session
                        this.userService.setUserInfo(loginInfo.profile);
                        this.userService.setUserPerm(loginInfo.perm);

                        // navigate to home page

                        this.router.navigate(['/dashboard']);

                    },
                    error: (e) => {
                        const self = this;
                        this.messageAlert = {
                            title: 'Warning',
                            message: 'The username is incorrect',
                            button: [
                                {
                                    label: 'Close', action: async function () {
                                        self.messageAlertModal.hide();
                                        self.userService.logout();
                                        await self.oktaAuth.signOut();
                                    }.bind(this)
                                }]
                        }
                        this.messageAlertModal.show();
                    },
                    complete: () => {
                        //console.info('complete');
                        //this.alertService.alertSuccess("complete");
                    }
                });
            },
            error: (e) => {
                let errMsg = (e.message) ? e.message : e.status ? `${e.status} - ${e.statusText}` : 'Server error';

                console.error(errMsg);
                const self = this;
                this.messageAlert = {
                    title: 'Warning',
                    message: errMsg,
                    button: [
                        {
                            label: 'Close', action: async function () {
                                self.messageAlertModal.hide();
                                self.userService.logout();
                                await self.oktaAuth.signOut();
                            }.bind(this)
                        }]
                }
                this.messageAlertModal.show();
            }
        });
    }

    ngAfterViewInit(): void {
        this.messageAlertModal = new bootstrap.Modal(document.getElementById('messageAlertModal'));
    }

    login() {

        // check repeat click
        if (this.isWaiting == true) {
            return;
        }

        if (this.validationData()) {
            this.isWaiting = true;
            this.userService.getToken().subscribe({
                next: (v) => {
                    let jwtResponse = v as AuthJwt;
                    this.userService.setJwt(jwtResponse.jwtToken);
                    this.userService.login(this.username, this.password).subscribe({
                        next: (v: any) => {
                            let loginResponse = v as LoginResponseModel;
                            const loginInfo: any = v.data;
                            // set data to session
                            this.userService.setUserInfo(loginInfo.profile);
                            this.userService.setUserPerm(loginInfo.perm);

                            // navigate to home page
                            this.router.navigate(['/dashboard']);
                            this.isWaiting = false;

                        },
                        error: (e) => {
                            const self = this;
                            this.messageAlert = {
                                title: 'Warning',
                                message: 'The username or password is incorrect',
                                button: [
                                    {
                                        label: 'Close', action: async function () {
                                            self.messageAlertModal.hide();
                                        }.bind(this)
                                    }]
                            }
                            this.messageAlertModal.show();
                            this.isWaiting = false;
                        },
                        complete: () => {
                            //console.info('complete');
                            //this.alertService.alertSuccess("complete");
                            this.isWaiting = false;
                        }
                    });
                },
                error: (e) => {
                    let errMsg = (e.message) ? e.message : e.status ? `${e.status} - ${e.statusText}` : 'Server error';

                    console.error(errMsg);
                    const self = this;
                    this.messageAlert = {
                        title: 'Warning',
                        message: errMsg,
                        button: [
                            {
                                label: 'Close', action: async function () {
                                    self.messageAlertModal.hide();
                                }.bind(this)
                            }]
                    }
                    this.messageAlertModal.show();
                    this.isWaiting = false;
                }
            });
        }
    }

    validationData() {
        let checkedData = true;
        if (!this.username) {
            checkedData = false;
        }
        if (!this.password) {
            checkedData = false;
        }
        if (!checkedData) {
            const self = this;
            this.messageAlert = {
                title: 'Warning',
                message: 'Please enter username and password',
                button: [
                    {
                        label: 'Close', action: async function () {
                            self.messageAlertModal.hide();
                        }.bind(this)
                    }]
            }
            this.messageAlertModal.show();
        }

        return checkedData;
    }

}
