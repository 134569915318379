import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PreviewFileService } from 'src/app/core/services/preview-file.service';
import * as moment from 'moment';
@Component({
    selector: 'app-preview-file',
    templateUrl: './preview-file.component.html',
    styleUrls: ['./preview-file.component.scss']
})
export class PreviewFileComponent implements OnInit {

    constructor(
        private router: Router,
        private acRoute: ActivatedRoute,
        private previewFileService: PreviewFileService,
        private location: Location
    ) {
        let url = this.acRoute.snapshot?.queryParams?.['url'];
        let typeRespone = this.acRoute.snapshot?.queryParams?.['typeRespone'];

        let objFilePreview = localStorage.getItem('FILE_PREVIEW');
        localStorage.removeItem('FILE_PREVIEW');
        location.replaceState('/previewfile');

        if (url) {

            // ex let urlApi = `${environment.api_url}/apibo/call/docondemandexportpdf?${new URLSearchParams({
            //     filePath : data?.filePath,
            //     fileName : data?.fileName
            // })}`
            // const url = this.router.serializeUrl(this.router.createUrlTree(['/previewfile'],{ queryParams: { url: encodeURIComponent(urlApi), typeRespone : 'blob' } }));
            // window.open(url, '_blank');

            let decodeUrl = decodeURIComponent(url);
            switch (typeRespone) {
                case 'blob':
                    this.getFileBlob(decodeUrl);
                    break;
                default:
                    this.getFileBase64(decodeUrl);
                    break;
            }

        } else if (objFilePreview) {
            //  ex
            // let b64 = data.urlPdf;
            // let filePreview = {
            //   base64 : b64,
            //   downloadName : this.abo + '_WHT'+ data.taxYear + '_' + Utils.dateFormatYearsMonth(new Date(),Utils.YEARS_MONTH_DATE)  +'.pdf'
            // }
            // localStorage.setItem('FILE_PREVIEW',JSON.stringify(filePreview));
            // window.open('/previewfile', '_blank');

            let filePreview = JSON.parse(objFilePreview);
            let base64 = filePreview?.base64;
            let downloadName = filePreview?.downloadName;
            this.openPreviewBase64(base64, downloadName)
        } else {
            window.self.close();
        }
    }

    getFileBlob(decodeUrl: string) {
        this.previewFileService.getFileBlob(decodeUrl).subscribe(
            {
                next: async (result: any) => {
                    this.openPreviewFile(result);
                },
                error: (error: any) => {
                    console.error(error);
                }
            }
        );
    }
    getFileBase64(decodeUrl: string) {
        this.previewFileService.getFileBase64(decodeUrl).subscribe(
            {
                next: async (result: any) => {
                    this.openPreviewBase64(result);
                },
                error: (error: any) => {
                    console.error(error);
                }
            }
        );
    }

    openPreviewBase64(b64: string, download?: string) {
        const byteCharacters = atob(b64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        let blob: any = new Blob([byteArray], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('href', url);
            document.body.appendChild(link);
            link.click();
            link.setAttribute('download',  download ? download : moment().format('YYYYMMDD'));
            document.body.appendChild(link);
            link.click();
            link.remove();
            // this.isLoading = false;
    }


    ngOnInit(): void {
    }

    openPreviewFile(base64: any) {
        let blob: any = new Blob([base64], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        // link.setAttribute('target', '_blank');
        link.setAttribute('href', url);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

}
