import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { OndemandService } from 'src/app/core/services/ondemand.service';
import { ColDef } from 'ag-grid-community';
import { OndemandImportResponseDataModel } from 'src/app/core/entity/ondemandImport';
import { ActionButtonOndemandImportLog } from '../../components/action-button-ondemand-import-log.component';
declare var $: any;
declare const bootstrap: any;

const DOC_TYPE_WHT = "wht";
const DOC_TYPE_BONUS = "bonus";
interface OndemandImportformData {
    endDate: string | Date;
    startDate: string | Date;
    docType: string;
    fileName: string;
    aboNumber : string;
}

interface AgGridOptionModel {
    gridApi: any,
    rowData: OndemandImportResponseDataModel[],
    columnDefs: ColDef[],
    defaultColDef: ColDef,
    frameworkComponents: any
}
@Component({
  selector: 'app-import-error',
  templateUrl: './import-error.component.html',
  styleUrls: ['./import-error.component.scss']
})
export class ImportErrorComponent implements OnInit {
    elementname = 'OndemandImportError';

    messageAlert: any = {
      title: '',
      message: '',
      button: []
    }

    pageinfo = {
      page: 1,
      rowPerPage: 50,
      totalRecord: 0,
      allpage: 0
  }

    searchOption: OndemandImportformData = {
        endDate: new Date(),
        startDate: new Date(),
        docType: DOC_TYPE_WHT,
        fileName: '',
        aboNumber: ''
    }

    listDocType = [{name:'Withholding TAX',value:DOC_TYPE_WHT},{name:'Bonus Statement',value:DOC_TYPE_BONUS}];
    searched = false;
    currentDatetime: string = '';
    detailLog! : OndemandImportResponseDataModel
    agGridOption: AgGridOptionModel = {
      gridApi: null,
      rowData: [],
      defaultColDef: {
          sortable: false,
          suppressMovable: true,
          filter: false,
          comparator: () => { return 0 },
          sortingOrder: ['asc', 'desc']
      },
      frameworkComponents: {
          actionButton: ActionButtonOndemandImportLog,
      },
      columnDefs: [
          {
              headerName: 'View',
              cellRenderer: 'actionButton',
              cellRendererParams: {
                  clickedOpenView: (data: any) => {
                      // console.log(data);
                      this.detailLog = data
                      const ondemandDetailModal = new bootstrap.Modal(document.getElementById('ondemand_detail_modal'));
                      ondemandDetailModal.show();

                      // this.modalDownloadPdf(data);
                  }
              },
              width: 110
          },
          {
              headerName: 'Import datetime',
              cellRenderer: (param: any) => {
                  return moment(param.data.importDatetime).format('YYYY-MM-DD HH:mm:ss')
              },
              width: 180,
          }, {
              headerName: 'File name',
              field: 'fileName',
              width: 270,
          }, {
              headerName: 'Import type',
              field: 'docType',
              width: 150,
          }, {
              headerName: 'Year',
              field: 'year',
              width: 100,
          }, {
              headerName: 'Period',
              field: 'period',
              width: 100,
          }, {
              headerName: 'ABO Number',
              field: 'aboNumber',
              width: 120,
          }, {
              headerName: 'Error Description',
              field: 'errorDesc',
              flex: 2
          }
      ]
  };

    constructor(
      private headerNavigateService:HeaderNavigateService,
      private ondemandService : OndemandService
    ) { }

    ngOnInit(): void {
      this.initialHeaderNavigate();
    }

    initialHeaderNavigate() {
      let headerNavigateModelList: Array<HeaderNavigateModel> = [
          { label: 'Home', url: '/', attributes: { title: 'Home' } },
          { label: 'Ondemand Import', url: '/ondemandimport' },
      ];
      this.headerNavigateService.setNavigate(headerNavigateModelList);
  }

  searchLogOndemand() {
      const loadingModal = new bootstrap.Modal(document.getElementById(`${this.elementname}loadingModal`), { backdrop: 'static', keyboard: false });
      loadingModal.show();
      let request = {
          page: this.pageinfo.page,
          rowPerPage: this.pageinfo.rowPerPage,
          docType: this.searchOption.docType,
          fileName: this.searchOption.fileName ?? '',
          startDate:new Date(moment(this.searchOption.startDate).format('YYYY-MM-DD 00:00:00')).toISOString(),
          endDate:new Date(moment(this.searchOption.endDate).format('YYYY-MM-DD 23:23:59')).toISOString(),
          aboNumber : this.searchOption.aboNumber,
          orderBy:  'importDatetime desc, id desc'
      }
      this.ondemandService.listOndemandErrorLog(request).subscribe({
          next: (res) => {
              // console.log(res);
              setTimeout(() => {
                      loadingModal.hide();
              }, 500);

              this.agGridOption.rowData = [...res?.data ?? []];
              // this.requestId = res?.requestId;
              const newPageInfo =  {
                  page: res.pageinfo.page,
                  rowPerPage: res.pageinfo.rowPerPage,
                  totalRecord: res.pageinfo.totalRecord,
                  allpage: Math.ceil(res.pageinfo.totalRecord / res.pageinfo.rowPerPage)
              }
              this.pageinfo = newPageInfo;
              this.currentDatetime = moment().format('DD/MM/YYYY HH:mm:ss');
              this.searched = true;

          }, error: (e: any) => {
              // console.log(e);
              setTimeout(() => {
                  loadingModal.hide();
              }, 500);
              this.alertMessage('danger', 'Error!', 'bx bx-error-circle', e?.error?.error?.errorMsg);

          },
      });
  }

  resetSearch(){
      this.searchOption.docType= DOC_TYPE_WHT;
      this.searchOption.startDate= new Date();
      this.searchOption.endDate= new Date();
      this.searched = false;
      this.searchOption.aboNumber= '';
  }

  //Alert Modal
  alertMessage(theme: string, title: string, icon: string | null, message: string) {
      const messageAlertModal = new bootstrap.Modal(document.getElementById('messageAlertModal'));
      const self = this;
      this.messageAlert = {
          theme: theme,
          title: title,
          icon: icon,
          message: message,
          button: [
              {
                  label: 'OK',
                  action: async function () {
                      messageAlertModal.hide();
                  }.bind(this),
                  color: 'warning'
              }
          ]
      };
      messageAlertModal.show();
  }

  async gotoPage(page: any) {
      if (page > 0 && page <= this.pageinfo.allpage) {
          this.pageinfo.page = page;
          this.searchLogOndemand();
      }
  }

  }
