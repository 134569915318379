import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataTypeValidationService {
    constructor() { }

    validateNumber(input: any) {
        const isNumber = new RegExp('^[0-9]+$', 'g');
        return isNumber.test(input);
    }
}
