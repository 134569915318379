import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Apilog } from '../entity/apilog';

@Injectable({
    providedIn: 'root'
})
export class ApilogService {

    constructor(private http: HttpClient) { }

    private formatErrors(error: any) {
        return throwError(error);
    }

    listHistoryApi(option: { q: any, pageinfo: any }) {

        let url = `${environment.api_url}/apibo/call/history-api?page=${option.pageinfo.page}&rowPerPage=${option.pageinfo.rowPerPage}`;

        for (let key in option.q) {
            if (option.q[key]) {
                url += `&${key}=${option.q[key]}`;
            }
        }
        return this.http.get(url).pipe(catchError(this.formatErrors));
    }

}
