<c-row>
    <c-col>
        <h2 cCardTitle class="mb-2">
            <strong class="text-primary">Order Payment Detail</strong>
        </h2>
        <c-card class="mb-3">
            <c-card-body>
                <form [classList]="'mt-2'" novalidate cForm class="needs-validation mt-2" cForm>
                    <c-row [classList]="'px-2'">
                        <c-col [lg]="4">
                            <c-row class="mb-3">
                                <c-col [lg]="5">
                                    <label for="search_start_date" class="p-1 float-end">Start Date <span
                                            class="star-required"></span>:</label>
                                </c-col>
                                <c-col [lg]="7">
                                    <p-calendar [id]="'search_start_date'" [name]="'search_start_date'"
                                        class="is-invalid" dateFormat="dd/mm/yy" [(ngModel)]="searchOption.startDate"
                                        [styleClass]="'date-form'" [readonlyInput]="true">
                                    </p-calendar>
                                </c-col>
                            </c-row>
                        </c-col>
                        <c-col [lg]="4">
                            <c-row class="mb-3">
                                <c-col [lg]="5">
                                    <label for="search_end_date" class="p-1 float-end">End Date <span
                                            class="star-required"></span>:</label>
                                </c-col>
                                <c-col [lg]="7">
                                    <p-calendar [id]="'search_end_date'" [name]="'search_end_date'"
                                        dateFormat="dd/mm/yy" [(ngModel)]="searchOption.endDate"
                                        [styleClass]="'date-form'" [readonlyInput]="true">
                                    </p-calendar>
                                </c-col>
                            </c-row>
                        </c-col>
                        <c-col [lg]="4">
                            <c-row class="mb-3">
                                <c-col [lg]="6" [xs]="11">
                                    <label for="search_by_order_date" class="p-1 float-end">No order invoice :</label>
                                </c-col>
                                <c-col [lg]="6" [xs]="1">
                                    <input class="float-lg-start float-end form-check-input mt-2" cFormCheckInput
                                        type="checkbox" id="search_by_order_date" [checked]="searchOption.noInv"
                                        (change)="searchOption.noInv = !searchOption.noInv " />
                                </c-col>
                            </c-row>
                        </c-col>
                    </c-row>
                </form>
                <c-row class="mt-4">
                    <c-col [lg]="2" [offset]="{ lg: 4 }">
                        <input name="search_btn" cButton color="primary" type="button" value="Search"
                            class="rounded mb-2 w-100" (click)="searchOrderPayment(true)" />
                    </c-col>
                    <c-col [lg]="2">
                        <input name="reset_btn" cButton color="primary-50" type="button" value="Reset"
                            class="rounded w-100" (click)="onClickReset()" />
                        <!--px-4 me-2 -->
                    </c-col>
                </c-row>
            </c-card-body>
        </c-card>
    </c-col>
</c-row>
<c-row class="mt-3">
    <c-col *ngIf="searched">
        <h3 cCardTitle class="mb-2"><strong class="text-primary">Result</strong></h3>
        <c-card class="mb-3">
            <c-card-body>
                <c-row>
                    <c-col [sm]="6">
                        <h6>Total {{pageInfo.totalRecord}} records Update on {{currentDatetime}}</h6>
                    </c-col>
                </c-row>
                <c-row>
                    <c-col [sm]="12" class="mb-2 d-flex justify-content-between">
                        <c-dropdown>
                            <button cButton cDropdownToggle id="btn_export_file" color="primary">
                                Export
                            </button>
                            <ul cDropdownMenu>
                                <li><a cDropdownItem (click)="exportOrderDetail('xlsx')">Excel</a></li>
                                <li><a cDropdownItem (click)="exportOrderDetail('csv')">CSV</a></li>
                            </ul>
                        </c-dropdown>
                        <div class="float-end">
                            <span class="me-1">Show</span>
                            <select class="me-1" id="search_option_limit" (change)="searchOrderPayment(true)"
                                [(ngModel)]="pageInfo.rowPerPage">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                            <span>entries</span>
                        </div>
                    </c-col>
                    <c-col [sm]="12" class="mb-2">

                        <ag-grid-angular style="width: 100%; height: 50vh;"   class="ag-theme-alpine" [defaultColDef]="defaultColDef" [columnDefs]="orderColumnDefs"
                            [rowData]="rowData" [enableCellTextSelection]="true" [enableRangeSelection]="true">
                        </ag-grid-angular>
                    </c-col>
                    <c-col [sm]="12">
                        <app-paging-tool [pageinfo]="pageInfo" (onClickPage)="gotoPage($event)">
                        </app-paging-tool>
                    </c-col>
                </c-row>
            </c-card-body>
        </c-card>
    </c-col>
</c-row>

<app-message-alert-modal [messageAlert]="messageAlert"></app-message-alert-modal>
<div class="modal fade" id="loadingModal" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                </div>
            </div>
        </div>
    </div>
</div>
