import { filter } from 'rxjs/operators';
import { UserService } from './../../core/services/user.service';
import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { environment } from "src/environments/environment";

// https://www.ag-grid.com/javascript-data-grid/component-cell-renderer/
// https://blog.ag-grid.com/cell-renderers-in-ag-grid-every-different-flavour/
@Component({
    selector: 'action-button-order-list',
    template: `
      <button hidden (click)="sendEmail()" name="btn_view_order" id="btn_view_order" style="height: 30px;" class="btn btn-pill btn-primary me-2 py-0" *ngIf="this.orderData.hasSendmailIcon && orderData.voidStatus == 'Active'">Send mail</button>
      <button (click)="downloadPdf()" name="btn_download_pdf" id="btn_download_pdf" style="height: 30px;" class="btn btn-pill btn-primary-50 me-2 py-0" *ngIf="orderData.hasPDFIcon">PDF</button>
      <button (click)="downloadXml()" name="btn_download_xml" id="btn_download_xml" style="height: 30px;" class="btn btn-pill btn-primary-50 me-2 py-0" *ngIf="orderData.hasXmlIcon">XML</button>
    `,
})
export class ActionButtonOrderDetailList implements ICellRendererAngularComp {
    private params: any;
    public orderData: any;

    agInit(params: any): void {
        this.params = params;
        this.orderData = this.params.data;
    }

    sendEmail() {
        this.params.clickedSendEmail(this.params.data);
    }

    downloadPdf() {
        this.params.clickedDownloadPdf(this.params.data);
    }

    downloadXml() {
        this.params.clickedDownloadXml(this.params.data);
    }

    refresh(params: ICellRendererParams): boolean {
        return true;
    }
}

@Component({
    selector: 'action-button-source-list',
    template: `
      <button (click)="downloadKinesis()" name="btn_download_kinesis" id="btn_download_kinesis" style="height: 30px;" class="btn btn-pill btn-primary-50 me-2 py-0" *ngIf="orderData.docInputKinesisFileName && orderData.eventCode == '${environment.nextgen_appId}'">Nextgen</button>
      <button (click)="downloadKinesis()" name="btn_download_kinesis" id="btn_download_kinesis" style="height: 30px;" class="btn btn-pill btn-primary-50 me-2 py-0" *ngIf="orderData.docInputKinesisFileName && orderData.eventCode != '${environment.nextgen_appId}'">Hybris</button>
      <button (click)="downloadInet()" name="btn_download_inet" id="btn_download_inet" style="height: 30px;" class="btn btn-pill btn-primary-50 me-2 py-0" *ngIf="orderData.docOutputInetFileName">INET</button>
      <button (click)="openLog()" name="btn_open_log" id="btn_open_log" style="height: 30px;" class="btn btn-pill btn-primary-50 me-2 py-0">Log</button>
      <button (click)="uploadKinesis()" name="btn_upload_kinesis" id="btn_upload_kinesis" style="height: 30px;" class="btn btn-pill btn-primary me-2 py-0" *ngIf="uploadKinesisPerm">Event Upload</button>
      <button (click)="resendInet()" name="btn_resend_inet" id="btn_resend_inet" style="height: 30px;" class="btn btn-pill btn-primary me-2 py-0" *ngIf="uploadKinesisPerm">Resend INET</button>
      <button (click)="onClickedUpdateinet()" name="btn_resend_inet" id="btn_resend_inet" style="height: 30px;" class="btn btn-pill btn-success-50 me-2 py-0" *ngIf="updateinetPerm">Update inet code</button>
      <button (click)="triggerHybris()" name="btn_trigger_hybris" id="btn_trigger_hybris" style="height: 30px;" class="btn btn-pill btn-primary me-2 py-0" *ngIf="trigerHybrisPerm && orderData.eventCode == '${environment.nextgen_appId}'">Trigger eMail</button>
      <button (click)="triggerHybris()" name="btn_trigger_hybris" id="btn_trigger_hybris" style="height: 30px;" class="btn btn-pill btn-primary me-2 py-0" *ngIf="trigerHybrisPerm && orderData.eventCode != '${environment.nextgen_appId}'">Trigger eMail</button>
      <button (click)="onClickDelete()" name="btn_delete" id="btn_delete" style="height: 30px;" class="btn btn-pill btn-danger-50 me-2 py-0" *ngIf="deletePerm">Delete</button>

    `,
})
export class ActionButtonSourceOrderDetailList implements ICellRendererAngularComp {
    private params: any;
    public orderData: any;
    public userPerm: any = [];
    public uploadKinesisPerm: boolean = false;
    public trigerHybrisPerm: boolean = false;
    public deletePerm: boolean = false;
    public updateinetPerm: boolean = false;
    constructor(
        private userService: UserService,
    ) { }

    agInit(params: any): void {
        this.params = params;
        this.orderData = this.params.data;
        this.userPerm = this.userService.getUserPerm();
        console.log(this.orderData,this.userPerm);

        const permKinesis: any = this.userPerm.filter((row: any) => row.permcode == 'menu.ordersearch.editkinesis');
        this.uploadKinesisPerm = (this.orderData.hasKinesisIcon && (permKinesis.length && permKinesis[0].canView)) ? true : false;
        const permHybris: any = this.userPerm.filter((row: any) => row.permcode == 'menu.ordersearch.hybristrigger');
        this.trigerHybrisPerm = (this.orderData.hasTriggerIcon && (permHybris.length && permHybris[0].canView)) ? true : false;
        const permDelete: any = this.userPerm.filter((row: any) => row.permcode == 'menu.ordersearch.delete');
        this.deletePerm = (permDelete.length && permDelete[0].canView) ? true : false;
        const permUpdateinet: any = this.userPerm.filter((row: any) => row.permcode == 'menu.ordersearch.updateinet');
        this.updateinetPerm = (permUpdateinet.length && permUpdateinet[0].canView) ? true : false;
    }

    downloadKinesis() {
        this.params.clickedDownloadKinesis(this.params.data);
    }

    downloadInet() {
        this.params.clickedDownloadInet(this.params.data);
    }

    openLog() {
        this.params.clickOpenLog(this.params.data);
    }

    uploadKinesis() {
        this.params.clickUploadKinesis(this.params.data);
    }

    triggerHybris() {
        this.params.clickedTriggerHybris(this.params.data);
    }

    resendInet() {
        this.params.clickedResendInet(this.params.data);
    }

    onClickDelete() {
        this.params.clickedDelete(this.params.data);
    }

    onClickedUpdateinet() {
        this.params.clickedUpdateinet(this.params.data);
    }

    refresh(params: ICellRendererParams): boolean {
        return true;
    }
}
