<c-container>
    <c-row>
        <c-col>
            <c-card class="mb-3">
                <c-card-body>
                    <form cForm>
                        <c-row class="mb-2">
                            <c-col [lg]="6">
                                <c-row class="mb-3">
                                    <c-col [lg]="3">
                                        <label class="w-100 text-end" cCol cLabel="col" for="search_period">
                                            Period :
                                        </label>
                                    </c-col>
                                    <c-col [lg]="9">
                                        <input class="form-control" type="month" aria-label="search_period"
                                            data-date-format="yyyyMM" id="search_period" name="search_period"
                                            [(ngModel)]="searchOption.q.period" (change)="changePeriod($event)">
                                    </c-col>
                                </c-row>
                            </c-col>
                            <c-col [lg]="6">
                                <c-row>
                                    <c-col [lg]="6">
                                        <c-row class="mb-3">
                                            <c-col [lg]="6">
                                                <label class="w-100 text-end" cCol cLabel="col" for="search_date_from">
                                                    Date :
                                                </label>
                                            </c-col>
                                            <c-col [lg]="6">
                                                <select name="search_date_from" class="form-select"
                                                    aria-label="search_date_from" [(ngModel)]="searchOption.q.dateFrom"
                                                    cSelect>
                                                    <option *ngFor="let option of dateList" value="{{ option }}">
                                                        {{ option }}
                                                    </option>
                                                </select>
                                            </c-col>
                                        </c-row>
                                    </c-col>
                                    <c-col [lg]="6">
                                        <c-row class="mb-3">
                                            <c-col [lg]="6">
                                                <label class="w-100 text-end" cCol cLabel="col" for="search_date_to">
                                                    To :
                                                </label>
                                            </c-col>
                                            <c-col [lg]="6">
                                                <select name="search_date_to" class="form-select"
                                                    aria-label="search_date_to" [(ngModel)]="searchOption.q.dateTo"
                                                    cSelect>
                                                    <option *ngFor="let option of dateList" value="{{ option }}">
                                                        {{ option }}
                                                    </option>
                                                </select>
                                            </c-col>
                                        </c-row>
                                    </c-col>
                                </c-row>
                            </c-col>
                            <c-col [lg]="6">
                                <c-row class="mb-3">
                                    <c-col [lg]="3">
                                        <label class="w-100 text-end" cCol cLabel="col" for="search_client_id">
                                            Client ID :
                                        </label>
                                    </c-col>
                                    <c-col [lg]="9">
                                        <input aria-label="search_client_id" type="text" name="search_client_id"
                                            id="search_client_id" [(ngModel)]="searchOption.q.clientId" cFormControl />
                                    </c-col>
                                </c-row>
                            </c-col>
                            <c-col [lg]="6">
                                <c-row class="mb-3">
                                    <c-col [lg]="3">
                                        <label class="w-100 text-end" cCol cLabel="col" for="search_api_path">
                                            API Path :
                                        </label>
                                    </c-col>
                                    <c-col [lg]="9">
                                        <input aria-label="search_api_path" type="text" name="search_api_path"
                                            id="search_api_path" [(ngModel)]="searchOption.q.apiPath" cFormControl />
                                    </c-col>
                                </c-row>
                            </c-col>
                            <c-col [lg]="6">
                                <c-row class="mb-3">
                                    <c-col [lg]="3">
                                        <label class="w-100 text-end" cCol cLabel="col" for="search_request_id">
                                            Request ID :
                                        </label>
                                    </c-col>
                                    <c-col [lg]="9">
                                        <input aria-label="search_request_id" type="text" name="search_request_id"
                                            id="search_request_id" [(ngModel)]="searchOption.q.requestId"
                                            cFormControl />
                                    </c-col>
                                </c-row>
                            </c-col>

                            <c-col [lg]="6">
                                <c-row>
                                    <c-col [lg]="3">
                                        <label class="w-100 text-end" cCol cLabel for="inputEmail-1">Status :</label>
                                    </c-col>
                                    <c-col [lg]="9">
                                        <c-row>
                                            <c-col [lg]="3" [xs]="10">
                                                <c-form-check class="float-lg-start float-end">
                                                    <input cFormCheckInput id="search_type_success"
                                                        name="search_type_success" value="success" type="checkbox"
                                                        (change)="onCheckboxTypeChange($event)" checked />
                                                    <label cFormCheckLabel for="search_type_success">Success</label>
                                                </c-form-check>
                                            </c-col>
                                            <c-col [lg]="3" [xs]="2">
                                                <c-form-check class="float-lg-start float-end">
                                                    <input cFormCheckInput id="search_type_fail" name="search_type_fail"
                                                        value="failed" type="checkbox"
                                                        (change)="onCheckboxTypeChange($event)" checked />
                                                    <label cFormCheckLabel for="search_type_fail">Fail</label>
                                                </c-form-check>
                                            </c-col>
                                        </c-row>
                                    </c-col>
                                </c-row>
                            </c-col>
                        </c-row>

                        <c-row>
                            <c-col [lg]="6">
                                <c-row>
                                    <c-col [lg]="3"></c-col>
                                    <c-col [lg]="4">
                                        <button cButton color="primary" (click)="search()" class="mb-2 w-100 text-white">
                                            Search
                                        </button>
                                    </c-col>
                                    <c-col [lg]="4">
                                        <button cButton color="light" (click)="clear()" class="w-100">
                                            Clear
                                        </button>
                                    </c-col>
                                </c-row>
                            </c-col>
                        </c-row>
                    </form>
                </c-card-body>
            </c-card>
        </c-col>
    </c-row>
</c-container>

<c-container *ngIf="searched">
    <c-row>
        <c-col>
            <c-card class="mb-3">
                <c-card-body>
                    <c-row>
                        <c-col [sm]="6">
                            <h6>Total {{searchOption.pageinfo.totalRecord}} records Update on {{currentDatetime}}</h6>
                        </c-col>
                    </c-row>
                    <c-row>
                        <c-col [sm]="12">
                            <ag-grid-angular  class="ag-theme-alpine" domLayout="autoHeight"
                                [rowData]="searchRowData" [columnDefs]="searchColumnDefs"
                                [frameworkComponents]="frameworkComponents" [enableCellTextSelection]="true">
                            </ag-grid-angular>
                        </c-col>
                        <c-col [classList]="'mt-2'" [sm]="12">
                            <app-paging-tool [pageinfo]="searchOption.pageinfo" (onClickPage)="gotoPage($event)">
                            </app-paging-tool>
                        </c-col>
                    </c-row>
                </c-card-body>
            </c-card>
        </c-col>
    </c-row>
</c-container>

<div class="modal fade" id="detail_modal" tabindex="-1" aria-labelledby="Detail Modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="word-break: break-all;">
                <div class="table_data_modal" aria-hidden="true">
                    <c-row [gutter]="{gy: 3}">
                        <c-col class="col-3">
                            <h6>Request ID : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{apiLogModalData.requestId}}
                        </c-col>
                        <c-col class="col-3">
                            <h6>UID : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{apiLogModalData.uId}}
                        </c-col>
                        <c-col class="col-3">
                            <h6>Lambda Name : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{apiLogModalData.lambdaName}}
                        </c-col>
                        <c-col class="col-3">
                            <h6>API Path : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{apiLogModalData.apiPath}}
                        </c-col>
                        <c-col class="col-3">
                            <h6>Client ID : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{apiLogModalData.clientId}}
                        </c-col>
                        <c-col class="col-3">
                            <h6>Request User : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{apiLogModalData.requestUser}}
                        </c-col>
                        <c-col class="col-3">
                            <h6>Request Datetime : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{apiLogModalData.requestDateTime}}
                        </c-col>
                        <c-col class="col-3">
                            <h6>Response Datetime : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{apiLogModalData.responseDateTime}}
                        </c-col>
                        <c-col class="col-3">
                            <h6>Status : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{apiLogModalData.status}}
                        </c-col>
                        <c-col class="col-3">
                            <h6>Error : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{apiLogModalData.errorMessage}}
                        </c-col>
                        <c-col class="col-3">
                            <h6>Request Body : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{apiLogModalData.requestBody}}
                        </c-col>
                        <c-col class="col-3">
                            <h6>Response body : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{apiLogModalData.responseBody}}
                        </c-col>
                    </c-row>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"> Close
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="loadingModal" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-message-alert-modal [messageAlert]="messageAlert"></app-message-alert-modal>
