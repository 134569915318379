import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private http: HttpClient) { }

  getConfigs(config: string, page: string, rowPerPage: string) {
    const params = config == '' ? `page=${page}&rowPerPage=${rowPerPage}` : `config=${config}&page=${page}&rowPerPage=${rowPerPage}`
    return this.http.get(`${environment.api_url}/apibo/call/getwebconfigs?${params}`);
  }


  updateConfig(body : any) {
    return this.http.post(`${environment.api_url}/apibo/call/updatewebconfig`, body);
  }
}
