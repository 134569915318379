import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TrndocactionService {

    constructor(
        private http: HttpClient,
    ) { }

    getLogs(uId: any, pageinfo: any): Observable<any> {
        let url = `${environment.api_url}/apibo/call/docactionlog?page=${pageinfo.page}&rowPerPage=${pageinfo.rowPerPage}&docUid=${uId}`;
        return this.http.get(url);
    }
}
