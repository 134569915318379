import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

declare const bootstrap: any;

@Component({
  selector: 'config-action-button',
  template: `
      <button *ngIf="this.actionBtnOptions.editConfig" (click)="onClickEnable()" class="btn btn-success mx-3 py-0 text-light"  type="button" style="height: 30px;">
        Edit Config
      </button>
    `,
})

export class ConfigActionButton implements ICellRendererAngularComp {
  enableLoading: boolean = false;
  actionBtnOptions: any;
  params: any;
  messageAlert: any = {
    title: '',
    message: '',
    button: []
  }


  agInit(params: any): void {
    this.params = params;
    this.actionBtnOptions = this.params.actionBtnOptions;
  }

  onClickEnable() {
    this.enableLoading = true;
    this.params.onClickEditConfig(this.params.data);
  }


  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
