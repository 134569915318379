import { Component, OnDestroy, OnInit } from '@angular/core';
import { ColDef, GetRowNodeIdFunc, IGetRowsParams, GridApi, GridReadyEvent } from 'ag-grid-community';
import { MessageService } from 'primeng/api';
import { UserService } from 'src/app/core/services/user.service';
import { UserRoleActionButton } from './user-role.actionbtn.component';
import { UserRoleService } from 'src/app/core/services/user-role.service';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
declare const bootstrap: any;

interface UsernameRole {
    username?: string,
    enable?: string,
    fullName?: string,
    profileName?: string,
    role?: string,
    status?: string,
    source?: string,
  }

  interface SearchOption {
    userName: string;
  }

  interface PageInfo {
    page: number;
    rowPerPage: number;
    totalRecord: number;
    allpage: number;
  }

  interface Role {
    name?: string
  }

@Component({
  selector: 'app-user-managment',
  templateUrl: './user-managment.component.html',
  styleUrls: ['./user-managment.component.scss']
})
export class UserManagmentComponent implements OnInit, OnDestroy {


    role: string = '';
    userName: string = '';
    usernameRoleList: UsernameRole[] = [];
    usernameRole: UsernameRole = {}
    pageNumber: number = 1;
    asingRoleConditionModal: boolean = false;
    isValidAbo: boolean = false;
    roles: Role[] = [
        { name: 'admin' },
        { name: 'viewer' },
        { name: 'user' }
      ]
    selectedRole: Role = {}

    actionBtnOptions: any = {
      enable: false,
      addCondition: false
    };

    ruleColumnDefs: ColDef[] = [];


    private gridApi!: GridApi;

    searchOptions: SearchOption = {
      userName: '',
    };

    pageInfo: PageInfo = {
      page: 1,
      rowPerPage: 10,
      totalRecord: 0,
      allpage: 0,
    };

    loadingModal: any;
    messageAlertModal: any;

    constructor(
      private messageService: MessageService,
      private userService: UserService,
      private UserRoleService: UserRoleService,
      private headerNavigateService :HeaderNavigateService
    ) { }

    ngOnInit() {
      this.getPermission();
      this.initialColDef();
      this. initialHeaderNavigate();
    }


    initialHeaderNavigate() {
        let headerNavigateModelList: Array<HeaderNavigateModel> = [
            { label: 'Home', url: '/', attributes: { title: 'Home' } },
            { label: 'User Managment', url: '/usermanagment' },
        ];
        this.headerNavigateService.setNavigate(headerNavigateModelList);
    }

    ngAfterViewInit(): void {
        this.loadingModal = new bootstrap.Modal(document.getElementById('loadingModal'), { backdrop: 'static', keyboard: false });
        this.messageAlertModal = new bootstrap.Modal(document.getElementById('messageAlertModal'));

        this.searchRule('');
    }

    ngOnDestroy() {
        const messageAlertModal: any = document.getElementById('messageAlertModal');
        messageAlertModal.remove();
        const loadingModal: any = document.getElementById('loadingModal');
        loadingModal.remove();
    }

    getPermission() {
      const userInfo = this.userService.getUserInfo() as any;
      console.log(userInfo);

      this.role = userInfo.role;
      this.userName = userInfo.fullName;
      switch (this.role) {
        case 'admin':
          this.actionBtnOptions.enable = true;
          this.actionBtnOptions.addCondition = true;
          this.isValidAbo = true;
          break;
        default:
          this.actionBtnOptions.enable = false;
          this.actionBtnOptions.addCondition = false;
          this.isValidAbo = true;
          break;
      }
    }

    frameworkComponents = {
        actionButton: UserRoleActionButton,
    }
    initialColDef() {
      this.ruleColumnDefs = [
        {
          headerName: 'Action',
          cellRenderer: 'actionButton',
          cellRendererParams: {
            onClickEditRole: (data: any) => {
              this.onClickEditRole(data);
            },
            actionBtnOptions: this.actionBtnOptions
          },
          width: 150,
        },
        {
          headerName: "User Name",
          field: 'username',
          sortable: true,
          width: 200
        },
        {
          headerName: "Full Name",
          field: 'fullName',
          sortable: true,
          width: 200
        },
        {
          headerName: 'Role',
          field: 'role',
          sortable: true,
          width: 160
        },
        {
          headerName: 'Status',
          field: 'status',
          sortable: true,
          width: 160
        },
        {
          headerName: 'source',
          field: 'source',
          sortable: true,
          width: 160
        },
        {
          headerName: 'Profile Name',
          field: 'profileName',
          sortable: true,
          width: 250
        },
      ]
    }

    async onClickSearch() {
      this.pageNumber = 1;
      if (this.searchOptions.userName == '') {
        this.alertMessage('danger', 'Input invalid', 'bx bx-error-circle','User Name must be string')
        return;
      }
      this.usernameRoleList = []
      this.searchRule(this.searchOptions.userName);
    }

    onClickClear() {
      this.pageNumber = 1
      this.searchOptions = {
        userName: ''
      };
      this.usernameRoleList = []
      this.searchRule('');
    }

    onClickEditRole(rule: UsernameRole) {
      this.isValidAbo = true;
      this.usernameRole = rule;
      this.selectedRole.name = rule.role;
      this.asingRoleConditionModal = true
    }

    onGridReady(params: GridReadyEvent) {
      this.gridApi = params.api;

    }

    async gotoPage(page: any) {
      if (page > 0 && page <= this.pageInfo.allpage) {
        this.pageInfo.page = page;
        await this.searchRule(this.searchOptions.userName);
      }
    }

    searchRule(textSearch: string) {
      this.loadingModal.show();
      this.UserRoleService.getUsers(textSearch, String(this.pageInfo.page), String(this.pageInfo.rowPerPage)).subscribe((res: any) => {
        if (res.success && res.data.length > 0) {
          this.usernameRoleList = res.data as UsernameRole[];
          this.pageInfo.page = Number(res.pageinfo.page);
          this.pageInfo.rowPerPage = Number(res.pageinfo.rowPerPage)
          this.pageInfo.totalRecord = Number(res.pageinfo.totalRecord)
          this.pageInfo.allpage = Math.ceil(this.pageInfo.totalRecord / this.pageInfo.rowPerPage);
        }
        setTimeout(() => {
            this.loadingModal.hide();
        }, 500);

      }, (error: any) => {
        this.alertMessage('danger', 'Error!', 'bx bx-error-circle',error.error.error.errorMsg)
        this.loadingModal.hide();
      }
      )
    }

    saveCondition(selectedRole: Role) {

      if (this.usernameRole.role?.toLowerCase() === selectedRole?.name?.toLowerCase()) {
        this.alertMessage('danger', 'Warn!', 'bx bx-error-circle','Role not change')
        return;
      }

      this.loadingModal.show();
      this.usernameRole.role = selectedRole?.name ;
      this.UserRoleService.updateUser(this.usernameRole).subscribe({
        next: async (res: any) => {
          if (res.success && res.data) {
            const list = this.usernameRoleList;
            this.usernameRoleList = []
             this.closeAsingRoleConditionModal()
             this.loadingModal.hide();
             setTimeout(() => {
                this.alertMessage('success', 'Update User', 'bx bx-check-circle', 'Update User successfully');
            }, 500);


             this.searchRule('');

          }
        },
        error: (error: any) => {
          this.loadingModal.hide();
          this.alertMessage('danger', 'Error', 'bx bx-error-circle',error.error.error.errorMsg)

        }
      })
    }

    closeAsingRoleConditionModal() {
      this.asingRoleConditionModal = false
      this.usernameRole = {}
      this.isValidAbo = false;
    }


    messageAlert: any = {
        title: '',
        message: '',
        button: []
    }
    alertMessage(theme: string, title: string, icon: string | null, message: string){
        this.messageAlertModal.show();
        const self = this;
        this.messageAlert = {
            theme: theme,
            title: title,
            icon: icon,
            message: message,
            button: [
                {
                    label: 'OK',
                    action: async function () {
                        self.messageAlertModal.hide();
                    }.bind(this),
                    color: 'warning'
                }
            ]
        };
    }

  }
