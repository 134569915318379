import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

  constructor(private http: HttpClient) { }

  getUsers(username: string, page: string, rowPerPage: string) {
    const params = username == '' ? `page=${page}&rowPerPage=${rowPerPage}` : `username=${username}&page=${page}&rowPerPage=${rowPerPage}`
    return this.http.get(`${environment.api_url}/apibo/call/getusers?${params}`);
  }


  updateUser(body : any) {
    return this.http.post(`${environment.api_url}/apibo/call/updateuser`, body);
  }


}
