<div class="container-fluid bg-light">
    <div class="container pt-5">
        <c-row class="mt-3">
            <c-col>
                <h3 cCardTitle class="mb-2"><strong class="text-primary">Document ID : {{uId}}</strong></h3>
                <h3 cCardTitle class="mb-2"><strong class="text-primary">Document No : {{docNo}}</strong></h3>
                <c-card class="mb-3">
                    <c-card-body>
                        <c-row>
                            <c-col [sm]="12" class="mb-2">
                                <div class="float-end">
                                    <span class="me-1">Show</span>
                                    <select class="me-1" id="search_option_limit" [(ngModel)]="pageOption.rowPerPage" (change)="getLog()">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                    </select>
                                    <span>entries</span>
                                </div>
                            </c-col>
                            <c-col [sm]="12" class="mb-2">
                                <ag-grid-angular style="width: 100%; height: 50vh;" class="ag-theme-alpine"
                                    [columnDefs]="logColumnDefs" [rowData]="logData" [enableCellTextSelection]="true"
                                    [enableRangeSelection]="true" [frameworkComponents]="frameworkComponents">
                                </ag-grid-angular>
                            </c-col>
                            <c-col [sm]="12">
                                <app-paging-tool [pageinfo]="pageOption" (onClickPage)="gotoPage($event)">
                                </app-paging-tool>
                            </c-col>
                        </c-row>
                    </c-card-body>
                </c-card>
            </c-col>
        </c-row>
    </div>
</div>

<div class="modal fade" id="loadingModal" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-message-alert-modal [messageAlert]="messageAlert"></app-message-alert-modal>

<div class="modal fade" id="docLogModal" data-bs-backdrop="static">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="docLogModalLabel" *ngIf="docSelectedData">Log ID : {{docSelectedData.uId}}</h5>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="docSelectedData">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-3 text-end"><strong>Log Date Time : </strong></div>
                            <div class="col-9">{{docSelectedData.logDateTime}}</div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-3 text-end"><strong>Log Code : </strong></div>
                            <div class="col-9">{{docSelectedData.logCode}}</div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-3 text-end"><strong>Log Sub Code : </strong></div>
                            <div class="col-9">{{docSelectedData.logSubCode}}</div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-3 text-end"><strong>Log Status : </strong></div>
                            <div class="col-9">{{docSelectedData.logStatus}}</div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-3 text-end"><strong>Log Status Desc : </strong></div>
                            <div class="col-9">
                               <span id="log_status_desc" class="rounded-3 url-overflow">{{docSelectedData.logStatusDesc}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-3 text-end"><strong>Endpoint URL : </strong></div>
                            <div class="col-9">
                                <span id="endpoint_url" class="rounded-3 url-overflow">{{docSelectedData.endpointUrl}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-3 text-end"><strong>Endpoint Ref No : </strong></div>
                            <div class="col-9">
                                <span id="endpoint_ref_no" class="rounded-3 url-overflow">{{docSelectedData.endpointRefNo}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"> Close </button>
            </div>
        </div>
    </div>
</div>
