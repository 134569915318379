import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SelectOptionModel } from '../models/common/common.model';
import { Injectable } from '@angular/core';

declare var $ :any;

@Injectable({
    providedIn: 'root'
  })
export class AlertService {
    constructor(
        private http: HttpClient
    ) {}


    alert(message:string, type:string) {
        $(".alert").alert('close')
        var alertPlaceholder = document.body; //document.getElementById('alertholder')
        var wrapper = document.createElement('div');
        wrapper.innerHTML = '<div class="alert alert-' + type + ' alert-dismissible" role="alert">' + message + '<button type="button" class="btn-close" data-coreui-dismiss="alert" aria-label="Close"></button></div>';
    
        alertPlaceholder?.append(wrapper);
    }

    alertSuccess(message:string){
        $(".alert").alert('close')
        let type = AlertType.type_success;
        //var alertPlaceholder = document.body; //document.getElementById('alertholder')
        //var wrapper = document.createElement('div');
        //wrapper.innerHTML = '<div class="alert alert-' + type + ' alert-dismissible" role="alert">' + message + '<button type="button" class="btn-close" data-coreui-dismiss="alert" aria-label="Close"></button></div>';
        let alertContent = '<div class="alert alert-' + type + ' alert-dismissible" role="alert">' + message + '<button type="button" class="btn-close" data-dismiss="alert" aria-label="Close"></button></div>';
        //alertPlaceholder?.prepend(wrapper);
        $('body').prepend(alertContent);

    }

    alertError(message:string){
        $(".alert").alert('close')
        let type = AlertType.type_danger;
        //var alertPlaceholder = document.body; //document.getElementById('alertholder')
        //var wrapper = document.createElement('div');
        //wrapper.innerHTML
        let alertContent = '<div class="alert alert-' + type + ' alert-dismissible" role="alert">' + message + '<button type="button" class="btn-close" data-dismiss="alert" aria-label="Close"></button></div>';
        //alertPlaceholder?.prepend(alertContent);

        $('body').prepend(alertContent);

        //alertPlaceholder?.append(wrapper);
    }

        /*
    getBonusDetail(token: string, period: string, params: URLSearchParams) {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${token}`);
        return this.http.get(`${environment.mashery_api_url}/rest/bonus/v3/account/detail/${period}?${params.toString()}`, { headers: headers }).pipe(catchError(this.formatErrors));
    }*/

}

// primary, secondary, success, danger, warning, info, light, dark
export class AlertType {
    static readonly type_primary:string = "primary";
    static readonly type_secondary:string = "secondary";
    static readonly type_success:string = "success";
    static readonly type_danger:string = "danger";
    static readonly type_warning:string = "warning";
    static readonly type_info:string = "info";
    static readonly type_light:string = "light";
    static readonly type_dark:string = "dark";
  
    constructor() {
        
    }
}