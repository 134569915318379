import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    AccordionModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonModule,
    CardModule,
    CarouselModule,
    CollapseModule,
    DropdownModule,
    FormModule,
    GridModule,
    ListGroupModule,
    NavModule,
    PaginationModule,
    PopoverModule,
    ProgressModule,
    SharedModule,
    SpinnerModule,
    TableModule,
    TabsModule,
    TooltipModule,
    UtilitiesModule,
    WidgetModule,
    AlertModule,
    ModalModule,
    ToastModule,
    ButtonGroupModule,
    AvatarModule,
    NavbarModule,
    FooterModule,
    HeaderModule,
    SidebarModule,


} from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { ChartjsModule } from '@coreui/angular-chartjs';
import { AgGridModule } from 'ag-grid-angular';
import { MessageAlertModalComponent } from './modules/components/message-alert-modal/message-alert-modal.component';
import { PagingToolComponent } from './modules/components/paging-tool/paging-tool.component';
import {CalendarModule} from 'primeng/calendar';
import {InputSwitchModule} from 'primeng/inputswitch';

@NgModule({
    declarations: [
    MessageAlertModalComponent,
    PagingToolComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        AccordionModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CardModule,
        CarouselModule,
        CollapseModule,
        DropdownModule,
        FormModule,
        GridModule,
        ListGroupModule,
        NavModule,
        PaginationModule,
        PopoverModule,
        ProgressModule,
        SharedModule,
        SpinnerModule,
        TableModule,
        TabsModule,
        TooltipModule,
        UtilitiesModule,
        WidgetModule,
        AlertModule,
        ModalModule,
        ToastModule,
        ButtonGroupModule,
        AvatarModule,
        NavbarModule,
        FooterModule,
        HeaderModule,
        SidebarModule,
        IconModule,
        ChartjsModule,
        AgGridModule,
        CalendarModule,
        InputSwitchModule,
    ],
    exports: [
        FormsModule,
        AccordionModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CardModule,
        CarouselModule,
        CollapseModule,
        DropdownModule,
        FormModule,
        GridModule,
        ListGroupModule,
        NavModule,
        PaginationModule,
        PopoverModule,
        ProgressModule,
        SharedModule,
        SpinnerModule,
        TableModule,
        TabsModule,
        TooltipModule,
        UtilitiesModule,
        WidgetModule,
        AlertModule,
        ModalModule,
        ToastModule,
        ButtonGroupModule,
        AvatarModule,
        NavbarModule,
        FooterModule,
        HeaderModule,
        SidebarModule,
        IconModule,
        ChartjsModule,
        AgGridModule,
        MessageAlertModalComponent,
        PagingToolComponent,
        CalendarModule,
        InputSwitchModule
    ],
    schemas: []
})
export class ShareModule { }
