import { TrndocactionComponent } from './modules/order/trndocaction/trndocaction.component';
import { OrderDetailComponent } from './modules/order/order-detail/order-detail.component';
import { OrderComponent } from './modules/order/order.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

import { DefaultLayoutComponent } from './containers';
import { Page404Component } from './views/pages/page404/page404.component';
import { Page500Component } from './views/pages/page500/page500.component';
//import { LoginComponent } from './views/pages/login/login.component';

import { RegisterComponent } from './views/pages/register/register.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { LoginComponent } from './modules/login/login.component';
import { TransactionComponent } from './modules/reconcile/transaction/transaction.component';
import { ReconcileComponent } from './modules/reconcile/reconcile.component';

import { AuthGuard } from './core/services/auth-guard.service';
import { CampaignroleComponent } from './modules/campaignrole/campaignrole.component';
import { CampaignroleCreateComponent } from './modules/campaignrole/campaignrole-create/campaignrole-create.component';
import { CampaignroleDetailComponent } from './modules/campaignrole/campaignrole-detail/campaignrole-detail.component';
import { ApilogComponent } from './modules/apilog/apilog.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { LogoutComponent } from './modules/logout/logout.component';
import { UserManagmentComponent } from './modules/user-managment/user-managment.component';
import { ConfigComponent } from './modules/config/config.component';
import { OndemandComponent } from './modules/ondemand/ondemand.component';
import { OndemandImportComponent } from './modules/ondemand-import/ondemand-import.component';
import { PreviewFileComponent } from './modules/preview-file/preview-file.component';
import { OrderPaymentComponent } from './modules/order-payment/order-payment.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    { path: 'login/callback', component: OktaCallbackComponent },
    /*
      {
        path: '',
        component: DefaultLayoutComponent,
        data: {
          title: 'Home'
        },
        children: [
          { path: 'dashboard', component: DashboardComponent },
        ]
      },
    */

    {
        path: '',
        component: DefaultLayoutComponent,
        data: {
            title: 'Home',
        },
        children: [
            {
                path: 'transaction',
                component: TransactionComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'reconcile',
                component: ReconcileComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'ordersearch',
                component: OrderComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
                canActivate: [AuthGuard],
            },
            { path: 'apilog', component: ApilogComponent },
            {
                path: 'usermanagment',
                component: UserManagmentComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'config',
                component: ConfigComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'ondemandpdfwht',
                component: OndemandComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'ondemandpdfbonusstatement',
                component: OndemandComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'ondemandimport',
                component: OndemandImportComponent,
                canActivate: [AuthGuard],
            },
            { path: 'orderpayment', component: OrderPaymentComponent, canActivate: [AuthGuard] },

            //{ path: "campaignrole",component: CampaignroleComponent, canActivate:[AuthGuard]},
            //{ path: "campaignrole-create",component: CampaignroleCreateComponent, canActivate:[AuthGuard]},
            //{ path: "campaignrole/:id",component: CampaignroleDetailComponent, canActivate:[AuthGuard]},

            {
                path: 'dashboard_prototype',
                loadChildren: () =>
                    import('./views/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
            },
            {
                path: 'theme',
                loadChildren: () =>
                    import('./views/theme/theme.module').then(
                        (m) => m.ThemeModule
                    ),
            },
            {
                path: 'base',
                loadChildren: () =>
                    import('./views/base/base.module').then(
                        (m) => m.BaseModule
                    ),
            },
            {
                path: 'buttons',
                loadChildren: () =>
                    import('./views/buttons/buttons.module').then(
                        (m) => m.ButtonsModule
                    ),
            },
            {
                path: 'forms',
                loadChildren: () =>
                    import('./views/forms/forms.module').then(
                        (m) => m.CoreUIFormsModule
                    ),
            },
            {
                path: 'charts',
                loadChildren: () =>
                    import('./views/charts/charts.module').then(
                        (m) => m.ChartsModule
                    ),
            },
            {
                path: 'icons',
                loadChildren: () =>
                    import('./views/icons/icons.module').then(
                        (m) => m.IconsModule
                    ),
            },
            {
                path: 'notifications',
                loadChildren: () =>
                    import('./views/notifications/notifications.module').then(
                        (m) => m.NotificationsModule
                    ),
            },
            {
                path: 'widgets',
                loadChildren: () =>
                    import('./views/widgets/widgets.module').then(
                        (m) => m.WidgetsModule
                    ),
            },
            {
                path: 'pages',
                loadChildren: () =>
                    import('./views/pages/pages.module').then(
                        (m) => m.PagesModule
                    ),
            },
        ],
    },
    {
        path: 'ordersearch/:id',
        component: OrderDetailComponent,
        data: {
            title: 'Order Detail',
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'trndocaction/:id',
        component: TrndocactionComponent,
        data: {
            title: 'Order Detail',
        },
        canActivate: [AuthGuard],
    },
    {
        path: '404',
        component: Page404Component,
        data: {
            title: 'Page 404',
        },
    },
    {
        path: '500',
        component: Page500Component,
        data: {
            title: 'Page 500',
        },
    },
    {
        path: 'login',
        component: LoginComponent,
        data: {
            title: 'Login Page',
        },
    },
    {
        path: 'logout',
        component: LogoutComponent,
        data: {
            title: 'Logout Page',
        },
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: {
            title: 'Register Page',
        },
    },
    {
        path: 'previewfile',
        component: PreviewFileComponent,
        data: {
            title: 'Preview File Page'
        }
    },
    { path: '**', redirectTo: 'dashboard' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'top',
            anchorScrolling: 'enabled',
            initialNavigation: 'enabledBlocking',
            // relativeLinkResolution: 'legacy'
        }),
    ],
    //imports: [RouterModule.forRoot(routes), CommonModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
