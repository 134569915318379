<!-- Modal -->
<div class="modal fade" id="messageAlertModal" tabindex="-1" aria-labelledby="messageAlertModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="messageAlertModalLabel">{{messageAlert.title}}</h5>
            </div>
            <div class="modal-body">
                {{messageAlert.message}}
            </div>
            <div class="modal-footer">
                <button *ngFor="let btn of messageAlert.button" type="button" class="btn"
                    [ngClass]="{'btn-primary': btn.color == 'primary','btn-secondary':btn.color == 'secondary' || !btn.color}"
                    (click)="btn.action()">{{btn.label}}</button>
            </div>
        </div>
    </div>
</div>
