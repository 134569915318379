import { LocalService } from './local.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HttpInterceptor, HttpErrorResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Router } from '@angular/router';

const USER_INFO = "webUserInfo";
const TOKEN = "webToken";

@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

    constructor(private router: Router, private localService: LocalService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const token: any = JSON.parse(this.localService.getJsonValue(TOKEN));
        let tokenizedReq;
        if (token && typeof req.headers !== 'undefined') {
            tokenizedReq = req.clone({
                setHeaders: {
                    'Authorization': `Bearer ${token}`
                }
            });

            return next.handle(tokenizedReq).pipe(tap(() => { }, (err: any) => {
                console.log('incepter: ', err);
                console.log(err.status);
                if (err.status === 401) {
                    window.location.href = '/login';
                }
            }));
        }

        return next.handle(req).pipe(tap(() => { }, (err: any) => {
            console.log('incepter: ', err);
            if (err.status === 401) {
                window.location.href = '/login';
            }
        }));
    }
}
