import { UserService } from 'src/app/core/services/user.service';
import { Component, OnInit } from '@angular/core';
import { navItems } from './_nav';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-dashboard',
    templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent implements OnInit {

    public navItems: any = [];

    public perfectScrollbarConfig = {
        suppressScrollX: true,
    };

    constructor(
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private router :Router
    ) {
     }

    ngOnInit(): void {
        this.navItems = this.filterRecursive(navItems, 'variant');
    }

    showData(event:any){
      console.log(event);

    }


    filterRecursive(array: any[], property: string) {
        const listPermission = this.userService.getUserPerm();
        function copy(o: any) {
            return Object.assign({}, o);
        }
        let filteredData = array.map(copy).filter(function x(y) {
            if (listPermission.map((data:any) => data.permcode)?.includes(y[property])
            ) {
                return true;
            }
            if (y.children) {
                return (y.children = y.children.map(copy).filter(x)).length;
            }
        });
        return filteredData;
    }

}
