import { filter } from 'rxjs/operators';
import { UserService } from './../../core/services/user.service';
import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

// https://www.ag-grid.com/javascript-data-grid/component-cell-renderer/
// https://blog.ag-grid.com/cell-renderers-in-ag-grid-every-different-flavour/
@Component({
    selector: 'action-button-order-list',
    template: `
      <button *ngIf="orderData?.status == 'SUCCESS' && orderData?.statusFileExist == 'EXIST' " (click)="downloadPdf()" name="btn_download_pdf" id="btn_download_pdf" style="height: 30px;" class="btn btn-pill btn-primary me-2 py-0">PDF</button>
   `,
})
export class ActionButtonOndemandPdf implements ICellRendererAngularComp {
    private params: any;
    public orderData: any;

    agInit(params: any): void {


        this.params = params;
        this.orderData = this.params.data;
        console.log(this.orderData );
    }


    downloadPdf() {
        this.params.clickedDownloadPdf(this.params.data);
    }


    refresh(params: ICellRendererParams): boolean {
        return true;
    }
}

