import { ActionButtonDocLog } from './../../components/action-button-doc-log.component';
import { ActivatedRoute } from '@angular/router';
import { TrndocactionService } from './../../../core/services/trndocaction.service';
import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import * as moment from 'moment';

declare const bootstrap: any;

@Component({
    selector: 'app-trndocaction',
    templateUrl: './trndocaction.component.html',
    styleUrls: ['./trndocaction.component.scss']
})
export class TrndocactionComponent implements OnInit {

    pageOption: any = {
        page: 1,
        rowPerPage: 10,
        totalRecord: 0,
        allpage: 0
    };

    uId: any;
    docNo: any;

    logColumnDefs: ColDef[] = [
        {
            headerName: "Action",
            cellRenderer: 'action',
            cellRendererParams: {
                clickedOpenLogModal: (data: any) => {
                    this.openLogModal(data);
                },
            },
            width: 100
        },
        { headerName: "Log Date Time", field: 'logDateTime', sortable: true, width: 170 },
        { headerName: 'Log Code', field: 'logCode', sortable: true, width: 150 },
        { headerName: 'Log Sub Code', field: 'logSubCode', sortable: true, width: 270 },
        { headerName: 'Status', field: 'logStatus', sortable: true, width: 150 },
        { headerName: 'Status Desc', field: 'logStatusDesc', sortable: true, width: 300 },
        { headerName: 'Endpoint URL', field: 'endpointUrl', sortable: true, width: 340 },
        { headerName: 'Endpoint Ref No', field: 'endpointRefNo', sortable: true, width: 200 },
    ];

    frameworkComponents = {
        action: ActionButtonDocLog,
    }

    logData: any = [];
    messageAlertModal: any;
    messageAlert: any = {
        title: '',
        message: '',
        button: []
    }

    loadingModal: any;
    docLogModal: any;
    docSelectedData: any;

    constructor(
        private trndocactionService: TrndocactionService,
        private activatedRoute: ActivatedRoute
    ) {
        this.activatedRoute.paramMap.subscribe((params: any) => {
            this.uId = params.get('id');
        });
        this.activatedRoute.queryParams.subscribe((query: any) => {
            this.docNo = (query.docNo) ? query.docNo : '';
        });
    }

    ngOnInit(): void {
        this.getLog();
    }

    ngAfterViewInit(): void {
        this.messageAlertModal = new bootstrap.Modal(document.getElementById('messageAlertModal'));
        this.loadingModal = new bootstrap.Modal(document.getElementById('loadingModal'), { backdrop: 'static', keyboard: false });
        this.docLogModal = new bootstrap.Modal(document.getElementById('docLogModal'));
    }

    getLog() {
        this.trndocactionService.getLogs(this.uId, this.pageOption).subscribe(
            {
                next: async (result: any) => {
                    this.logData = result.data.map((row: any) => {
                        row.logDateTime = moment(row.logDateTime).format('DD-MM-YYYY HH:mm:ss')
                        return row;
                    });
                    this.pageOption.page = parseInt(result.pageinfo.page);
                    this.pageOption.rowPerPage = parseInt(result.pageinfo.rowPerPage);
                    this.pageOption.totalRecord = parseInt(result.pageinfo.totalRecord);
                    this.pageOption.allpage = Math.ceil(result.pageinfo.totalRecord / result.pageinfo.rowPerPage);
                },
                error: (error: any) => {
                    console.log();
                    const self = this;
                    this.messageAlert = {
                        title: 'Error!',
                        message: error.error.error.errorMsg,
                        button: [
                            {
                                label: 'Close', action: async function () {
                                    self.messageAlertModal.hide();
                                }.bind(this)
                            }
                        ]
                    }
                    this.messageAlertModal.show();
                }
            }
        );

    }

    async gotoPage(page: any) {
        if (page > 0 && page <= this.pageOption.allpage) {
            this.pageOption.page = page;
            this.getLog();
        }
    }

    openLogModal(data: any) {
        this.docSelectedData = {
            uId: data.uId,
            logDateTime: data.logDateTime,
            logCode: data.logCode,
            logSubCode: data.logSubCode,
            logStatus: data.logStatus,
            logStatusDesc: data.logStatusDesc,
            endpointUrl: data.endpointUrl,
            endpointRefNo: data.endpointRefNo
        };
        this.docLogModal.show();
    }

}
