import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
//import { PagesRoutingModule } from './pages-routing.module';
import { ReactiveFormsModule,FormsModule  } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ShareModule } from './../../share.module';

import { WidgetsModule } from '../../views/widgets/widgets.module';
//import { CampaignFlowComponent } from './campaign-detail/campaign-flow/campaign-flow.component';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    WidgetsModule,
    RouterModule,
    ShareModule
  ]
})
export class LoginModule { }
