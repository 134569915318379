import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Apilog } from 'src/app/core/entity/apilog';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { ApilogService } from 'src/app/core/services/apilog.service';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import * as moment from 'moment';
import { ActionButtonApiLog } from '../components/action-button-api-log.component';

declare const bootstrap: any;

@Component({
    selector: 'app-apilog',
    templateUrl: './apilog.component.html',
    styleUrls: ['./apilog.component.scss']
})
export class ApilogComponent implements OnInit {
    pipe = new DatePipe('en-US');
    today = new Date();
    searchOption: any = {
        q: {
            period: this.pipe.transform(this.today, 'yyyy-MM'),
            dateFrom: parseInt(moment().format('DD')),
            dateTo: parseInt(moment().format('DD')),
            clientId: '',
            apiPath: '',
            requestId: '',
            status: ['success', 'failed'],
        },
        pageinfo: {
            page: 1,
            rowPerPage: 50,
            totalRecord: 0,
            allpage: 0
        }
    };
    searched: boolean = false;
    currentDatetime: any = '';
    apiLogModalData: Apilog = {};

    dateList: string[];

    searchRowData: Array<any> = [];
    searcHishRowData: Array<any> = [];
    public searchColumnDefs: ColDef[] = [
        {
            headerName: 'Action',
            cellRenderer: 'actionButton',
            cellRendererParams: {
                clickedOpenView: (data: any) => {
                    console.log(data)
                    this.openDetailApiLog(data);
                },
            },
            width: 120
        },
        {
            headerName: 'uId',
            field: 'uId',
            sortable: false,
            filter: false,
            suppressMovable: true,
            width: 130,
        },
        {
            field: 'requestDateTime',
            headerName: 'Request Datetime',
            sortable: false,
            filter: false,
            suppressMovable: true,
        },
        { field: 'apiPath', headerName: 'API Path', sortable: false, filter: false, suppressMovable: true },
        { field: 'clientId', headerName: 'Client ID', sortable: false, filter: false, suppressMovable: true },
        {
            field: 'responseDateTime',
            headerName: 'Response Datetime',
            sortable: false,
            filter: false,
            suppressMovable: true,
        },
        { field: 'status', headerName: 'Status', sortable: false, filter: false, suppressMovable: true },
        { field: 'responseStatusCode', headerName: 'Http Code', sortable: false, filter: false, suppressMovable: true },
        { field: 'errorCode', headerName: 'Error Code', sortable: false, filter: false, suppressMovable: true },
        { field: 'requestId', headerName: 'Request ID', sortable: false, filter: false, suppressMovable: true, width: 350, },
    ];


    frameworkComponents = {
        actionButton: ActionButtonApiLog,
    }

    messageAlertModal: any;
    messageAlert: any = {
        title: '',
        message: '',
        button: []
    }
    loadingModal: any;

    constructor(
        private headerNavigateService: HeaderNavigateService,
        private apiLogService: ApilogService
    ) {
        this.dateList = this.getTotalsdateOfMonth(this.searchOption.q.period || '');
    }

    ngOnInit() {
        this.initialData();
    }

    ngAfterViewInit(): void {
        this.messageAlertModal = new bootstrap.Modal(document.getElementById('messageAlertModal'));
        this.loadingModal = new bootstrap.Modal(document.getElementById('loadingModal'), { backdrop: 'static', keyboard: false });
    }

    ngOnDestroy() {
        const messageAlertModal: any = document.getElementById('messageAlertModal');
        messageAlertModal.remove();
        const loadingModal: any = document.getElementById('loadingModal');
        loadingModal.remove();
    }

    initialData() {
        let headerNavigateModelList: Array<HeaderNavigateModel> = [
            { label: 'Home', url: '/', attributes: { title: 'Home' } },
            { label: 'Api Log', url: '/apilog' },
        ];
        this.headerNavigateService.setNavigate(headerNavigateModelList);
    }

    search() {
        if (this.verifySearchData()) {
            this.loadingModal.show();
            const option: any = {
                q: {
                    period: this.searchOption.q.period.replace('-', ''),
                    fromDateTime: new Date((new Date(this.searchOption.q.period || '').setDate(Number(this.searchOption.q.dateFrom)))).toISOString(),
                    toDateTime: new Date(new Date((new Date(this.searchOption.q.period || '').setDate(Number(this.searchOption.q.dateTo)))).setUTCHours(23, 59, 59, 999)).toISOString(),
                    clientId: this.searchOption.q.clientId,
                    apiPath: this.searchOption.q.apiPath,
                    requestId: this.searchOption.q.requestId,
                    status: this.searchOption.q.status.join(','),
                },
                pageinfo: this.searchOption.pageinfo
            };

            this.apiLogService
                .listHistoryApi(option)
                .subscribe({
                    next: (res: any) => {
                        this.searchRowData = res.data.map((row: any) => {
                            row.requestDateTime = moment(row.requestDateTime).format('DD-MM-YYYY HH:mm:ss');
                            row.responseDateTime = moment(row.responseDateTime).format('DD-MM-YYYY HH:mm:ss');
                            return row;
                        });
                        this.searchOption.pageinfo.page = parseInt(res.pageinfo.page);
                        this.searchOption.pageinfo.rowPerPage = parseInt(res.pageinfo.rowPerPage);
                        this.searchOption.pageinfo.totalRecord = parseInt(res.pageinfo.totalRecord);
                        this.searchOption.pageinfo.allpage = Math.ceil(res.pageinfo.totalRecord / res.pageinfo.rowPerPage);
                        this.currentDatetime = moment().format('DD/MM/YYYY HH:mm:ss');
                        this.searched = true;
                        setTimeout(() => {
                            this.loadingModal.hide();
                        }, 500);
                    },
                    error: (e: any) => {
                        setTimeout(() => {
                            this.loadingModal.hide();
                        }, 500);
                    },
                    complete: () => {
                        setTimeout(() => {
                            this.loadingModal.hide();
                        }, 500);
                    },
                })
        }

    }

    verifySearchData() {
        let cheched = true,
            messageError = '';
        if (this.searchOption.q.dateFrom && this.searchOption.q.dateTo) {
            if (Number(this.searchOption.q.dateFrom) > Number(this.searchOption.q.dateTo)) {
                messageError = "invalid date from-to";
                cheched = false;
            }
        } else {
            if (this.searchOption.q.dateFrom || this.searchOption.q.dateTo) {
                messageError = "require date from-to";
                cheched = false;
            }
        }

        if (!cheched) {
            const self = this;
            this.messageAlert = {
                title: 'Warning',
                message: messageError,
                button: [
                    {
                        label: 'Close', action: async function () {
                            self.messageAlertModal.hide();
                        }.bind(this)
                    }
                ]
            }
            this.messageAlertModal.show();
        }

        return cheched;
    }

    async gotoPage(page: any) {
        if (page > 0 && page <= this.searchOption.pageinfo.allpage) {
            this.searchOption.pageinfo.page = page;
            this.search();
        }
    }

    openDetailApiLog(param: any) {
        this.apiLogModalData = param;
        const detailLogModal = new bootstrap.Modal(document.getElementById('detail_modal'));
        detailLogModal.show();
    }

    changePeriod(event: any) {
        this.dateList = this.getTotalsdateOfMonth(event.target.value);
        this.searchOption.q.dateFrom = '';
        this.searchOption.q.dateTo = '';
    }

    getTotalsdateOfMonth(period: string): string[] {
        let dateList = [];
        let lastDateOnMonth = new Date(new Date(period).getFullYear(), new Date(period).getMonth() + 1, 0).getDate();
        for (let i = 0; i < lastDateOnMonth; i++) {
            dateList.push((i + 1).toString());
        }
        return dateList;
    }

    onCheckboxTypeChange(param: any) {
        if (param.target.checked) {
            this.searchOption.q.status.push(param.target.value);
        } else {
            const index = this.searchOption.q.status.indexOf(param.target.value);
            if (index > -1) {
                this.searchOption.q.status.splice(index, 1);
            }
        }
    }

    clear() {
        this.searchOption.q.period = this.pipe.transform(this.today, 'yyyy-MM');
        this.searchOption.q.clientId = '';
        this.searchOption.q.apiPath = '';
        this.searchOption.q.requestId = '';
        this.searchOption.q.status = ['success', 'failed'];
        const searchTypeDuccess: any = document.getElementById('search_type_success');
        searchTypeDuccess.checked = true;
        const searchTypeFail: any = document.getElementById('search_type_fail');
        searchTypeFail.checked = true;
    }

}
