import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from './../../../core/services/order.service';
import {
    ActionButtonOrderDetailList,
    ActionButtonSourceOrderDetailList,
} from './../../components/action-button-order-detail-list.component';
import { HeaderNavigateModel } from './../../../core/models/common/headernavigate.model';
import { DataTypeValidationService } from './../../../core/services/data-type-validation.service';
import { HeaderNavigateService } from './../../../core/services/headernavigate.service';
import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import * as moment from 'moment';

declare const bootstrap: any;

@Component({
    selector: 'app-order-detail',
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent implements OnInit {
    orderDetail: any = {
        orderNo: null,
        orderDateTime: null,
        orderFor: null,
        taxId: null,
        orderAccount: null,
        groupOrderId: null,
        aboNo: null,
    };
    orderRevision: any = {
        data: [],
        pageinfo: {
            page: 1,
            rowPerPage: 10,
            totalRecord: 0,
            allpage: 0,
        },
    };
    searchRevisionOption: any = {
        q: {
            orderNo: '',
        },
        pageinfo: {
            page: 1,
            rowPerPage: 10,
            totalRecord: 0,
            allpage: 0,
        },
    };
    orderDetailColumnDefs: ColDef[] = [
        {
            headerName: 'Action',
            cellRenderer: 'actionOrderList',
            cellRendererParams: {
                clickedSendEmail: (data: any) => {
                    this.sendEmail(data);
                },
                clickedDownloadPdf: (data: any) => {
                    this.modalDownloadPdf(data);
                },
                clickedDownloadXml: (data: any) => {
                    this.modalDownloadXml(data);
                },
            },
            width: 180,
        },
        {
            headerName: 'Doc Datetime',
            field: 'invoiceDateTime',
            sortable: true,
            cellRenderer: 'dateTimeRenderer',
        },
        { headerName: 'Doc No', field: 'docNo', sortable: true },
        {
            headerName: 'Doc Status',
            field: 'voidStatus',
            sortable: true,
            cellRenderer: function (params) {
                return params.data.stateError == ''
                    ? "<strong class='text-success fs-6'>SUCCESS</strong>"
                    : "<strong class='text-danger fs-6'>FAIL</strong>";
            },
        },
        {
            headerName: 'Void',
            field: 'voidStatus',
            sortable: true,
            cellRenderer: function (params) {
                return params.data.voidStatus == 'Active'
                    ? "<strong class='text-success fs-6'>in-use</strong>"
                    : "<strong class='text-danger fs-6'>void</strong>";
            },
        },
        { headerName: 'Doc Type', field: 'docType', sortable: true },
        { headerName: 'Processtype', field: 'groupEvent', sortable: true },
        { headerName: 'Channel', field: 'docChannel', sortable: true },
        { headerName: 'Remark', field: 'docRemark', sortable: true },
        {
            headerName: 'Source',
            cellRenderer: 'actionSourceList',
            cellRendererParams: {
                clickedDownloadKinesis: (data: any) => {
                    this.modalDownloadKinesis(data);
                },
                clickedDownloadInet: (data: any) => {
                    this.modalDownloadInet(data);
                },
                clickOpenLog: (data: any) => {
                    this.OpenLog(data);
                },
                clickUploadKinesis: (data: any) => {
                    this.uploadKinesis(data);
                },
                clickedResendInet: (data: any) => {
                    this.resendInet(data);
                },
                clickedTriggerHybris: (data: any) => {
                    this.modalTriggerHybris(data);
                },
                clickedDelete: (data: any) => {
                    console.log(data);
                    this.openModalConfirmDelete(data);
                },
                clickedUpdateinet: (data: any) => {
                    console.log(data);
                    this.openModalUpdateinetConfirm(data);
                },
            },
            width: 900,
        },
    ];
    frameworkComponents = {
        actionOrderList: ActionButtonOrderDetailList,
        actionSourceList: ActionButtonSourceOrderDetailList,
    };

    messageAlertModal: any;
    messageAlert: any = {
        title: '',
        message: '',
        button: [],
    };
    loadingModal: any;
    documentDataSelected: any = null;
    docForKinesis: any = null;
    confirmManageModalAlert: any;
    typeDeleteConfirm = {
        deleteText: '',
        deleteSubmit: false,
        isShowDeleteType: false,
    };

    updateinetConfirm = {
        transactionCode: '',
        updateinetSubmit: false,
        isShowUpdateinet: false,
    };

    constructor(
        private headerNavigateService: HeaderNavigateService,
        private dataTypeValidationService: DataTypeValidationService,
        private orderService: OrderService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.activatedRoute.paramMap.subscribe((params: any) => {
            this.searchRevisionOption.q.orderNo = params.get('id');
        });
    }

    ngOnInit() {
        this.initialData();
        this.getOrderDetailData();
        this.getOrderRevision();
    }

    ngAfterViewInit(): void {
        this.messageAlertModal = new bootstrap.Modal(
            document.getElementById('messageAlertModal')
        );
        this.loadingModal = new bootstrap.Modal(
            document.getElementById('loadingModal'),
            { backdrop: 'static', keyboard: false }
        );
        this.loadingModal.show();
        this.confirmManageModalAlert = new bootstrap.Modal(
            document.getElementById('confirmManageModalAlert'),
            { backdrop: 'static', keyboard: false }
        );
    }

    numPage(n: number): Array<number> {
        return Array(n);
    }

    initialData() {
        let headerNavigateModelList: Array<HeaderNavigateModel> = [
            { label: 'Home', url: '/', attributes: { title: 'Home' } },
            { label: 'Order', url: '/ordersearch' },
            {
                label: 'Order Detail',
                url: '/ordersearch/' + this.searchRevisionOption.q.orderNo,
            },
        ];
        this.headerNavigateService.setNavigate(headerNavigateModelList);
    }

    getOrderDetailData() {
        this.orderService
            .getOrderDetail(this.searchRevisionOption.q.orderNo)
            .subscribe({
                next: async (result: any) => {
                    const data: any = result.data[0];
                    this.orderDetail.orderNo = data.orderNo
                        ? data.orderNo.split('-')[1]
                        : '';
                    this.orderDetail.orderDateTime = data.orderDateTime
                        ? moment(data.orderDateTime).format(
                              'DD/MM/YYYY HH:mm:ss'
                          )
                        : '';
                    this.orderDetail.orderFor =
                        (data.volumeAboAccountCode
                            ? data.volumeAboAccountCode
                            : '') +
                        ' ' +
                        (data.volumeAboName ? data.volumeAboName : '');
                    this.orderDetail.taxId = data.taxId ? data.taxId : '';
                    this.orderDetail.orderAccount =
                        (data.accountCode ? data.accountCode : '') +
                        ' ' +
                        (data.accountName ? data.accountName : '');
                    this.orderDetail.groupOrderId = data.groupOrderId
                        ? data.groupOrderId
                        : '';
                    this.orderDetail.aboNo = data.volumeAboAccountCode
                        ? data.volumeAboAccountCode.split(' ')[0]
                        : '';
                },
                error: (error: any) => {
                    console.log(error);
                },
            });
    }

    getOrderRevision() {
        this.orderService
            .getOrderRevision(this.searchRevisionOption)
            .subscribe({
                next: async (result: any) => {
                    this.orderRevision.data = this.setData(result.data);
                    this.orderRevision.pageinfo.page = parseInt(
                        result.pageinfo.page
                    );
                    this.orderRevision.pageinfo.rowPerPage = parseInt(
                        result.pageinfo.rowPerPage
                    );
                    this.orderRevision.pageinfo.totalRecord = parseInt(
                        result.pageinfo.totalRecord
                    );
                    this.orderRevision.pageinfo.allpage = Math.ceil(
                        result.pageinfo.totalRecord / result.pageinfo.rowPerPage
                    );
                    setTimeout(() => {
                        this.loadingModal.hide();
                    }, 500);
                },
                error: (error: any) => {
                    console.log(error);
                    setTimeout(() => {
                        this.loadingModal.hide();
                    }, 500);
                },
            });
    }

    setData(revisionData: any) {
        return revisionData.map((row: any) => {
            row.invoiceDateTime = moment(row.invoiceDateTime).format(
                'DD/MM/YYYY HH:mm:ss'
            );
            return row;
        });
    }

    async gotoPage(page: any) {
        if (page > 0 && page <= this.orderRevision.pageinfo.allpage) {
            this.orderRevision.pageinfo.page = page;
            await this.getOrderRevision();
        }
    }

    getShowingFrom() {
        let showingFrom: number =
            this.orderRevision.pageinfo.rowPerPage *
                this.orderRevision.pageinfo.page -
            (this.orderRevision.pageinfo.rowPerPage - 1);
        return showingFrom < this.orderRevision.pageinfo.totalRecord
            ? showingFrom
            : this.orderRevision.pageinfo.totalRecord;
    }

    getShowingTo() {
        let showingTo: number =
            this.orderRevision.pageinfo.page *
            this.orderRevision.pageinfo.rowPerPage;
        return showingTo < this.orderRevision.pageinfo.totalRecord
            ? showingTo
            : this.orderRevision.pageinfo.totalRecord;
    }

    sendEmail(data: any) {
        const self = this;
        this.messageAlert = {
            title: 'Confirm',
            message: 'Send mail to ABO ?',
            button: [
                {
                    label: 'Yes',
                    color: 'primary',
                    action: async function () {
                        self.eventSendEmail(data);
                        self.messageAlertModal.hide();
                    }.bind(this),
                },
                {
                    label: 'Cancel',
                    action: async function () {
                        self.messageAlertModal.hide();
                    }.bind(this),
                },
            ],
        };
        this.messageAlertModal.show();
    }

    eventSendEmail(data: any) {
        this.loadingModal.show();

        let dataBody: any = {
            sendMailType: 'INVOICE_PDF',
            sendToABO: this.orderDetail.aboNo,
            orderNo: '200-' + this.orderDetail.orderNo,
            docNo: data.docNo,
        };

        this.orderService.sendEmail(dataBody).subscribe({
            next: async (result: any) => {
                const self = this;
                this.messageAlert = {
                    title: 'Success!',
                    message: 'Send Email successed',
                    button: [
                        {
                            label: 'Close',
                            action: async function () {
                                self.messageAlertModal.hide();
                            }.bind(this),
                        },
                    ],
                };
                setTimeout(() => {
                    this.loadingModal.hide();
                    this.messageAlertModal.show();
                }, 500);
            },
            error: (error: any) => {
                const self = this;
                this.messageAlert = {
                    title: 'Error!',
                    message: error.error.error.errorMsg,
                    button: [
                        {
                            label: 'Close',
                            action: async function () {
                                self.messageAlertModal.hide();
                            }.bind(this),
                        },
                    ],
                };
                setTimeout(() => {
                    this.loadingModal.hide();
                    this.messageAlertModal.show();
                }, 500);
            },
        });
    }

    modalTriggerHybris(data: any) {
        const self = this;
        this.messageAlert = {
            title: 'Confirm?',
            message: `Trigger Hybris case ${data.groupEvent} ?`,
            button: [
                {
                    label: 'Yes',
                    color: 'primary',
                    action: async function () {
                        self.triggerHybris(data);
                        self.messageAlertModal.hide();
                    }.bind(this),
                },
                {
                    label: 'Cancel',
                    action: async function () {
                        self.messageAlertModal.hide();
                    }.bind(this),
                },
            ],
        };
        this.messageAlertModal.show();
    }

    triggerHybris(data: any) {
        this.loadingModal.show();
        const self = this;
        this.orderService.triggerHybris(data.uId).subscribe({
            next: async (response: any) => {
                this.messageAlert = {
                    title: 'Success!',
                    message: `Success Trigger Hybris case ${data.groupEvent}`,
                    button: [
                        {
                            label: 'Close',
                            action: async function () {
                                self.messageAlertModal.hide();
                            }.bind(this),
                        },
                    ],
                };
                setTimeout(() => {
                    this.messageAlertModal.show();
                    this.loadingModal.hide();
                }, 500);
            },
            error: async (error: any) => {
                this.messageAlert = {
                    title: 'Error!',
                    message: error.error.error.errorMsg,
                    button: [
                        {
                            label: 'Close',
                            action: async function () {
                                self.messageAlertModal.hide();
                            }.bind(this),
                        },
                    ],
                };
                setTimeout(() => {
                    this.messageAlertModal.show();
                    this.loadingModal.hide();
                }, 500);
            },
        });
    }

    modalDownloadPdf(data: any) {
        const self = this;
        this.messageAlert = {
            title: 'Confirm',
            message: 'Export PDF file ?',
            button: [
                {
                    label: 'Yes',
                    color: 'primary',
                    action: async function () {
                        self.downloadPdfFile(data.uId);
                        self.messageAlertModal.hide();
                    }.bind(this),
                },
                {
                    label: 'Cancel',
                    action: async function () {
                        self.messageAlertModal.hide();
                    }.bind(this),
                },
            ],
        };
        this.messageAlertModal.show();
    }

    downloadPdfFile(uId: any) {
        this.orderService.orderExportPdf(uId).subscribe({
            next: async (result: any) => {
                console.log(result);
                let blob: any = new Blob([result], { type: result.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('target', '_blank');
                link.setAttribute('href', url);
                //   link.setAttribute('download', `report-list-${this.filterOption.startDate}-to-${this.filterOption.endDate}.zip`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            error: (error: any) => {
                console.log(error);
            },
        });
    }

    modalDownloadXml(data: any) {
        const self = this;
        this.messageAlert = {
            title: 'Confirm',
            message: 'Export Payload file ?',
            button: [
                {
                    label: 'Yes',
                    color: 'primary',
                    action: async function () {
                        self.downloadXmlFile(data.uId);
                        self.messageAlertModal.hide();
                    }.bind(this),
                },
                {
                    label: 'Cancel',
                    action: async function () {
                        self.messageAlertModal.hide();
                    }.bind(this),
                },
            ],
        };
        this.messageAlertModal.show();
    }

    downloadXmlFile(uId: any) {
        this.orderService.orderExportXml(uId).subscribe({
            next: async (result: any) => {
                console.log(result);
                let blob: any = new Blob([result], { type: result.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('target', '_blank');
                link.setAttribute('href', url);
                link.setAttribute('download', `${uId}.xml`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            error: (error: any) => {
                console.log(error);
            },
        });
    }

    modalDownloadKinesis(data: any) {
        const self = this;
        this.messageAlert = {
            title: 'Confirm',
            message: 'Export Json file ?',
            button: [
                {
                    label: 'Yes',
                    color: 'primary',
                    action: async function () {
                        self.downloadKinesis(data);
                        self.messageAlertModal.hide();
                    }.bind(this),
                },
                {
                    label: 'Cancel',
                    action: async function () {
                        self.messageAlertModal.hide();
                    }.bind(this),
                },
            ],
        };
        this.messageAlertModal.show();
    }

    downloadKinesis(data: any) {
        this.orderService.docExport(data.uId, 'kinesis').subscribe({
            next: async (result: any) => {
                console.log(result);
                let blob: any = new Blob([result], { type: result.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('target', '_blank');
                link.setAttribute('href', url);
                link.setAttribute('download', data.docInputKinesisFileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            error: (error: any) => {
                console.log(error);
            },
        });
    }

    modalDownloadInet(data: any) {
        const self = this;
        this.messageAlert = {
            title: 'Confirm',
            message: 'Export Json file ?',
            button: [
                {
                    label: 'Yes',
                    color: 'primary',
                    action: async function () {
                        self.downloadInet(data);
                        self.messageAlertModal.hide();
                    }.bind(this),
                },
                {
                    label: 'Cancel',
                    action: async function () {
                        self.messageAlertModal.hide();
                    }.bind(this),
                },
            ],
        };
        this.messageAlertModal.show();
    }

    downloadInet(data: any) {
        this.orderService.docExport(data.uId, 'inet').subscribe({
            next: async (result: any) => {
                console.log(result);
                let blob: any = new Blob([result], { type: result.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('target', '_blank');
                link.setAttribute('href', url);
                link.setAttribute('download', data.docOutputInetFileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            error: (error: any) => {
                console.log(error);
            },
        });
    }

    OpenLog(data: any) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['/trndocaction', data.uId], {
                queryParams: { docNo: data.docNo },
            })
        );
        window.open(url, '_blank');
    }

    uploadKinesis(data: any) {
        this.docForKinesis = data;
        const element: any = document.getElementById('input_upload_kinesis');
        element.click();
    }

    resendInet(data: any) {
        this.loadingModal.show();
        this.orderService.resendInet(data.uId).subscribe({
            next: async (result: any) => {
                const self = this;
                this.messageAlert = {
                    title: 'Success!',
                    message: 'Resend INET Success',
                    button: [
                        {
                            label: 'Close',
                            action: async function () {
                                self.messageAlertModal.hide();
                            }.bind(this),
                        },
                    ],
                };
                setTimeout(() => {
                    this.loadingModal.hide();
                    this.messageAlertModal.show();
                }, 500);
            },
            error: (error: any) => {
                const self = this;
                this.messageAlert = {
                    title: 'Error!',
                    message: error.error.error.errorMsg,
                    button: [
                        {
                            label: 'Close',
                            action: async function () {
                                self.messageAlertModal.hide();
                            }.bind(this),
                        },
                    ],
                };
                setTimeout(() => {
                    this.loadingModal.hide();
                    this.messageAlertModal.show();
                }, 500);
            },
        });
    }

    getKinesisFile(event: any) {
        this.loadingModal.show();
        const kinesisFile: any = event.target.files[0];
        const fileReader: any = new FileReader();
        fileReader.readAsText(kinesisFile, 'UTF-8');
        fileReader.onload = async () => {
            const kinesisData = await this.fileToJson(fileReader);

            let orderNoForCheck: any = null;
            if (
                this.docForKinesis.groupEvent == 'New Order' &&
                kinesisData.entity.salesOrderId
            ) {
                orderNoForCheck = kinesisData.entity.salesOrderId;
            } else if (
                this.docForKinesis.groupEvent == 'Cancel Order' &&
                kinesisData.entity.salesOrderId
            ) {
                orderNoForCheck = kinesisData.entity.salesOrderId;
            } else if (
                this.docForKinesis.groupEvent == 'Return Order' &&
                kinesisData.entity.returnLines[0].originalSalesOrderId
            ) {
                orderNoForCheck =
                    kinesisData.entity.returnLines[0].originalSalesOrderId;
            }

            if (kinesisData && orderNoForCheck == this.orderDetail.orderNo) {
                const input = {
                    uId: this.docForKinesis.uId,
                    payload: kinesisData,
                };
                this.sendKinesis(input);
            } else {
                const self = this;
                this.messageAlert = {
                    title: 'Error!',
                    message: 'Order No in JSON file is not valid',
                    button: [
                        {
                            label: 'Close',
                            action: async function () {
                                self.messageAlertModal.hide();
                            }.bind(this),
                        },
                    ],
                };
                setTimeout(() => {
                    this.docForKinesis = null;
                    this.loadingModal.hide();
                    this.messageAlertModal.show();
                }, 500);
            }
        };
        fileReader.onerror = (error: any) => {
            this.docForKinesis = null;
            this.loadingModal.hide();
            console.log(error);
        };
    }

    async fileToJson(file: any) {
        try {
            const jsonData = JSON.parse(file.result);
            return jsonData;
        } catch (error) {
            console.log(error);
            const self = this;
            this.messageAlert = {
                title: 'Error!',
                message: 'File is not valid JSON',
                button: [
                    {
                        label: 'Close',
                        action: async function () {
                            self.messageAlertModal.hide();
                        }.bind(this),
                    },
                ],
            };
            setTimeout(() => {
                this.loadingModal.hide();
                this.messageAlertModal.show();
            }, 500);
            return false;
        }
    }

    sendKinesis(kinesisData: any) {
        this.orderService.sendKinesis(kinesisData).subscribe({
            next: async (result: any) => {
                const self = this;
                this.messageAlert = {
                    title: 'Success!',
                    message: 'Upload Kinesis Success',
                    button: [
                        {
                            label: 'Close',
                            action: async function () {
                                self.messageAlertModal.hide();
                            }.bind(this),
                        },
                    ],
                };
                setTimeout(() => {
                    this.loadingModal.hide();
                    this.messageAlertModal.show();
                }, 500);
            },
            error: (error: any) => {
                const self = this;
                this.messageAlert = {
                    title: 'Error!',
                    message: error.error.error.errorMsg,
                    button: [
                        {
                            label: 'Close',
                            action: async function () {
                                self.messageAlertModal.hide();
                            }.bind(this),
                        },
                    ],
                };
                setTimeout(() => {
                    this.loadingModal.hide();
                    this.messageAlertModal.show();
                }, 500);
            },
        });
    }

    openModalConfirmDelete(data: any) {
        const self = this;
        self.typeDeleteConfirm.deleteSubmit = false;
        self.typeDeleteConfirm.deleteText = '';
        self.typeDeleteConfirm.isShowDeleteType = true;

        self.updateinetConfirm.isShowUpdateinet = false;
        this.messageAlert = {
            title: 'CONFIRM DELETE',
            message: `Are you sure you want to delete ? <br><br> <b>Doc No : </b>  ${data?.docNo} &nbsp; &nbsp; &nbsp;    <b>Doc Type : </b> ${data?.docType}`,
            button: [
                {
                    label: 'Cancel',
                    action: async function () {
                        self.confirmManageModalAlert.hide();
                    }.bind(this),
                },
                {
                    label: 'Delete',
                    color: 'danger',
                    action: async function () {
                        self.typeDeleteConfirm.deleteSubmit = true;
                        if (self.typeDeleteConfirm.deleteText == 'delete') {
                            self.confirmManageModalAlert.hide();
                            self.deleteDocrevision(data?.uId);
                        }
                    }.bind(this),
                },
            ],
        };
        this.confirmManageModalAlert.show();
    }

    deleteDocrevision(id: any) {
        this.loadingModal.show();
        this.orderService.deleteDocrevision(id).subscribe({
            next: async (result: any) => {
                const self = this;
                self.getOrderRevision();
                this.messageAlert = {
                    title: 'Success!',
                    message: 'Deleted!! document had been delete',
                    button: [
                        {
                            label: 'Close',
                            action: async function () {
                                self.messageAlertModal.hide();
                            }.bind(this),
                        },
                    ],
                };
                setTimeout(() => {
                    this.loadingModal.hide();
                    this.messageAlertModal.show();
                }, 500);
            },
            error: (error: any) => {
                const self = this;
                this.messageAlert = {
                    title: 'Error!',
                    message: error.error.error.errorMsg,
                    button: [
                        {
                            label: 'Close',
                            action: async function () {
                                self.messageAlertModal.hide();
                            }.bind(this),
                        },
                    ],
                };
                setTimeout(() => {
                    this.loadingModal.hide();
                    this.messageAlertModal.show();
                }, 500);
            },
        });
    }

    async openModalUpdateinetConfirm(data: any) {
        const self = this;

        self.typeDeleteConfirm.isShowDeleteType = false;

        self.updateinetConfirm.isShowUpdateinet = true;
        self.updateinetConfirm.transactionCode = '';
        self.updateinetConfirm.updateinetSubmit = false;

        let dataInqDoc: any = undefined;
        const InqDoc = async () => {
            dataInqDoc = await this.getInqDoc(data?.uId);
        };

        await InqDoc();

        console.log(dataInqDoc);
        const dataStatus = dataInqDoc?.data[0];
        this.messageAlert = {
            title: 'UPDATE INET Transactioncode',
            message: `

            <b>Doc No : </b>  ${
                data?.docNo
            } &nbsp; &nbsp; &nbsp;    <b>Doc Type : </b>  ${data?.docType} <br>
            docstatus : ${dataStatus?.eTaxStatus || '-'}<br>
            docOutputInetStatus : ${dataStatus?.docOutputInetStatus || '-'}<br>
            docPdfFileStatus : ${dataStatus?.docPdfFileStatus || '-'}<br><br>
            <b>CURENT transaction code :</b> ${
                dataStatus?.docOutputInetRefId || '-'
            }<br>
                    `,
            button: [
                {
                    label: 'Cancel',
                    action: async function () {
                        self.confirmManageModalAlert.hide();
                    }.bind(this),
                },
                {
                    label: 'Update',
                    color: 'primary',
                    action: async function () {
                        self.updateinetConfirm.updateinetSubmit = true;
                        if (self.updateinetConfirm.transactionCode) {
                            self.confirmManageModalAlert.hide();
                            self.updateinet(data?.uId);
                        }
                    }.bind(this),
                },
            ],
        };
        this.confirmManageModalAlert.show();
    }

    updateinet(id: any) {
        this.loadingModal.show();
        this.orderService
            .updateInettranscode(id, this.updateinetConfirm.transactionCode)
            .subscribe({
                next: async (result: any) => {
                    const self = this;
                    self.getOrderRevision();
                    this.messageAlert = {
                        title: 'Success!',
                        message: 'Update INET Transactioncode success',
                        button: [
                            {
                                label: 'Close',
                                action: async function () {
                                    self.messageAlertModal.hide();
                                }.bind(this),
                            },
                        ],
                    };
                    setTimeout(() => {
                        this.loadingModal.hide();
                        this.messageAlertModal.show();
                    }, 500);
                },
                error: (error: any) => {
                    const self = this;
                    this.messageAlert = {
                        title: 'Error!',
                        message: error.error.error.errorMsg,
                        button: [
                            {
                                label: 'Close',
                                action: async function () {
                                    self.messageAlertModal.hide();
                                }.bind(this),
                            },
                        ],
                    };
                    setTimeout(() => {
                        this.loadingModal.hide();
                        this.messageAlertModal.show();
                    }, 500);
                },
            });
    }

    getInqDoc(id: string): Promise<any> {
        return new Promise((resolve) => {
            this.orderService.getInqDoc(id).subscribe({
                next: async (v: any) => {
                    resolve(v);
                },
                error: (e) => {
                    resolve(undefined);
                },
            });
        });
    }
}
