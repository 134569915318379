import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OndemandURLParams } from '../models/ondemand/ondemandURLParams.model';
import { OndemandErrorURLParams } from '../models/ondemand/ondemandErrorURLParams.model';
import { Ondemand, SearchOndemandRespone } from '../models/ondemand/searchOndemandRespone.model';
import { ResponesCustom } from '../models/responedata.model';
import { OndemandImportResponseDataModel } from '../entity/ondemandImport';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class OndemandService {

  constructor(
    private http: HttpClient,
    private userService : UserService
  ) { }
  private formatErrors(error: any) {
    return throwError(() => error);
}

  listOndemand(param: OndemandURLParams){
    let url: string = `${environment.api_url}/apibo/call/searchondemand`;
    let params = new HttpParams()
    .set('page', param.page)
    .set('rowPerPage', param.rowPerPage)
    .set('aboNumber', param.aboNumber)
    .set('docType', param.docType)
    .set('period', param.period)
    .set('year', param.year)
    .set('orderBy', param.orderBy)
    return this.http.get<SearchOndemandRespone>(url, { params });
}

getOndemandExportPdf(filePath:string,fileName:string): Observable<any> {
    let url = `${environment.api_url}/apibo/call/docondemandexportpdf`;
    return this.http.get(url,{
        headers: new HttpHeaders({
            'Accept': 'application/pdf'
        }),
        withCredentials: true,
        responseType: 'blob',
        params: {
            filePath : filePath,
            fileName : fileName
        }
    });
}

getOndemandExportZip(param: OndemandURLParams): Observable<any> {
        let url = `${environment.api_url}/apibo/call/docondemandexportzip`;
        let params = new HttpParams()
    .set('page', param.page)
    .set('rowPerPage', param.rowPerPage)
    .set('aboNumber', param.aboNumber)
    .set('docType', param.docType)
    .set('period', param.period)
    .set('year', param.year)
    .set('orderBy', param.orderBy)
        return this.http.get(url, {
            params,
            headers: new HttpHeaders({
                'Accept': 'application/zip'
            }),
            withCredentials: true,
            responseType: 'text'
        });
}

listOndemandErrorLog(param: OndemandErrorURLParams) {
    let url: string = `${environment.api_url}/apibo/call/searchondemandimporterror`;
    let params = new HttpParams()
    .set('page', param.page)
    .set('rowPerPage', param.rowPerPage)
    .set('fileName', param.fileName)
    .set('aboNumber', param.aboNumber)
    .set('docType', param.docType)
    .set('startDate', param.startDate)
    .set('endDate', param.endDate)
    .set('orderBy', param.orderBy)
    return this.http
        .get<ResponesCustom<OndemandImportResponseDataModel[]>>(url, { params })
        .pipe(catchError(this.formatErrors));
}


listOndemandPdfError(param: OndemandURLParams){
    let url: string = `${environment.api_url}/apibo/call/searchondemandpdferror`;
    let params = new HttpParams()
    .set('page', param.page)
    .set('rowPerPage', param.rowPerPage)
    .set('aboNumber', param.aboNumber)
    .set('docType', param.docType)
    .set('period', param.period)
    .set('year', param.year)
    .set('orderBy', param.orderBy)
    return this.http.get<SearchOndemandRespone>(url, { params });
}

searchabo(params: {aboNumber : string, detailLevelCd : string}) {
    let url: string = `${environment.api_url}/apibo/call/searchabo`;
    return this.http
        .get(url, { params })
        .pipe(catchError(this.formatErrors));
}

updateOndemandStatusPdf(bodyupdate:Ondemand): Observable<any> {
    let url = `${environment.api_url}/apibo/call/updateondemandstatuspdf`;
    let userInfo = this.userService.getUserInfo()
    let body = {
        ...bodyupdate,
        updateUser : userInfo?.fullName
    }
    return this.http.post(url,body);
}

}
