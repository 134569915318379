import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
    {
        name: 'Invoice',
        class : 'sidebar-blod',
        iconComponent: { name: 'cilBank' },
        children: [
            {
                variant: 'menu.ordersearch',
                name: 'Search Order',
                url: '/ordersearch',
                class : 'sidebar-normal'
            },
            {
                variant: 'menu.reconcile',
                name: 'Reconcile',
                url: '/reconcile',
                class : 'sidebar-normal'
            },
            {
                variant: 'menu.reconcile',
                name: 'Transaction',
                url: '/transaction',
                class : 'sidebar-normal',
                linkProps: {
                    state: { isDefaltTransaction: true },
                },
            },
        ],
    },
    {
        name: 'Ondemand',
        class : 'sidebar-blod',
        iconComponent: { name: 'cibAdobeAcrobatReader' },
        variant: 'menu.ondemandpdf',
        children: [
            {
                name: 'WHT/TAX50',
                url: '/ondemandpdfwht',
                class : 'sidebar-normal'
            },{
                name: 'BONUS',
                url: '/ondemandpdfbonusstatement',
                class : 'sidebar-normal'
            }
        ],
    },
    {
        name: 'Admin',
        class : 'sidebar-blod',
        iconComponent: { name: 'cilPeople' },
        children: [
            {
                variant: 'menu.user',
                name: 'User Managment',
                url: '/usermanagment',
                class : 'sidebar-normal'
            },
            {
                variant: 'menu.config',
                name: 'Config',
                url: '/config',
                class : 'sidebar-normal'
            },
            {
                variant: 'menu.ondemandimport',
                name: 'Ondemand Import ',
                url: '/ondemandimport',
                class : 'sidebar-normal'
            },
        ],
    },
    {
        name: 'Log',
        class : 'sidebar-blod',
        iconComponent: { name: 'cilStream' },
        children: [
            {
                variant: 'menu.apilog',
                name: 'Api Log',
                url: '/apilog',
                class : 'sidebar-normal'
            },
        ],
    },
];
