import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PreviewFileService {

  constructor(
    private http: HttpClient,
    private userService : UserService
  ) { }

  getFileBlob(url:string) {
    return this.http.get(url,{
        headers: new HttpHeaders({'Accept': 'application/pdf'}),
        responseType: 'blob'
    });
}

getFileBase64(url:string) {
    return this.http.get(url,{
        headers: new HttpHeaders({'Accept': 'text/plain'}),
        responseType: 'text'
    });
}

}
