import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-ondemand-import',
  templateUrl: './ondemand-import.component.html',
  styleUrls: ['./ondemand-import.component.scss']
})
export class OndemandImportComponent implements OnInit {
    constructor() { }

  ngOnInit(): void {
  }

}
