import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

declare const bootstrap: any;

// https://www.ag-grid.com/javascript-data-grid/component-cell-renderer/
// https://blog.ag-grid.com/cell-renderers-in-ag-grid-every-different-flavour/
@Component({
    selector: 'action-button-order-list',
    template: `
      <button (click)="openView()" name="btn_view_order" style="height: 30px;" id="btn_view_order" class="btn btn-pill btn-primary me-2 py-0">View</button>
      <button (click)="downloadZip()" name="btn_download_pdf" style="height: 30px;" id="btn_download_pdf" class="btn btn-pill btn-primary-50 me-2 py-0" *ngIf="this.orderData.hasPDFIcon">PDF</button>
      <!--
      <button (click)="sendEmail()" name="btn_send_email" id="btn_send_email" style="height: 30px;" class="btn btn-pill btn-primary me-2 py-0" *ngIf="this.orderData.hasPDFIcon">Send mail</button>
      -->
    `,
})
export class ActionButtonOrderList implements ICellRendererAngularComp {
    private params: any;
    public orderData: any;
    messageAlert: any = {
        title: '',
        message: '',
        button: []
    }

    agInit(params: any): void {
        this.params = params;
        this.orderData = this.params.data;
    }

    openView() {
        this.params.clickedOpenView(this.params.data);
    }

    downloadZip() {
        this.params.clickedDownloadZip(this.params.data);
    }

    sendEmail() {
        this.params.clickedSendEmail(this.params.data);
    }

    refresh(params: ICellRendererParams): boolean {
        return true;
    }
}

@Component({
    selector: 'action-button-order-transaction',
    template: `
    <div class="d-flex align-items-center">
        <label class="me-1">{{orderData.etaxStatusDateTime}}</label>
        <a href="javascript:void(0)" class="mt-2" (click)="openTransaction()"><i class='bx bxs-time fs-4'></i></a>
    </div>
    `,
})
export class ActionButtonOrderTransaction implements ICellRendererAngularComp {
    private params: any;
    public orderData: any;
    messageAlert: any = {
        title: '',
        message: '',
        button: []
    }

    agInit(params: any): void {
        this.params = params;
        this.orderData = this.params.data;
    }

    openTransaction() {
        this.params.clickedOpenTransaction(this.params.data);
    }

    refresh(params: ICellRendererParams): boolean {
        return true;
    }
}
