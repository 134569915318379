import { Injectable } from "@angular/core";
import { AuthUser, User, UserInfo, UserPerm } from "../models/auth/user.model";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
//import { Utils } from "src/app/shared/utils";
import { LocalService } from "./local.service";
import { DomSanitizer } from "@angular/platform-browser";
//import { resolve } from "dns";
import { ActivatedRoute, Router, RouterState } from '@angular/router';
import { LoginResponseModel } from "../models/auth/login.model";
import * as CryptoJS from "crypto-js";
import * as moment from 'moment';
import { OktaClaims } from "src/app/modules/login/login.component";

const AUTH_USER = "webAuthUser";
//const AUTH_USER_ADMIN = "authUserAdmin";
const USER_INFO = "webUserInfo";
const USER_PERM = "webUserPerm";
const TOKEN = "webToken";
const OPTION_TRANSACTION = "optionTransaction";


@Injectable({
    providedIn: 'root'
})
export class UserService {
    //private hybrisLoginErrorSubject = new BehaviorSubject<AuthUser>(null);
    //currentHybrisLoginError = this.hybrisLoginErrorSubject.asObservable();

    private sessionTimeoutSubject = new BehaviorSubject<number>(3600);
    public currentSessionTimeout = this.sessionTimeoutSubject.asObservable();

    private production = environment.production;

    constructor(
        private http: HttpClient,
        private localService: LocalService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    private formatErrors(error: any) {
        return throwError(error);
    }

    //getHybrisLoginErrorValue(): AuthUser {
    //  return this.hybrisLoginErrorSubject.value;
    //}

    //setHybrisLoginErrorValue(hybrisLoginError: AuthUser) {
    //  this.hybrisLoginErrorSubject.next(hybrisLoginError);
    //}

    setSessionTimeout(timeout: number) {
        this.sessionTimeoutSubject.next(timeout);
    }

    isLoggedIn() {
        return this.localService.getJsonValue(USER_INFO);
    }

    logout() {
        this.destroyAuth();
        //this.router.navigate(['items'], { relativeTo: this.route });
        //this.router.navigateByUrl("/login");
        /*if (environment.admin_mode) {
          location.href = `${environment.admin_amwaycoth_url}`;
        } else {
          location.href = `${environment.amwaycoth}`;
        }*/
        // this.router.navigate(["/login"]);
    }


    setAuth(authUser: AuthUser) {
        this.setSessionTimeout(environment.session_timeout);
        // this.localService.setJsonValue(AUTH_USER, JSON.stringify(hybrisLogin));
        {
            this.localService.setJsonValue(AUTH_USER, JSON.stringify(authUser));
        }

    }

    getAuthUser(): AuthUser {
        {
            return <AuthUser>JSON.parse(this.localService.getJsonValue(AUTH_USER));
        }
    }

    getUserInfo(): UserInfo {
        return <UserInfo>JSON.parse(this.localService.getJsonValue(USER_INFO));
    }

    setUserInfo(user: UserInfo) {
        this.localService.setJsonValue(USER_INFO, JSON.stringify(user));
    }

    getUserPerm(): UserPerm[] {
        return <UserPerm[]>JSON.parse(this.localService.getJsonValue(USER_PERM));
    }

    setUserPerm(perm: UserPerm[]) {
        this.localService.setJsonValue(USER_PERM, JSON.stringify(perm));
    }

    setJwt(token: any) {
        this.localService.setJsonValue(TOKEN, JSON.stringify(token));
    }

    destroyAuth() {
        localStorage.clear();
        this.localService.clearToken();
    }
    aesEncrypt(plainText: string) {
        const env: any = environment;
        const parsedkey = CryptoJS.enc.Utf8.parse(env.secret_key);
        const iv = CryptoJS.enc.Utf8.parse(env.secret_iv);
        const encrypted = CryptoJS.AES.encrypt(plainText, parsedkey, { iv: iv, mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return encrypted.toString(CryptoJS.format.Hex);
    };

    hashGenerate(data: any) {
        const env: any = environment;
        let hash = CryptoJS.SHA256(data + env.salt).toString();
        return hash;
    }

    hashVerify(data: any, hash: any) {
        const env: any = environment;
        let hashVerify = CryptoJS.SHA256(data + env.salt).toString();
        return hashVerify == hash;
    }

    login(username: string, password: string) {
        const headers = new HttpHeaders().set(
            "Content-Type",
            "application/json"
        );
        let encData = this.aesEncrypt(JSON.stringify(
            {
                "uName": username,
                "uPass": password,
                "expireTimestamp": moment(new Date(new Date().getTime() + (60 * 60 * 1000))).format(),
            }
        ));

        const body = {
            "encData": encData,
            "hashData": this.hashGenerate(encData)
        }

        const env: any = environment;

        return this.http
            .post(
                `${env.api_url}/apibo/call/login`,
                body,
                { headers }
            )
            .pipe(catchError(this.formatErrors));

        // return new Observable((observer) => {
        //     if (username == "admin" && password == "1234") {
        //         let response: LoginResponseModel = { token: "12345678" };
        //         observer.next(response);
        //     } else {

        //         observer.error("Invalid Login");
        //     }
        // });
    }

    getToken() {

        const headers = new HttpHeaders().set(
            "Content-Type",
            "application/x-www-form-urlencoded"
        );

        const env: any = environment;
        const body = {
            grantType: "client_credentials",
            clientId: env.client_id,
            clientSecret: env.client_secret
        };

        return this.http
            .post(
                `${env.api_url}/webauthorize/token`,
                body,
                { headers }
            )
            .pipe(catchError(this.formatErrors));
    }

    getUserByUserName(oktaClaims: OktaClaims) {
        const headers = new HttpHeaders().set(
            "Content-Type",
            "application/json"
        );

        let encData = this.aesEncrypt(JSON.stringify(
            {
                "userName": oktaClaims.userName,
                "fullName": oktaClaims.fullName,
                "email": oktaClaims.email,
                "profileName": oktaClaims.profileName,
                "expireTimestamp": moment(new Date(new Date().getTime() + (60 * 60 * 1000))).format(),
            }
        ));

        const body = {
            "encData": encData,
            "hashData": this.hashGenerate(encData)
        }

        const env: any = environment;

        return this.http
        .post(
            `${env.api_url}/apibo/call/userinfo`,
            body,
            { headers }
        )
        .pipe(catchError(this.formatErrors));
    }
}
