import { ShareModule } from './../../share.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReconcileComponent } from './reconcile.component';
import { TransactionComponent } from './transaction/transaction.component';
import { DropdownModule } from 'primeng/dropdown';
import { RouterModule } from '@angular/router';
import { ActionButtonTransaction } from './../components/action-button-transaction.component';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
    declarations: [
        ReconcileComponent,
        TransactionComponent,
        ActionButtonTransaction
    ],
    imports: [
        CommonModule,
        ShareModule,
        DropdownModule,
        RouterModule,
        AgGridModule.withComponents(
            [
                ActionButtonTransaction
            ]
        )
    ]
})
export class ReconcileModule { }
