import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OrderService {

    constructor(
        private http: HttpClient,
    ) { }

    getOrders(option: { q: any, pageinfo: any }): Observable<any> {
        let url = `${environment.api_url}/apibo/call/searchorder?page=${option.pageinfo.page}&rowPerPage=${option.pageinfo.rowPerPage}`;
        for (let key in option.q) {
            if (option.q[key]) {
                url += `&${key}=${option.q[key]}`;
            }
        }
        return this.http.get(url);
    }

    getOrderDetail(orderNo: any): Observable<any> {
        let url = `${environment.api_url}/apibo/call/orderdetail?page=1&rowPerPage=1&orderNo=${orderNo}`;
        return this.http.get(url);
    }

    getOrderRevision(option: { q: any, pageinfo: any }): Observable<any> {
        let url = `${environment.api_url}/apibo/call/orderrevision?page=${option.pageinfo.page}&rowPerPage=${option.pageinfo.rowPerPage}&orderNo=${option.q.orderNo}`;
        return this.http.get(url);
    }

    orderExportZip(orderNo: any): Observable<any> {
        let url = `${environment.api_url}/apibo/call/orderexportzip?orderNo=${orderNo}`;
        return this.http.get(url, {
            headers: new HttpHeaders({
                'Accept': 'application/zip'
            }),
            withCredentials: true,
            responseType: 'text'
        });
    }

    orderExportPdf(uId: any): Observable<any> {
        let url = `${environment.api_url}/apibo/call/docexportpdf?uId=${uId}`;
        return this.http.get(url, {
            headers: new HttpHeaders({
                'Accept': 'application/pdf'
            }),
            withCredentials: true,
            responseType: 'blob'
        });
    }

    orderExportXml(uId: any): Observable<any> {
        let url = `${environment.api_url}/apibo/call/docexportxml?uId=${uId}`;
        return this.http.get(url, {
            headers: new HttpHeaders({
                'Accept': 'application/xml'
            }),
            withCredentials: true,
            responseType: 'blob'
        });
    }

    docExport(uId: any, type: any): Observable<any> {
        let url = `${environment.api_url}/apibo/call/docexport?uId=${uId}&orderType=${type}`;
        return this.http.get(url, {
            headers: new HttpHeaders({
                'Accept': 'application/xml'
            }),
            withCredentials: true,
            responseType: 'blob'
        });
    }

    sendEmail(body: any) {
        let url = `${environment.api_url}/apibo/call/sendmail`;
        return this.http.post(url, body);
    }

    sendKinesis(body: any) {
        let url = `${environment.api_url}/apibo/call/uploadkinesis`;
        return this.http.post(url, body);
    }

    resendInet(uId: any) {
        let url = `${environment.api_url}/apibo/call/resendinet?uId=${uId}`;
        return this.http.get(url);
    }

    triggerHybris(uId: any) {
        let url = `${environment.api_url}/apibo/call/triggerhybris?uId=${uId}`;
        return this.http.get(url);
    }

    deleteDocrevision(uId: any) {
        let url = `${environment.api_url}/apibo/call/delete_docrevision?uId=${uId}`;
        return this.http.get(url);
    }

    getInqDoc(uId: any) {
        let url = `${environment.api_url}/apibo/call/inq_doc`;
        return this.http.get(url,{params:{uId:uId,type:'statusinet'}});
    }
    updateInettranscode(uId: string,transcode:string) {
        let url = `${environment.api_url}/apibo/call/update_inettranscode`;
        return this.http.get(url,{params:{uId:uId,transcode:transcode}});
    }
}
