import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviewFileComponent } from './preview-file.component';



@NgModule({
  declarations: [
    PreviewFileComponent
  ],
  imports: [
    CommonModule
  ]
})
export class PreviewFileModule { }
