<c-row>
    <c-col>
        <h2 cCardTitle class="mb-2"><strong class="text-primary">Transaction data</strong></h2>
        <c-card class="mb-3">
            <c-card-body>
                <form [classList]="'mt-2'" cForm>
                    <c-row [classList]="'px-2'">
                        <c-col [lg]="12">
                            <c-row class="mb-3">
                                <c-col [lg]="2">
                                    <label for="search_process_type" class="p-1 float-end">Process Type
                                        :</label>
                                </c-col>
                                <c-col [lg]="3">
                                    <select name="search_process_type" id="search_process_type" cSelect
                                        [(ngModel)]="searchOption.q.process">
                                        <option value="all">All Process Type</option>
                                        <option value="new_order">New Order</option>
                                        <option value="cancel_order_credit_note">Cancel Order [Credit Note]</option>
                                        <option value="cancel_order_debit_note">Cancel Order [Debit Note]</option>
                                        <option value="return_order">Return Order</option>
                                        <option value="edit_invoice">Edit Invoice/Credit Note
                                        </option>
                                    </select>
                                </c-col>
                                <c-col [lg]="2">
                                    <label for="search_sup_process" class="p-1 float-end">Sub Process :</label>
                                </c-col>
                                <c-col [lg]="4">
                                    <select name="search_sup_process" id="search_sup_process" cSelect
                                        [(ngModel)]="searchOption.q.step">
                                        <option value="get_order_from_hybris">Get order from Hybris</option>
                                        <option value="get_order_from_nextgen">Get order from Nextgen</option>
                                        <option value="sent_order_to_inet">Sent order to inet</option>
                                        <option value="receive_invoice_pdf">Receive eTax Invoice</option>
                                        <option value="send_mail_to_abo">Send mail to abo</option>
                                        <option value="send_trigger_to_hybris">Send trigger to Hybris</option>
                                        <option value="send_trigger_to_nextgen">Send trigger to Nextgen</option>
                                    </select>
                                </c-col>
                            </c-row>
                            <c-row class="mb-3">
                                <c-col [lg]="2">
                                    <label for="search_date_from" class="p-1 float-end">Date from :</label>
                                </c-col>
                                <c-col [lg]="3">
                                    <p-calendar [id]="'search_date_from'" [name]="'search_date_from'"
                                        [(ngModel)]="searchOption.q.startDate" dateFormat="dd/mm/yy"
                                        [styleClass]="'date-form'">
                                    </p-calendar>
                                </c-col>
                                <c-col [lg]="2">
                                    <label for="search_date_to" class="p-1 float-end">Date to :</label>
                                </c-col>
                                <c-col [lg]="3">
                                    <p-calendar [id]="'search_date_to'" [name]="'search_date_to'"
                                        [(ngModel)]="searchOption.q.endDate" dateFormat="dd/mm/yy"
                                        [styleClass]="'date-form'">
                                    </p-calendar>
                                </c-col>
                            </c-row>
                            <c-row class="mb-3">
                                <c-col [lg]="2">
                                    <label for="search_order_no" class="p-1 float-end">Order No :</label>
                                </c-col>
                                <c-col [lg]="3">
                                    <input class="form-control" name="search_order_no" id="search_order_no"
                                        [ngClass]="{'is-invalid': searchOption.q.orderNo && (!validateNumber(searchOption.q.orderNo) || searchOption.q.orderNo.length > 20)}"
                                        [(ngModel)]="searchOption.q.orderNo" type="text" />
                                    <small id="feedback_order_no" class="invalid-feedback"
                                        *ngIf="searchOption.q.orderNo && !validateNumber(searchOption.q.orderNo)">
                                        Please enter only number.
                                    </small>
                                    <small id="feedback_order_no" class="invalid-feedback"
                                        *ngIf="searchOption.q.orderNo && searchOption.q.orderNo.length > 20">
                                        Please enter Max charater is 20.
                                    </small>
                                </c-col>
                                <c-col [lg]="2">
                                    <label for="search_inv_cn_no" class="p-1 float-end">Doc No. :</label>
                                </c-col>
                                <c-col [lg]="3">
                                    <input class="form-control" name="search_inv_cn_no" id="search_inv_cn_no"
                                        [ngClass]="{'is-invalid': searchOption.q.docNo && searchOption.q.docNo.length > 20}"
                                        [(ngModel)]="searchOption.q.docNo" type="text" />
                                    <small id="feedback_order_no" class="invalid-feedback"
                                        *ngIf="searchOption.q.docNo && searchOption.q.docNo.length > 20">
                                        Please enter Max charater is 20.
                                    </small>
                                </c-col>
                            </c-row>
                            <c-row class="mb-3">
                                <c-col [lg]="2">
                                    <label for="search_result_status" class="p-1 float-end">Result Status
                                        :</label>
                                </c-col>
                                <c-col [lg]="4">
                                    <c-row class="position-check">
                                        <c-col [lg]="4" [xs]="12">
                                            <c-form-check class="float-lg-start">
                                                <input  cFormCheckInput id="search_status_success"
                                                    name="search_status_success" value="SUCCESS" type="checkbox"
                                                    [(ngModel)]="searchOption.q.statusSuccess"   />
                                                <label cFormCheckLabel
                                                    for="search_status_success">SUCCESS</label>
                                            </c-form-check>
                                        </c-col>
                                        <c-col [lg]="4" [xs]="12">
                                            <c-form-check class="float-lg-start">
                                                <input cFormCheckInput id="search_status_pending"
                                                    name="search_status_pending" value="PENDING" type="checkbox"
                                                    [(ngModel)]="searchOption.q.statusPending"   />
                                                <label cFormCheckLabel for="search_status_pending">PENDING</label>
                                            </c-form-check>
                                        </c-col>
                                        <c-col [lg]="4" [xs]="12">
                                            <c-form-check class="float-lg-start">
                                                <input cFormCheckInput id="search_status_fail"
                                                    name="search_status_fail" value="FAILED" type="checkbox"
                                                    [(ngModel)]="searchOption.q.statusFail"   />
                                                <label cFormCheckLabel for="search_status_fail">FAILED</label>
                                            </c-form-check>
                                        </c-col>
                                    </c-row>
                                    <!-- <select name="search_result_status" id="search_result_status" cSelect
                                        [(ngModel)]="searchOption.q.status">
                                        <option value="all">All</option>
                                        <option value="success">SUCCESS</option>
                                        <option value="pending">PENDING</option>
                                        <option value="failed">FAILED</option>
                                    </select> -->
                                </c-col>
                                <!-- <c-col [lg]="3"></c-col> -->
                            </c-row>
                        </c-col>

                    </c-row>
                    <c-row [classList]="'px-2'">
                        <c-col [lg]="6">
                            <c-row>
                                <c-col [lg]="3"></c-col> <!--[classList]="'me-5'"-->
                                <c-col [lg]="3">
                                    <input name="search_search_btn" cButton color="primary" type="button" value="Search"
                                        class="w-100 rounded mb-2" (click)="getTransaction()">
                                </c-col>
                                <c-col [lg]="3">
                                    <input name="search_search_btn" cButton color="primary-50" type="button"
                                        value="Reset" class="w-100 rounded" (click)="resetOption()"> <!--px-4 me-2-->
                                </c-col>
                            </c-row>
                        </c-col>
                    </c-row>
                </form>
            </c-card-body>
        </c-card>
    </c-col>
</c-row>

<c-row class="mt-3">
    <c-col>
        <h3 cCardTitle class="mb-2"><strong class="text-primary">Result</strong></h3>
        <c-card class="mb-3">
            <c-card-body>
                <c-row>
                    <c-col [sm]="6">
                        <h6>Total {{searchOption.pageinfo.totalRecord}} records Update on {{currentDatetime}}</h6>
                    </c-col>
                </c-row>
                <c-row>
                    <c-col [sm]="12" class="mb-2">
                        <c-dropdown>
                            <button cButton cDropdownToggle id="btn_export_file" color="primary">
                                Export
                            </button>
                            <ul cDropdownMenu>
                                <li><a cDropdownItem (click)="exportExcel()">Excel</a></li>
                                <li><a cDropdownItem (click)="exportCSV()">CSV</a></li>
                            </ul>
                        </c-dropdown>
                        <div class="float-end">
                            <span class="me-1">Show</span>
                            <select class="me-1" id="search_option_limit" (change)="getTransaction()"
                                [(ngModel)]="searchOption.pageinfo.rowPerPage">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                            <span>entries</span>
                        </div>
                    </c-col>
                    <c-col [sm]="12" class="mb-2">

                        <ag-grid-angular style="width: 100%; height: 50vh;" class="ag-theme-alpine"
                            [columnDefs]="orderColumnDefs" [rowData]="orderData"
                            [frameworkComponents]="frameworkComponents" [enableCellTextSelection]="true"
                            [enableRangeSelection]="true">
                        </ag-grid-angular>
                    </c-col>
                    <c-col [sm]="12">
                        <app-paging-tool [pageinfo]="searchOption.pageinfo" (onClickPage)="gotoPage($event)">
                        </app-paging-tool>
                    </c-col>
                </c-row>
            </c-card-body>
        </c-card>
    </c-col>
</c-row>

<div class="modal fade" id="loadingModal" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="logModal" tabindex="-1" aria-labelledby="logModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="logModalLabel" *ngIf="logData">Log ID : {{logData.uId}}</h5>
            </div>
            <div class="modal-body">
                <c-row *ngIf="logData">
                    <c-col [sm]="12" style="word-break: break-all;">
                        <c-row class="mb-2">
                            <c-col [sm]="3" class="text-end">Log Date Time :</c-col>
                            <c-col [sm]="9">{{logData.logDateTime | date: 'dd/MM/yyyy HH:mm:ss'}}</c-col>
                        </c-row>
                        <c-row class="mb-2">
                            <c-col [sm]="3" class="text-end">Data Step :</c-col>
                            <c-col [sm]="9">{{logData.datastep}}</c-col>
                        </c-row>
                        <c-row class="mb-2">
                            <c-col [sm]="3" class="text-end">Log Status :</c-col>
                            <c-col [sm]="9">{{logData.logStatus}}</c-col>
                        </c-row>
                        <c-row class="mb-2">
                            <c-col [sm]="3" class="text-end">Log Status Desc :</c-col>
                            <c-col [sm]="9">{{logData.errDesc}}</c-col>
                        </c-row>
                    </c-col>
                </c-row>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="logModal.hide()">Close</button>
            </div>
        </div>
    </div>
</div>
<app-message-alert-modal [messageAlert]="messageAlert"></app-message-alert-modal>
