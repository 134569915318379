import { ActionButtonDocLog } from './../components/action-button-doc-log.component';
import { ActionButtonOrderList, ActionButtonOrderTransaction } from './../components/action-button-order-list.component';
import { ActionButtonOrderDetailList, ActionButtonSourceOrderDetailList } from './../components/action-button-order-detail-list.component';
import { ShareModule } from './../../share.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrderComponent } from './order.component';
import { AgGridModule } from 'ag-grid-angular';
import { TrndocactionComponent } from './trndocaction/trndocaction.component';

@NgModule({
    declarations: [
        OrderDetailComponent,
        OrderComponent,
        ActionButtonOrderDetailList,
        ActionButtonSourceOrderDetailList,
        ActionButtonOrderList,
        ActionButtonOrderTransaction,
        TrndocactionComponent
    ],
    imports: [
        CommonModule,
        ShareModule,
        AgGridModule.withComponents(
            [
                ActionButtonOrderDetailList,
                ActionButtonSourceOrderDetailList,
                ActionButtonOrderList,
                ActionButtonOrderTransaction,
                ActionButtonDocLog
            ]
        )
    ]
})
export class OrderModule { }
