import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

// https://www.ag-grid.com/javascript-data-grid/component-cell-renderer/
// https://blog.ag-grid.com/cell-renderers-in-ag-grid-every-different-flavour/
@Component({
    selector: 'action-button-api-log',
    template: `
      <button (click)="openView()" name="btn_open_view" id="btn_open_view" style="height: 30px;" class="btn btn-pill btn-primary me-2 py-0">View</button>
    `,
})
export class ActionButtonApiLog implements ICellRendererAngularComp {
    private params: any;
    public logData: any;

    agInit(params: any): void {
        this.params = params;
        this.logData = this.params.data;
    }

    openView(){
        this.params.clickedOpenView(this.logData);
    }

    refresh(params: ICellRendererParams): boolean {
        return true;
    }
}
