import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'checkbox-cell',
    template: `<input class="form-check-input mt-2" [disabled]="isModeView" type="checkbox" [checked]="params.value" (change)="onChange($event)">`
})
export class CheckboxCellRendererComponent implements ICellRendererAngularComp {

    public params: any;
    public cellValue!: string;
    public isModeView: boolean = false;

   agInit(params: ICellRendererParams): void {
    this.params = params;
    this.isModeView = this.params.isModeView ? true : false;
     // console.log('params =', params)
    }

    public onChange(event:any) {
        if(this.params.colDef?.field != undefined){
            this.params.data[this.params.colDef.field] = event.currentTarget.checked;
            this.params.select(this.params);
        }

    }

    refresh(params: ICellRendererParams): boolean {
        return true;
    }
}
