import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigComponent } from './config.component';
import { ShareModule } from 'src/app/share.module';
import { ConfigActionButton } from './components/config.actionbtn/config.actionbtn.component';




@NgModule({
  declarations: [
    ConfigComponent,
    ConfigActionButton
  ],
  imports: [
    CommonModule,
    ShareModule
  ]
})
export class ConfigModule { }
