import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import * as moment from 'moment';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { SearchOrderPaymentURLParams } from 'src/app/core/models/orderpayment/orderpaymentRespone.model';
import { SearchOrderPaymentRespone } from 'src/app/core/models/orderpayment/orderpaymentURLParams.model';
import { Pageinfo } from 'src/app/core/models/responedata.model';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { OrderpaymentService } from 'src/app/core/services/orderpayment.service';
declare const bootstrap: any;
import * as exportFile from 'xlsx';
import { Location } from '@angular/common';

interface SearchOption {
    startDate: Date;
    endDate: Date;
    noInv: boolean;
}

@Component({
    selector: 'app-order-payment',
    templateUrl: './order-payment.component.html',
    styleUrls: ['./order-payment.component.scss'],
})
export class OrderPaymentComponent implements OnInit {
    searchOption: SearchOption = {
        startDate: new Date(moment().format()),
        endDate: new Date(moment().format()),
        noInv: false,
    };

    pageInfo: Pageinfo = {
        page: 1,
        rowPerPage: 10,
        totalRecord: 0,
        orderBy: '',
        allpage: 0,
    };
    currentDatetime: string = '';
    searched: boolean = false;

    rowData: SearchOrderPaymentRespone[] = [];

    public defaultColDef: ColDef = {
        filter: false,
        resizable: true,
        autoHeight: true,
        wrapText: true,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
            return 0;
        },
        sortingOrder: ['asc', 'desc'],
    };

    orderColumnDefs: ColDef[] = [
        {
            autoHeight: true,
            headerName: 'OrderNo',
            field: 'orderNo',
            width: 150,
        },
        {
            autoHeight: true,
            headerName: 'PaymentSaleAccount',
            valueGetter: (params) =>
                `${params.data?.paymentSalesAccountId || ''} ${
                    params.data?.paymentSalesName || ''
                }`,
            width: 270,
        },
        {
            autoHeight: true,
            headerName: 'OrderPaymentValue',
            valueGetter: (params) =>
                params.data?.orderPaymentValue
                    ? params.data?.orderPaymentValue.toLocaleString('en-GB', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                      })
                    : '',
            width: 200,
        },
        {
            autoHeight: true,
            headerName: 'PaymentDate',
            valueGetter: (params) =>
                params.data?.paymentDate
                    ? moment(params.data.paymentDate).format(
                          'DD/MM/YYYY HH:mm:ss'
                      )
                    : '',
            width: 200,
        },
        {
            autoHeight: true,
            headerName: 'PaymentStatus',
            field: 'orderPaymentStatus',
            width: 150,
        },
        {
            autoHeight: true,
            headerName: 'PaymentMethod(PaymentValue)',
            cellRenderer: (params: any) => {
                if (params?.data?.paymentsDetail) {
                    const listDetail = params?.data?.paymentsDetail.split(',');
                    const manage = listDetail.map((element: any) => {
                        const spEl = element.split('|');
                        return `${spEl?.[0]} (${
                            spEl?.[1]
                                ? Number(spEl?.[1]).toLocaleString('en-GB', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                  })
                                : '0.00'
                        })`;
                    });
                    return manage.join('<br/>');
                }

                return '';
            },
            minWidth: 272,
            flex: 1,
        },
    ];

    constructor(
        private orderpaymentService: OrderpaymentService,
        private headerNavigateService: HeaderNavigateService,
        private acRoute: ActivatedRoute,
        private location: Location
    ) {}

    ngOnInit(): void {
        let headerNavigateModelList: Array<HeaderNavigateModel> = [
            { label: 'Home', url: '/', attributes: { title: 'Home' } },
            { label: 'Reconcile', url: '/reconcile' },
            { label: 'Order Payment', url: '/orderpayment' },
        ];
        this.headerNavigateService.setNavigate(headerNavigateModelList);
        const { startDate, endDate, noInv } =
            this.acRoute.snapshot?.queryParams;
        this.location.replaceState('/orderpayment');
        if (
            'startDate' in this.acRoute.snapshot?.queryParams &&
            'endDate' in this.acRoute.snapshot?.queryParams
        ) {
            this.searchOption = {
                startDate: new Date(moment(startDate).format()),
                endDate: new Date(moment(endDate).format()),
                noInv: JSON.parse(noInv) == true,
            };
            this.searchOrderPayment();
        }
    }

    searchOrderPayment(isReset?: boolean) {
        this.rowData = [];
        if (!this.searchOption.startDate) {
            this.alertMessage(
                'danger',
                'Error!',
                'bx bx-error-circle',
                'Start Date required.'
            );
            return;
        }
        if (!this.searchOption.endDate) {
            this.alertMessage(
                'danger',
                'Error!',
                'bx bx-error-circle',
                'End Date required.'
            );
            return;
        }

        const loadingModal = new bootstrap.Modal(
            document.getElementById('loadingModal'),
            { backdrop: 'static', keyboard: false }
        );
        loadingModal.show();
        if (isReset) {
            this.pageInfo.page = 1;
        }
        const request: SearchOrderPaymentURLParams = {
            page: this.pageInfo.page,
            rowPerPage: this.pageInfo.rowPerPage,
            startDate: moment(this.searchOption.startDate).format(
                'YYYY-MM-DDT00:00:00+07:00'
            ),
            endDate: moment(this.searchOption.endDate).format(
                'YYYY-MM-DDT23:59:59+07:00'
            ),
            noInv: this.searchOption.noInv,
        };
        this.orderpaymentService.searchOrderPayment(request).subscribe({
            next: (res) => {
                this.searched = true;
                this.currentDatetime = moment().format('DD/MM/YYYY HH:mm:ss');
                if (res.success) {
                    this.rowData = res.data;
                    this.pageInfo.totalRecord = res.pageinfo.totalRecord;
                    this.pageInfo.allpage = Math.ceil(
                        this.pageInfo.totalRecord / this.pageInfo.rowPerPage
                    );
                }
                setTimeout(() => {
                    loadingModal.hide();
                }, 500);
            },
            error: (e: any) => {
                this.pageInfo.totalRecord = 0;
                this.pageInfo.allpage = 0;

                setTimeout(() => {
                    loadingModal.hide();
                }, 500);
            },
        });
    }

    //Alert Modal

    messageAlert: any = {
        title: '',
        message: '',
        button: [],
    };

    alertMessage(
        theme: string,
        title: string,
        icon: string | null,
        message: string
    ) {
        const messageAlertModal = new bootstrap.Modal(
            document.getElementById('messageAlertModal')
        );
        const self = this;
        this.messageAlert = {
            theme: theme,
            title: title,
            icon: icon,
            message: message,
            button: [
                {
                    label: 'OK',
                    action: async function () {
                        messageAlertModal.hide();
                    }.bind(this),
                    color: 'warning',
                },
            ],
        };
        messageAlertModal.show();
    }

    async gotoPage(page: any) {
        if (
            page > 0 &&
            page <= (this.pageInfo?.allpage ? this.pageInfo?.allpage : 0)
        ) {
            this.pageInfo.page = page;
            this.searchOrderPayment();
        }
    }

    onClickReset() {
        this.searched = false;
        this.rowData = [];
        this.searchOption = {
            startDate: new Date(moment().format()),
            endDate: new Date(moment().format()),
            noInv: false,
        };
    }

    exportOrderDetail(exportTo: any = 'xlsx'): any {
        const loadingModal = new bootstrap.Modal(
            document.getElementById('loadingModal'),
            { backdrop: 'static', keyboard: false }
        );
        loadingModal.show();
        const request: SearchOrderPaymentURLParams = {
            page: this.pageInfo.page,
            rowPerPage: this.pageInfo.totalRecord,
            startDate: moment(this.searchOption.startDate).format(
                'YYYY-MM-DDT00:00:00+07:00'
            ),
            endDate: moment(this.searchOption.endDate).format(
                'YYYY-MM-DDT23:59:59+07:00'
            ),
            noInv: this.searchOption.noInv,
        };
        this.orderpaymentService.searchOrderPayment(request).subscribe({
            next: (res) => {
                if (res.success) {
                    const data: any = [
                        [
                            'OrderNo',
                            'PaymentSaleAccount',
                            'OrderPaymentValue',
                            'PaymentDate',
                            'PaymentStatus',
                            'PaymentMethod(PaymentValue)',
                        ],
                    ];

                    if (res.data.length) {
                        for (let order of res.data) {
                            const listDetail = order?.paymentsDetail.split(',');
                            const manage = listDetail.map((element: any) => {
                                const spEl = element.split('|');
                                return `${spEl?.[0]} (${spEl?.[1]})`;
                            });
                            const newRow = [
                                order?.orderNo,
                                `${order?.paymentSalesAccountId || ''} ${
                                    order?.paymentSalesName || ''
                                }`,
                                order?.orderPaymentValue,
                                order?.paymentDate
                                    ? moment(order.paymentDate).format(
                                          'DD/MM/YYYY HH:mm:ss'
                                      )
                                    : '',
                                order?.orderPaymentStatus,
                                manage.join('\n'),
                            ];
                            data.push(newRow);
                        }
                    }
                    let fileOption: any = {
                            bookType: exportTo,
                            bookSST: true,
                            type: 'binary',
                        },
                        sheetName = 'Sheet1',
                        worksheet = exportFile.utils.json_to_sheet(data, {
                            skipHeader: true,
                        }),
                        workbook = exportFile.utils.book_new(),
                        fileName = `export-order-detail-${moment().format(
                            'DDMMYYYYHHSS'
                        )}.${fileOption.bookType}`;

                    exportFile.utils.book_append_sheet(
                        workbook,
                        worksheet,
                        sheetName
                    );
                    exportFile.writeFile(workbook, fileName, fileOption);
                }
                setTimeout(() => {
                    loadingModal.hide();
                }, 500);
            },
            error: (e: any) => {
                this.pageInfo.totalRecord = 0;
                this.pageInfo.allpage = 0;

                setTimeout(() => {
                    loadingModal.hide();
                }, 500);
            },
        });
    }
}
