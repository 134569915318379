import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-paging-tool',
    templateUrl: './paging-tool.component.html',
    styleUrls: ['./paging-tool.component.scss']
})
export class PagingToolComponent implements OnInit {
    @Input() pageinfo: any = {
        page: 1,
        rowPerPage: 10,
        totalRecord: 0,
        allpage: 0
    };
    pageNumber: any = [];
    @Output() onClickPage = new EventEmitter<any>();
    constructor() { }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
        this.getdisplayPage(this.pageinfo.page);
        }, 0);
    }

    getdisplayPage(now: any) {
        let allPage: any = [],
            startPage: any = now - 4,
            endPage: any = now + 5;

        if (startPage < 0) {
            startPage = 1;
            endPage = 10;
        }
        else if (endPage > this.pageinfo.allpage) {
            startPage = this.pageinfo.allpage - 10;
            endPage = this.pageinfo.allpage;
        }

        for (let now = startPage; now <= endPage; now++) {
            if (now <= this.pageinfo.allpage && now > 0) {
                allPage.push(now);
            }
        }

        this.pageNumber = allPage;
        setTimeout(() => {
            this.getdisplayPage(this.pageinfo.page);
        }, 1000);
    }

    getShowingFrom() {
        let showingFrom: number = ((this.pageinfo.rowPerPage * this.pageinfo.page) - (this.pageinfo.rowPerPage - 1));
        return (showingFrom < this.pageinfo.totalRecord) ? showingFrom : this.pageinfo.totalRecord;
    }

    getShowingTo() {
        let showingTo: number = this.pageinfo.page * this.pageinfo.rowPerPage;
        return (showingTo < this.pageinfo.totalRecord) ? showingTo : this.pageinfo.totalRecord;
    }

    async gotoPage(page: any) {
        if (page > 0 && page <= this.pageinfo.allpage) {
            this.onClickPage.emit(page);
            this.getdisplayPage(page);
        }
    }

    async skipPage() {

    }


}
