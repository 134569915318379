import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrderPaymentComponent } from './order-payment.component';
import { RouterModule } from '@angular/router';
import { DropdownModule } from '@coreui/angular';
import { AgGridModule } from 'ag-grid-angular';
import { ShareModule } from './../../share.module';
@NgModule({
    declarations: [OrderPaymentComponent],
    imports: [
        CommonModule,
        ShareModule,
        DropdownModule,
        RouterModule,
        AgGridModule.withComponents([]),
    ],
})
export class OrderPaymentModule {}
