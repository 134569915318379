import { Component, OnInit } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { MessageService } from 'primeng/api';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { UserService } from 'src/app/core/services/user.service';
import { ConfigActionButton } from './components/config.actionbtn/config.actionbtn.component';
declare const bootstrap: any;
import * as moment from 'moment';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';

interface Configuration {
    uId?: number
    env?: string
    configGroup?: string
    configCode?: string
    configValue?: string
    configNameTh?: string
    configNameEn?: string
    configStatus?: string
    configEffectiveDateTime?: string
    configExpireDateTime?: string
}

interface SearchOption {
    configCode: string;
}

interface PageInfo {
    page: number;
    rowPerPage: number;
    totalRecord: number;
    allpage: number;
}

@Component({
    selector: 'app-config',
    templateUrl: './config.component.html',
    styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

    role: string = '';
    userName: string = '';
    configurationList: Configuration[] = [];
    configuration: Configuration = {}
    pageNumber: number = 1;
    asingRoleConditionModal: boolean = false;
    isValidAbo: boolean = false;

    configStatus: string[] = ["ACTIVE", "INACTIVE"];
    selectedStatus: string = "";
    configEffectiveDatetime: Date = moment().startOf('day').toDate();
    configExpireDatetime: Date = moment().startOf('day').toDate()

    actionBtnOptions: any = {
        editConfig: false,
    };

    ruleColumnDefs: ColDef[] = [];


    private gridApi!: GridApi;

    searchOptions: SearchOption = {
        configCode: '',
    };

    pageInfo: PageInfo = {
        page: 1,
        rowPerPage: 10,
        totalRecord: 0,
        allpage: 0,
    };

    loadingModal: any;
    messageAlertModal: any;
    permCode: string = "menu.config";

    constructor(
        private messageService: MessageService,
        private userService: UserService,
        private configurationService: ConfigurationService,
        private headerNavigateService : HeaderNavigateService
    ) { }

    ngOnInit() {
        this.getPermission();
        this.initialColDef();
        this.initialHeaderNavigate();
    }

    initialHeaderNavigate() {
        let headerNavigateModelList: Array<HeaderNavigateModel> = [
            { label: 'Home', url: '/', attributes: { title: 'Home' } },
            { label: 'Config', url: '/config' },
        ];
        this.headerNavigateService.setNavigate(headerNavigateModelList);
    }

    ngAfterViewInit(): void {
        this.loadingModal = new bootstrap.Modal(document.getElementById('loadingModal'), { backdrop: 'static', keyboard: false });
        this.messageAlertModal = new bootstrap.Modal(document.getElementById('messageAlertModal'));

        this.searchRule('');
    }

    ngOnDestroy() {
        const messageAlertModal: any = document.getElementById('messageAlertModal');
        messageAlertModal.remove();
        const loadingModal: any = document.getElementById('loadingModal');
        loadingModal.remove();
    }

    getPermission() {
        const userInfo = this.userService.getUserInfo() as any;
        const userPerm = this.userService.getUserPerm() as any;

        this.role = userInfo.role;
        this.userName = userInfo.fullName;
        // switch (this.role) {
        //     case 'admin':
        //         this.actionBtnOptions.editConfig = true;
        //         this.isValidAbo = true;
        //         break;
        //     default:
        //         this.actionBtnOptions.editConfig = false;
        //         this.isValidAbo = true;
        //         break;
        // }

        //check config permission canUpdate
        const configPerm = userPerm.find((perm: any) => perm.permcode == this.permCode);
        if(configPerm && configPerm.canUpdate){
            this.actionBtnOptions.editConfig = true;
        }
    }

    frameworkComponents = {
        actionButton: ConfigActionButton,
    }

    initialColDef() {
        this.ruleColumnDefs = [
            {
                headerName: 'Action',
                cellRenderer: 'actionButton',
                cellRendererParams: {
                    onClickEditConfig: (data: any) => {
                        this.onClickEditConfig(data);
                    },
                    actionBtnOptions: this.actionBtnOptions
                },
                width: 180,
            },
            {
                headerName: "uId",
                field: 'uId',
                sortable: true,
                width: 100
            },
            {
                headerName: "env",
                field: 'env',
                sortable: true,
                width: 100
            },
            {
                headerName: 'configGroup',
                field: 'configGroup',
                sortable: true,
                width: 160
            },
            {
                headerName: 'configCode',
                field: 'configCode',
                sortable: true,
                width: 250
            },
            {
                headerName: 'configValue',
                field: 'configValue',
                sortable: true,
                width: 250
            },
            {
                headerName: 'configNameTh',
                field: 'configNameTh',
                sortable: true,
                width: 250
            },
            {
                headerName: 'configNameEn',
                field: 'configNameEn',
                sortable: true,
                width: 250
            },
            {
                headerName: 'configStatus',
                field: 'configStatus',
                sortable: true,
                width: 130
            },
            {
                headerName: 'configEffectiveDateTime',
                valueGetter: (params) => params.data.configEffectiveDateTime ? moment(params.data.configEffectiveDateTime).format() : "",
                sortable: true,
                width: 250
            },
            {
                headerName: 'configExpireDateTime',
                valueGetter: (params) => params.data.configExpireDateTime ? moment(params.data.configExpireDateTime).format() : "",
                sortable: true,
                width: 250
            },
        ]
    }

    async onClickSearch() {
        this.pageNumber = 1;
        if (this.searchOptions.configCode == '') {
            this.alertMessage('danger', 'Input invalid', 'bx bx-error-circle', 'User Name must be string')
            return;
        }
        this.configurationList = []
        this.searchRule(this.searchOptions.configCode);
    }

    onClickClear() {
        this.pageNumber = 1
        this.searchOptions = {
            configCode: ''
        };
        this.configurationList = []
        this.searchRule('');
    }

    onClickEditConfig(config: Configuration) {
        this.isValidAbo = true;
        this.configuration = config;
        this.selectedStatus = config.configStatus || "";
        this.configEffectiveDatetime = config.configEffectiveDateTime ? moment(config.configEffectiveDateTime).toDate() : moment().startOf('day').toDate();
        this.configExpireDatetime = config.configExpireDateTime ? moment(config.configExpireDateTime).toDate() : moment().startOf('day').toDate();
        this.asingRoleConditionModal = true
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;

    }

    async gotoPage(page: any) {
        if (page > 0 && page <= this.pageInfo.allpage) {
            this.pageInfo.page = page;
            await this.searchRule(this.searchOptions.configCode);
        }
    }

    searchRule(textSearch: string) {
        this.loadingModal.show();
        this.configurationService.getConfigs(textSearch, String(this.pageInfo.page), String(this.pageInfo.rowPerPage)).subscribe((res: any) => {
            if (res.success && res.data.length > 0) {
                this.configurationList = res.data as Configuration[];
                this.pageInfo.page = Number(res.pageinfo.page);
                this.pageInfo.rowPerPage = Number(res.pageinfo.rowPerPage)
                this.pageInfo.totalRecord = Number(res.pageinfo.totalRecord)
                this.pageInfo.allpage = Math.ceil(this.pageInfo.totalRecord / this.pageInfo.rowPerPage);
            }
            setTimeout(() => {
                this.loadingModal.hide();
            }, 500);

        }, (error: any) => {
            this.alertMessage('danger', 'Error!', 'bx bx-error-circle', error.error.error.errorMsg)
            this.loadingModal.hide();
        }
        )
    }

    saveCondition() {


        if (this.configuration.configValue == undefined || this.configuration.configValue == null || this.configuration.configValue.trim() == "") {
            this.alertMessage('danger', 'Warn!', 'bx bx-error-circle', 'Require configValue')
            return;
        }

        if (this.selectedStatus == undefined || this.selectedStatus == null || this.selectedStatus == "") {
            this.alertMessage('danger', 'Warn!', 'bx bx-error-circle', 'Require status')
            return;
        }

        if(moment(this.configExpireDatetime).isSameOrBefore(this.configEffectiveDatetime)){
            this.alertMessage('danger', 'Warn!', 'bx bx-error-circle', 'ExpireDateTime must be greater than EffectiveDatetime');
            return;
        }

        this.loadingModal.show();
        this.configuration.configStatus = this.selectedStatus;
        this.configuration.configEffectiveDateTime = moment(this.configEffectiveDatetime).format();
        this.configuration.configExpireDateTime = moment(this.configExpireDatetime).format();

        this.configurationService.updateConfig(this.configuration).subscribe({
            next: async (res: any) => {
                if (res.success && res.data) {
                    const list = this.configurationList;
                    this.configurationList = []
                    this.closeAsingRoleConditionModal()
                    this.loadingModal.hide();
                    setTimeout(() => {
                        this.alertMessage('success', 'Update User', 'bx bx-check-circle', 'Update config successfully');
                    }, 500);


                    this.searchRule('');

                }
            },
            error: (error: any) => {
                this.loadingModal.hide();
                this.alertMessage('danger', 'Error', 'bx bx-error-circle', error.error.error.errorMsg)

            }
        })
    }

    closeAsingRoleConditionModal() {
        this.asingRoleConditionModal = false
        this.configuration = {}
        this.isValidAbo = false;
    }


    messageAlert: any = {
        title: '',
        message: '',
        button: []
    }
    alertMessage(theme: string, title: string, icon: string | null, message: string) {
        this.messageAlertModal.show();
        const self = this;
        this.messageAlert = {
            theme: theme,
            title: title,
            icon: icon,
            message: message,
            button: [
                {
                    label: 'OK',
                    action: async function () {
                        self.messageAlertModal.hide();
                    }.bind(this),
                    color: 'warning'
                }
            ]
        };
    }

}
