import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, fromEvent } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ReconcileService {
    constructor(private http: HttpClient) {}

    getReconcile(dateOption: any): Observable<any> {
        let url = `${environment.api_url}/apibo/call/searchreconcile?startDate=${dateOption.startDate}&endDate=${dateOption.endDate}`;
        return this.http.get(url);
    }

    getReconcileDetail(option: { q: any; pageinfo: any }): Observable<any> {
        let url = `${environment.api_url}/apibo/call/reconciledetail?page=${option.pageinfo.page}&rowPerPage=${option.pageinfo.rowPerPage}`;
        for (let key in option.q) {
            if (option.q[key]) {
                url += `&${key}=${option.q[key]}`;
            }
        }
        return this.http.get(url);
    }

    getTransactionLog(
        uId: number,
        process: string,
        step: string
    ): Observable<any> {
        let url = `${environment.api_url}/apibo/call/transactionlog?uId=${uId}&process=${process}&step=${step}`;
        return this.http.get(url);
    }

    getPaidOrderCount(dateOption: any): Observable<any> {
        const url = `${environment.api_url}/apibo/call/getpaidordercount?startDate=${dateOption.startDate}&endDate=${dateOption.endDate}`;
        return this.http.get(url);
    }
}
