import { filter } from 'rxjs/operators';
import { deepCopy } from '@angular-devkit/core/src/utils/object';
import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import * as moment from 'moment';
import { OndemandResponseDataModel } from 'src/app/core/entity/ondemand';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { OndemandService } from 'src/app/core/services/ondemand.service';
import { OndemandURLParams } from 'src/app/core/models/ondemand/ondemandURLParams.model';
import { CheckboxCellRendererComponent } from '../../components/checkbox-cell-renderer.component';
import { Ondemand } from 'src/app/core/models/ondemand/searchOndemandRespone.model';
declare const bootstrap: any;
declare var $: any;

interface OndemandformData {
    docType: string;
    aboNumber: string | undefined | null;
    year: string | undefined | null;
    period: string | undefined | null;
    page: number,
    rowPerPage: number,
    orderBy: string | undefined | null
}

interface AgGridOptionModel {
    gridApi: any,
    rowData: any,
    columnDefs: ColDef[],
    defaultColDef: ColDef,
    frameworkComponents: any
}

interface PeriodModel {
    month: string,
    value: string,
}

const DOC_TYPE_WHT = "wht";
const DOC_TYPE_BONUS = "bonus";


@Component({
    selector: 'app-pdf-error',
    templateUrl: './pdf-error.component.html',
    styleUrls: ['./pdf-error.component.scss']
})
export class PdfErrorComponent implements OnInit {


    elementname = 'OndemandPdfError';
    last10MonthsList: { value: string, label: string }[] = [];
    searched = false;
    checkValideSearch = false;
    periodList: PeriodModel[] = [];
    // searchRowData: OndemandResponseDataModel[] = []
    requestId: string = '';
    formData: OndemandformData = {
        docType: DOC_TYPE_WHT,
        aboNumber: '',
        year: '',
        period: '',
        page: 1,
        rowPerPage: 50,
        orderBy: ''
    }

    requestExportZipPdf!: OndemandURLParams;

    pageinfo = {
        page: 1,
        rowPerPage: 50,
        totalRecord: 0,
        allpage: 0
    }
    currentDatetime: any = '';
    listDocType = [{ name: 'Withholding TAX', value: DOC_TYPE_WHT }, { name: 'Bonus Statement', value: DOC_TYPE_BONUS }];
    minPeriod = '';
    maxPeriod = '';
    accountLocalName = '';
    loadingModal : any;
    agGridOption: AgGridOptionModel = {
        gridApi: null,
        rowData: [],
        defaultColDef: {
            sortable: false,
            suppressMovable: true,
            filter: false,
            comparator: () => { return 0 },
            sortingOrder: ['asc', 'desc']
        },
        frameworkComponents: {
            checkboxCellRenderer: CheckboxCellRendererComponent
        },
        columnDefs: [

            {
                // headerName: 'Select',
                width: 40,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                // cellRenderer: 'checkboxCellRenderer',field: 'isCheck',
                // cellRendererParams: {
                //     select: (data: any) => {
                //         this.onSelectManualListIsDefault(data);

                //     },
                // },
                sortable: false,
                cellStyle: {textAlign: 'center'}
            } , {
                headerName: 'Doc Type',
                field: 'docType',
                width: 100
            } , {
                headerName: 'Year',
                field: 'year',
                width: 100
            } , {
                headerName: 'Period',
                field: 'period',
                width: 100
            } , {
                headerName: 'ABO Number',
                field: 'aboNumber',
                width: 130
            }, {
                headerName: 'Status Import',
                field: 'status',
                width: 130
            } , {
                headerName: 'Status File Exist',
                field: 'statusFileExist',
                width: 150
            } , {
                headerName: 'Import version',
                cellRenderer: (param: any) => {
                    return moment(param.data.lastUpdate).format('YYYY-MM-DD HH:mm:ss')
                }
            }  , {
                headerName: 'File Name',
                field: 'fileName',
                width : 500
            } , {
                headerName: 'File Path',
                field: 'filePath',
                width : 700
            }

        ]
    };
    constructor(
        private headerNavigateService: HeaderNavigateService,
        private ondemandService: OndemandService
    ) { }

    ngOnInit(): void {
        this.initialHeaderNavigate();

        this.gennerateYear(10);

        const today = new Date();
        this.minPeriod = moment(new Date(today.getFullYear() - 3, today.getMonth(), 1)).format("YYYY-MM");
        this.maxPeriod = moment(new Date()).format("YYYY-MM");
        this.periodList = [{
            month: 'Period',
            value: ''
        }]
        for (var i = 0; i <= 11; i += 1) {
            this.periodList.push({
                month: moment(new Date(today.getFullYear(), i, 1)).format("MMMM"),
                value: `${i + 1}`.padStart(2, '0')
            })
        }


    }

    ngAfterViewInit(): void {
        this.loadingModal = new bootstrap.Modal(document.getElementById(`${this.elementname}loadingModal`), { backdrop: 'static', keyboard: false });

    }
    gennerateYear(numYear: number) {
        this.last10MonthsList = [{
            value: '',
            label: 'Year'
        }];
        const today = new Date();
        for (var i = 0; i <= numYear; i += 1) {
            const date = new Date(today.getFullYear() - i, today.getMonth(), 1);
            if (numYear == 10) {
                if (moment(date).format("YYYY") >= '2022') {
                    this.last10MonthsList.push({
                        value: moment(date).format("YYYY"),
                        label: moment(date).format("YYYY")
                    })
                }
            }
            if (numYear == 4) {
                if (moment(date).format("YYYY") >= '2023') {
                    this.last10MonthsList.push({
                        value: moment(date).format("YYYY"),
                        label: moment(date).format("YYYY")
                    })
                }
            }

        }
    }

    ngOnDestroy() {

    }

    initialHeaderNavigate() {
        let headerNavigateModelList: Array<HeaderNavigateModel> = [
            { label: 'Home', url: '/', attributes: { title: 'Home' } },
            { label: 'PDF Error', url: '/ondemandimport' },
        ];
        this.headerNavigateService.setNavigate(headerNavigateModelList);
    }

    searchListOndemand() {
        this.checkValideSearch = true;

        // if(!this.formData.aboNumber){
        //    return;
        // }
        if (!this.formData.year) {
            return;
        }

        if (this.formData.docType == DOC_TYPE_BONUS) {
            if (!this.formData.period) {
                return;
            }

        }
        this.listOndemandPdfError()
    }

    listOndemandPdfError() {
        this.loadingModal.show();
        let request = {
            page: 1,
            rowPerPage: 0,
            docType: this.formData.docType,
            aboNumber: this.formData.aboNumber ?? '',
            year: this.formData.year ?? '',
            period: this.formData.period ? (`${this.formData.year}${this.formData.period}` ?? '') : '',
            orderBy: this.formData.docType == DOC_TYPE_WHT ? 'year desc' : 'period desc'
        }
        this.ondemandService.listOndemandPdfError(request).subscribe({
            next: (res) => {
                //console.log(res);
                this.requestExportZipPdf = request;
                setTimeout(() => {
                    this.loadingModal.hide();
                }, 500);

                let dataSearch = res?.data.map((item) => {
                    return {
                        ...item,
                        docType: this.formData.docType == DOC_TYPE_WHT ? DOC_TYPE_WHT : DOC_TYPE_BONUS,
                        isCheck: false
                    }
                })
                // this.searchRowData = [...dataSearch ?? []];
                this.setDataTable(dataSearch);
                this.requestId = res?.requestId;
                const newPageInfo = {
                    page: Number(res.pageinfo.page),
                    rowPerPage: Number(res.pageinfo.rowPerPage),
                    totalRecord: Number(res.pageinfo.totalRecord),
                    allpage: Math.ceil(res.pageinfo.totalRecord / res.pageinfo.rowPerPage)
                }
                this.pageinfo = newPageInfo;
                this.currentDatetime = moment().format('DD/MM/YYYY HH:mm:ss');
                this.searched = true;
                this.checkValideSearch = false;

            }, error: (e: any) => {
                //console.log(e);
                setTimeout(() => {
                    this.loadingModal.hide();
                }, 500);

                if(e?.status == 500){
                    let error = JSON.parse(e?.error?.error?.errorMsg);
                    if(error?.errno == 1146){
                        this.setDataTable([]);
                     this.requestId = e?.error?.requestId;
                     const newPageInfo =  {
                         page: 1,
                         rowPerPage: this.pageinfo.rowPerPage,
                         totalRecord: 0,
                         allpage: 0
                     }
                     this.pageinfo = newPageInfo;
                     this.currentDatetime = moment().format('DD/MM/YYYY HH:mm:ss');
                     this.searched = true;
                     this.checkValideSearch = false;
                     return;
                    }
                 }

                this.alertMessage('danger', 'Error!', 'bx bx-error-circle', e?.error?.error?.errorMsg);

            },
        });
    }

    async gotoPage(page: any) {
        if (page > 0 && page <= this.pageinfo.allpage) {
            this.pageinfo.page = page;
            this.listOndemandPdfError();
        }
    }

    changeYear() {
        if (this.formData.docType == DOC_TYPE_BONUS) {
            if (this.formData.year == '') {
                this.formData.period = '';
            }
        }
    }

    changeDoctype() {
        this.formData.year = '';
        this.formData.period = '';
        if (this.formData.docType == DOC_TYPE_BONUS) {
            this.gennerateYear(4)
        } else {
            this.gennerateYear(10)
        }
    }

    resetSearch() {
        this.formData.docType = DOC_TYPE_WHT;
        this.formData.aboNumber = '';
        this.formData.year = '';
        this.formData.period = '';
        this.checkValideSearch = false;
        this.accountLocalName = '';
    }

    modalDownloadPdf(data: any) {
        const messageAlertModal = new bootstrap.Modal(document.getElementById('messageAlertModal'));
        const self = this;
        this.messageAlert = {
            title: 'Confirm',
            message: 'Export PDF file ?',
            button: [
                {
                    label: 'Yes',
                    color: 'primary',
                    action: async function () {
                        self.downloadPdfFile(data);
                        messageAlertModal.hide();
                    }.bind(this)
                },
                {
                    label: 'Cancel', action: async function () {
                        messageAlertModal.hide();
                    }.bind(this)
                }
            ]
        }
        messageAlertModal.show();
    }

    downloadPdfFile(data: any) {
        this.ondemandService.getOndemandExportPdf(data?.filePath, data?.fileName).subscribe(
            {
                next: async (result: any) => {
                    let blob: any = new Blob([result], { type: result.type });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.setAttribute('target', '_blank');
                    link.setAttribute('href', url);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                },
                error: (error: any) => {
                    //console.log(error);
                }
            }
        );
    }

    updateResult :{failled : number ,success : number} = {
        failled: 0,
        success: 0
    };
    modalupdatestatuserror : any;
    async updateStatusFile() {
        // let allRows = this.getManualListAllRows();
        // console.log( this.agGridOption.gridApi.getSelectedRows());
        let rowSelected = this.agGridOption.gridApi.getSelectedRows();
        // let rowIsCheck = allRows.filter((data : any) => data.isCheck);
        if(rowSelected.length <= 0){
            this.alertMessage('danger', 'Error!', 'bx bx-error-circle', 'Please select row at least 1 row.');
            return;
        }

        this.updateResult = {failled: 0,success: 0};

        this.loadingModal.show();
        for (const row of rowSelected) {
          let result = await this.updateOndemandStatusPdf(row);
          if(result.success){
            this.updateResult.success += 1;
          }else{
            this.updateResult.failled += 1;
          }
        }
        this.loadingModal.hide();
        this.modalupdatestatuserror = new bootstrap.Modal(document.getElementById(`${this.elementname}modalupdatestatuserror`), { backdrop: 'static', keyboard: false });
        this.modalupdatestatuserror.show();


        // if(error){
        //     this.loadingModal.hide();
        //     this.errorMGS = errorMGS;
        //     this.modalupdatestatuserror = new bootstrap.Modal(document.getElementById(`${this.elementname}modalupdatestatuserror`), { backdrop: 'static', keyboard: false });
        //     this.modalupdatestatuserror.show();

        // }else{
        //     this.listOndemandPdfError()
        // }
        // this.alertMessage('danger', 'Update File Exist Error', 'bx bx-error-circle', errorMGS.join('<br>'));
        // const messageAlertModal = new bootstrap.Modal(document.getElementById('messageAlertModal'));
        // const self = this;
        // this.messageAlert = {
        //     title: 'Confirm',
        //     message: 'Export ZIP file PDF ?',
        //     button: [
        //         {
        //             label: 'Yes',
        //             color: 'primary',
        //             action: async function () {
        //                 self.downloadZipFilePdf();
        //                 messageAlertModal.hide();
        //             }.bind(this)
        //         },
        //         {
        //             label: 'Cancel', action: async function () {
        //                 messageAlertModal.hide();
        //             }.bind(this)
        //         }
        //     ]
        // }
        // messageAlertModal.show();
    }

    closeModalupdatestatuserror(){
        this.modalupdatestatuserror.hide();
        this.listOndemandPdfError();
    }

    async updateOndemandStatusPdf(value: Ondemand) : Promise<{ success: boolean; msg: string; }>{
        return new Promise(resolve => {
            this.ondemandService.updateOndemandStatusPdf(value).subscribe({
                next: (v: any) => {
                    if(v?.success){
                        resolve({
                            success : true,
                            msg : ''
                        });
                    }else{
                        resolve({
                            success : false,
                            msg : v?.error?.errorMsg
                        });
                    }
                }, error: (e) => {
                    resolve({
                        success : false,
                        msg : e?.error?.error?.errorMsg
                    });
                }
            });
        })
    }



    downloadZipFilePdf() {
        // if(this.requestExportZipPdf)
        this.ondemandService.getOndemandExportZip(this.requestExportZipPdf).subscribe(
            {
                next: async (result: any) => {
                    const buffer = this.base64ToBuffer(result);
                    let blob: any = new Blob([buffer], { type: 'application/zip' });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.setAttribute('target', '_blank');
                    link.setAttribute('href', url);
                    link.setAttribute('download', `${this.requestExportZipPdf.aboNumber}_${moment().format('DDMMYYYY_HHmmss')}.zip`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                },
                error: (error: any) => {
                    //console.log('error : ', error);
                }
            }
        );
    }
    base64ToBuffer(b64Data: any, contentType = 'application/zip', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    messageAlert: any = {
        title: '',
        message: '',
        button: []
    }

    //Alert Modal
    alertMessage(theme: string, title: string, icon: string | null, message: string) {
        const messageAlertModal = new bootstrap.Modal(document.getElementById('messageAlertModal'));
        const self = this;
        this.messageAlert = {
            theme: theme,
            title: title,
            icon: icon,
            message: message,
            button: [
                {
                    label: 'OK',
                    action: async function () {
                        messageAlertModal.hide();
                    }.bind(this),
                    color: 'warning'
                }
            ]
        };
        messageAlertModal.show();

    }


    checkName() {
        this.accountLocalName = '';
        this.loadingModal.show();
        this.ondemandService.searchabo({
            aboNumber: this.formData.aboNumber ?? '',
            detailLevelCd: 'MinDetail'
        }).subscribe({
            next: (res: any) => {
                setTimeout(() => {
                    this.loadingModal.hide();
                }, 500);

                if (res?.success) {
                    this.accountLocalName = res?.data[0]?.account?.accountMst?.accountLocalName
                }


            }, error: (e: any) => {
                //console.log(e);
                setTimeout(() => {
                    this.loadingModal.hide();
                }, 500);
                this.alertMessage('danger', 'Error!', 'bx bx-error-circle', e?.error?.error?.errorMsg);

            },
        });
    }

    getManualListAllRows() {
        let tempRowData: any = [];
        if (this.agGridOption.gridApi != undefined) {
            this.agGridOption.gridApi.forEachNode((node: { data: any; }) => tempRowData.push(node.data));
        }
        return tempRowData;
    }

    onSelectManualListIsDefault(data: any) {
        // //console.log("onSelectManualListIsDefault data.rowIndex = " , data);
    //     let allRows = this.getManualListAllRows();
    //    //console.log(allRows);

        // this.setDataTable(allRows);
    }

    setDataTable(newAllRow: any[]) {
        this.agGridOption.rowData = newAllRow;
        this.agGridOption.gridApi?.setRowData(this.agGridOption.rowData);
    }

    agGridReady(params:any) {
        this.agGridOption.gridApi = params.api;
      }

}
