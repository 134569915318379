import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OndemandComponent } from './ondemand.component';
import { ShareModule } from 'src/app/share.module';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ActionButtonOndemandPdf } from '../components/action-button-ondemand-pdf.component';
import { AgGridModule } from 'ag-grid-angular';


@NgModule({
  declarations: [
    OndemandComponent,
    ActionButtonOndemandPdf
  ],
  imports: [
    CommonModule,
    ShareModule,
    KeyFilterModule,
    AgGridModule.withComponents(
        [
            ActionButtonOndemandPdf
        ]
    )
  ]
})
export class OndemandModule { }
