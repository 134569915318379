import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OndemandImportComponent } from './ondemand-import.component';
import { ShareModule } from 'src/app/share.module';
import { ActionButtonOndemandImportLog } from '../components/action-button-ondemand-import-log.component';
import { AgGridModule } from 'ag-grid-angular';
import { ImportErrorComponent } from './import-error/import-error.component';
import { PdfErrorComponent } from './pdf-error/pdf-error.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CheckboxCellRendererComponent } from '../components/checkbox-cell-renderer.component';



@NgModule({
  declarations: [
    OndemandImportComponent,
    ActionButtonOndemandImportLog,
    ImportErrorComponent,
    PdfErrorComponent,
    CheckboxCellRendererComponent
  ],
  imports: [
    CommonModule,
    ShareModule,
    KeyFilterModule,
    AgGridModule.withComponents(
        [
            ActionButtonOndemandImportLog,
            CheckboxCellRendererComponent
        ]
    )
  ]
})
export class OndemandImportModule { }
