import { Component, OnInit, Input } from '@angular/core';

declare const bootstrap: any;

@Component({
    selector: 'app-message-alert-modal',
    templateUrl: './message-alert-modal.component.html',
    styleUrls: ['./message-alert-modal.component.scss']
})
export class MessageAlertModalComponent implements OnInit {


    messageAlertModal: any;
    @Input() messageAlert: any = {
        title: '',
        message: '',
        button: []
    }

    constructor() { }

    ngOnInit(): void {
    }

}
