import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import { SearchOrderPaymentURLParams } from '../models/orderpayment/orderpaymentRespone.model';
import { SearchOrderPaymentRespone } from '../models/orderpayment/orderpaymentURLParams.model';
import { ResponesCustom } from '../models/responedata.model';

@Injectable({
    providedIn: 'root',
})
export class OrderpaymentService {
    constructor(private http: HttpClient, private userService: UserService) {}

    searchOrderPayment(param: SearchOrderPaymentURLParams) {
        let url: string = `${environment.api_url}/apibo/call/searchorderpayment`;
        let params = new HttpParams()
            .set('page', param.page)
            .set('rowPerPage', param.rowPerPage)
            .set('startDate', new Date(param.startDate).toISOString())
            .set('endDate', new Date(param.endDate).toISOString())
            .set('noInv', param.noInv);

        return this.http.get<ResponesCustom<SearchOrderPaymentRespone[]>>(url, { params });
    }
}
