<c-nav variant="tabs">
    <a [active]="true" [cTabContent]="tabContent"   [tabPaneIdx]="0" cNavLink><h6 cCardTitle class="mb-2"><strong class="text-primary">Import Error</strong></h6></a>
    <a [cTabContent]="tabContent"   [tabPaneIdx]="1" cNavLink><h6 cCardTitle class="mb-2"><strong class="text-primary">PDF Error</strong></h6></a>
  </c-nav>
  <c-tab-content class="mb-3" #tabContent="cTabContent">
    <c-tab-pane class="p-3">
        <app-import-error *ngIf="tabContent.activeTabPaneIdx == 0"></app-import-error>
    </c-tab-pane>
    <c-tab-pane class="p-3">
       <app-pdf-error *ngIf="tabContent.activeTabPaneIdx == 1"></app-pdf-error>
    </c-tab-pane>
  </c-tab-content>



