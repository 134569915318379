<c-row>
    <c-col>
        <c-card class="mb-3">
            <c-card-body>
                <form [classList]="'mt-2'" cForm>
                    <c-row [classList]="'px-2'">
                        <c-col [lg]="12">
                            <c-row>
                                <c-col [lg]="4">
                                    <c-row class="mb-3">
                                        <c-col [lg]="6">
                                            <label for="search_order_no" class="p-1 float-end">Order No :</label>
                                        </c-col>
                                        <c-col [lg]="6">
                                            <input class="form-control" name="order_no" id="search_order_no" type="text"
                                                placeholder="number only"
                                                [ngClass]="{'is-invalid': searchOption.q.orderNo && (!validateNumber(searchOption.q.orderNo) || searchOption.q.orderNo.length > 20)}"
                                                [(ngModel)]="searchOption.q.orderNo" />
                                            <small id="feedback_order_no" class="invalid-feedback"
                                                *ngIf="searchOption.q.orderNo && !validateNumber(searchOption.q.orderNo)">
                                                Please enter only number.
                                            </small>
                                            <small id="feedback_order_no" class="invalid-feedback"
                                                *ngIf="searchOption.q.orderNo && searchOption.q.orderNo.length > 20">
                                                Please enter Max charater is 20.
                                            </small>
                                        </c-col>
                                    </c-row>
                                </c-col>
                                <c-col [lg]="4">
                                    <c-row class="mb-3">
                                        <c-col [lg]="6">
                                            <label for="search_invoice_no" class="p-1 float-end">Invoice No :</label>
                                        </c-col>
                                        <c-col [lg]="6">
                                            <input class="form-control" name="invoice_no" id="search_invoice_no"
                                                type="text" placeholder="number only"
                                                [ngClass]="{'is-invalid': searchOption.q.invoiceNumber && (!validateNumber(searchOption.q.invoiceNumber) || searchOption.q.invoiceNumber.length > 20)}"
                                                [(ngModel)]="searchOption.q.invoiceNumber" />
                                            <small id="feedback_order_no" class="invalid-feedback"
                                                *ngIf="searchOption.q.invoiceNumber && !validateNumber(searchOption.q.invoiceNumber)">
                                                Please enter only number.
                                            </small>
                                            <small id="feedback_order_no" class="invalid-feedback"
                                                *ngIf="searchOption.q.invoiceNumber && searchOption.q.invoiceNumber.length > 20">
                                                Please enter Max charater is 20.
                                            </small>
                                        </c-col>
                                    </c-row>
                                </c-col>
                                <c-col [lg]="4">
                                    <c-row class="mb-3">
                                        <c-col [lg]="6">
                                            <label for="search_credit_note_no" class="p-1 float-end">Debit / Credit Note No
                                                :</label>
                                        </c-col>
                                        <c-col [lg]="6">
                                            <input class="form-control" name="credit_note_no" id="search_credit_note_no"
                                                type="text"
                                                [ngClass]="{'is-invalid': searchOption.q.docNo &&  searchOption.q.docNo.length > 20}"
                                                [(ngModel)]="searchOption.q.docNo" />
                                            <small id="feedback_order_no" class="invalid-feedback"
                                                *ngIf="searchOption.q.docNo && searchOption.q.docNo.length > 20">
                                                Please enter Max charater is 20.
                                            </small>
                                        </c-col>
                                    </c-row>
                                </c-col>
                                <c-col [lg]="4">
                                    <c-row class="mb-3">
                                        <c-col [lg]="6">
                                            <label for="search_abo_no" class="p-1 float-end">ABO No :</label>
                                        </c-col>
                                        <c-col [lg]="6">
                                            <input class="form-control" name="abo_no" id="search_abo_no" type="text"
                                                placeholder="number only"
                                                [ngClass]="{'is-invalid': searchOption.q.volumeAboAccountCode && (!validateNumber(searchOption.q.volumeAboAccountCode) || searchOption.q.volumeAboAccountCode.length > 20)}"
                                                [(ngModel)]="searchOption.q.volumeAboAccountCode" />
                                            <small id="feedback_order_no" class="invalid-feedback"
                                                *ngIf="searchOption.q.volumeAboAccountCode && !validateNumber(searchOption.q.volumeAboAccountCode)">
                                                Please enter only number.
                                            </small>
                                            <small id="feedback_order_no" class="invalid-feedback"
                                                *ngIf="searchOption.q.volumeAboAccountCode && searchOption.q.volumeAboAccountCode.length > 20">
                                                Please enter Max charater is 20.
                                            </small>
                                        </c-col>
                                    </c-row>
                                </c-col>
                                <c-col [lg]="4">
                                    <c-row class="mb-3">
                                        <c-col [lg]="6">
                                            <label for="search_name_lastname" class="p-1 float-end">ABO Name
                                                :</label>
                                        </c-col>
                                        <c-col [lg]="6">
                                            <input class="form-control" name="name_lastname" id="search_name_lastname"
                                                type="text"
                                                [ngClass]="{'is-invalid': searchOption.q.taxPayerGivenName && searchOption.q.taxPayerGivenName.length > 50}"
                                                [(ngModel)]="searchOption.q.taxPayerGivenName" />
                                            <small id="feedback_order_no" class="invalid-feedback"
                                                *ngIf="searchOption.q.taxPayerGivenName && searchOption.q.taxPayerGivenName.length > 50">
                                                Please enter Max charater is 50.
                                            </small>
                                        </c-col>
                                    </c-row>
                                </c-col>
                                <c-col [lg]="4">
                                    <c-row>
                                        <c-col [lg]="6">
                                            <label class="w-100 text-end" cCol cLabel for="inputEmail-1">Status
                                                :</label>
                                        </c-col>
                                        <c-col [lg]="6">
                                            <c-row>
                                                <c-col [lg]="6" [xs]="10">
                                                    <c-form-check class="float-lg-start float-end">
                                                        <input cFormCheckInput id="search_status_success"
                                                            name="search_status_success" value="SUCCESS" type="checkbox"
                                                            checked (change)="onCheckboxStatusChange($event)" />
                                                        <label cFormCheckLabel
                                                            for="search_status_success">Success</label>
                                                    </c-form-check>
                                                </c-col>
                                                <c-col [lg]="6" [xs]="2">
                                                    <c-form-check class="float-lg-start float-end">
                                                        <input cFormCheckInput id="search_status_fail"
                                                            name="search_status_fail" value="FAILED" type="checkbox"
                                                            checked (change)="onCheckboxStatusChange($event)" />
                                                        <label cFormCheckLabel for="search_status_fail">Fail</label>
                                                    </c-form-check>
                                                </c-col>
                                            </c-row>
                                        </c-col>
                                    </c-row>
                                </c-col>
                                <c-col [lg]="4">
                                    <c-row class="mb-3">
                                        <c-col [lg]="6" [xs]="11">
                                            <label for="search_by_order_date" class="p-1 float-end">Search by order date
                                                :</label>
                                        </c-col>
                                        <c-col [lg]="6" [xs]="1">
                                            <input class="float-lg-start float-end form-check-input mt-2" cFormCheckInput type="checkbox"
                                                id="search_by_order_date_yes" [checked]="searchByDate"
                                                (change)="searchByDate = !searchByDate">
                                        </c-col>
                                    </c-row>
                                </c-col>
                                <c-col [lg]="4">
                                    <c-row class="mb-3">
                                        <c-col [lg]="6">
                                            <label for="search_name_lastname" class="p-1 float-end">From Date
                                                :</label>
                                        </c-col>
                                        <c-col [lg]="6">
                                            <p-calendar [(ngModel)]="searchOption.q.orderDateTimeFrom"
                                                [id]="'search_order_date_from'" [name]="'order_date_from'"
                                                dateFormat="dd/mm/yy" [styleClass]="'date-form text-center'"
                                                [disabled]="!searchByDate" [baseZIndex]="1030">
                                            </p-calendar>
                                        </c-col>
                                    </c-row>
                                </c-col>
                                <c-col [lg]="4">
                                    <c-row class="mb-3">
                                        <c-col [lg]="6">
                                            <label for="search_name_lastname" class="p-1 float-end">To
                                                Date
                                                :</label>
                                        </c-col>
                                        <c-col [lg]="6">
                                            <p-calendar [(ngModel)]="searchOption.q.orderDateTimeTo"
                                                [id]="'search_order_date_to'" [name]="'order_date_from'"
                                                dateFormat="dd/mm/yy" [styleClass]="'date-form text-center'"
                                                [disabled]="!searchByDate" [baseZIndex]="1030">
                                            </p-calendar>
                                        </c-col>
                                    </c-row>
                                </c-col>
                            </c-row>
                        </c-col>
                        <c-col [lg]="6">
                            <c-row>
                                <c-col [lg]="4"></c-col>
                                <c-col [lg]="4">
                                    <input name="search_search_btn" cButton color="primary" type="button" value="Search"
                                        class="rounded mb-2 w-100" (click)="searchOrderData()">
                                </c-col>
                                <c-col [lg]="4">
                                    <input name="search_search_btn" cButton color="primary-50" type="button"
                                        value="Reset" class="rounded w-100" (click)="resetSearch()"> <!--px-4 me-2 -->
                                </c-col>
                            </c-row>
                        </c-col>
                    </c-row>
                </form>
            </c-card-body>
        </c-card>
    </c-col>
</c-row>

<c-row class="mt-3">
    <c-col *ngIf="searched">
        <h3 cCardTitle class="mb-2"><strong class="text-primary">Result</strong></h3>
        <c-card class="mb-3">
            <c-card-body>
                <c-row>
                    <c-col [sm]="6">
                        <h6>Total {{searchOption.pageinfo.totalRecord}} records Update on {{currentDatetime}}</h6>
                    </c-col>
                </c-row>
                <c-row>
                    <c-col [sm]="12" class="mb-2">
                        <c-dropdown>
                            <button cButton cDropdownToggle id="btn_export_file" color="primary">
                                Export
                            </button>
                            <ul cDropdownMenu>
                                <li><a cDropdownItem (click)="exportExcel()">Excel</a></li>
                                <li><a cDropdownItem (click)="exportCSV()">CSV</a></li>
                            </ul>
                        </c-dropdown>
                        <div class="float-end">
                            <span class="me-1">Show</span>
                            <select class="me-1" id="search_option_limit" [(ngModel)]="searchOption.pageinfo.rowPerPage"
                                (change)="searchOrderData()">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                            <span>entries</span>
                        </div>
                    </c-col>
                    <c-col [sm]="12" class="mb-2">
                        <ag-grid-angular style="width: 100%; height: 50vh;" class="ag-theme-alpine"
                            [columnDefs]="orderColumnDefs" [rowData]="orderData"
                            [defaultColDef]="defaultColDef"
                            [frameworkComponents]="frameworkComponents" [enableCellTextSelection]="true"
                            [enableRangeSelection]="true">
                        </ag-grid-angular>
                    </c-col>
                    <c-col [sm]="12">
                        <app-paging-tool [pageinfo]="searchOption.pageinfo" (onClickPage)="gotoPage($event)">
                        </app-paging-tool>
                    </c-col>
                </c-row>
            </c-card-body>
        </c-card>
    </c-col>
</c-row>
<div class="modal fade" id="loadingModal" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-message-alert-modal [messageAlert]="messageAlert"></app-message-alert-modal>
