<c-row>
    <c-col>
        <h2 cCardTitle class="mb-2"><strong class="text-primary">Reconcile</strong></h2>
        <c-card class="mb-3">
            <c-card-body>
                <form [classList]="'mt-2'" cForm>
                    <c-row [classList]="'px-2'">
                        <c-col [lg]="3">
                            <c-row class="mb-3">
                                <c-col [lg]="5">
                                    <label for="search_date_option" class="pt-1 pb-1 float-end">Choose Date :</label>
                                </c-col>
                                <c-col [lg]="7">
                                    <select name="date_option" id="search_date_option" cSelect class="mb-3"
                                        (change)="selectDateOption($event)">
                                        <option value="today" selected>Today</option>
                                        <option value="yesterday">Yesterday</option>
                                        <option value="this week">This week</option>
                                        <option value="last week">Last week</option>
                                        <option value="this month">This month</option>
                                        <option value="last month">Last month</option>
                                        <option value="custom date">Custom Date</option>
                                    </select>
                                </c-col>
                            </c-row>
                        </c-col>
                        <c-col [lg]="3">
                            <c-row class="mb-3">
                                <c-col [lg]="5">
                                    <label for="search_start_date" class="p-1 float-end">Start Date :</label>
                                </c-col>
                                <c-col [lg]="7">
                                    <p-calendar [id]="'search_start_date'" [name]="'search_start_date'"
                                        dateFormat="dd/mm/yy" [(ngModel)]="searchOption.startDate"
                                        [styleClass]="'date-form'" [disabled]="!searchByDate">
                                    </p-calendar>
                                </c-col>
                            </c-row>
                        </c-col>
                        <c-col [lg]="3">
                            <c-row class="mb-3">
                                <c-col [lg]="5">
                                    <label for="search_end_date" class="p-1 float-end">End Date :</label>
                                </c-col>
                                <c-col [lg]="7">
                                    <p-calendar [id]="'search_end_date'" [name]="'search_end_date'"
                                        dateFormat="dd/mm/yy" [(ngModel)]="searchOption.endDate"
                                        [styleClass]="'date-form'" [disabled]="!searchByDate">
                                    </p-calendar>
                                </c-col>
                            </c-row>
                        </c-col>
                        <c-col [lg]="3">
                            <c-row class="mb-3">
                                <c-col [lg]="7" [xs]="12">
                                    <input name="search_search_btn" cButton color="primary" type="button" value="Search"
                                        class="px-4 me-2 w-100 rounded" (click)="getReconcile()">
                                </c-col>
                            </c-row>
                        </c-col>
                    </c-row>
                </form>
            </c-card-body>
        </c-card>
    </c-col>
</c-row>
<c-row *ngIf="searched">
    <c-col>
        <c-card class="mb-3">
            <c-card-body>
                <c-row>
                    <c-col [sm]="6">
                        <h6>Last update on : {{currentDatetime}}</h6>
                    </c-col>
                    <c-col [sm]="6">
                        <c-row class="mb-3">
                            <c-col [sm]="9" class="mb-2">
                                <label for="time_of_reface" class="p-1 float-end">auto refresh </label>
                                <p-inputSwitch [styleClass]="'float-end mt-1'" [(ngModel)]="autoRefresh">
                                </p-inputSwitch>
                            </c-col>
                            <c-col [sm]="3">
                                <select name="date_option" id="search_date_option" cSelect class="mb-3"
                                    [(ngModel)]="timeOfReface" [disabled]="!autoRefresh">
                                    <option value="60" selected>60 second</option>
                                    <option value="360">5 minute</option>
                                    <option value="720">10 minute</option>
                                </select>
                            </c-col>
                        </c-row>
                    </c-col>
                </c-row>
                <c-row>
                    <c-col class="p-3" *ngFor="let reconcile of reconcileData" [sm]="reconcile.size">
                        <c-card class="mb-2">
                            <c-card-header>
                                <h6 *ngIf="reconcile.processType == 'Cancel Order(Credit Note)'">
                                    <span>Cancel Order [Credit Note]</span>
                                </h6>
                                <h6 *ngIf="reconcile.processType == 'Cancel Order(Debit Note)'">
                                    <span>Cancel Order [Debit Note]</span>
                                </h6>
                                <h6 *ngIf="!reconcile.processType.includes('Cancel Order')">
                                    {{reconcile.processType}}
                                </h6>
                            </c-card-header>
                            <c-card-body>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col"> </th>
                                            <th scope="col">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <div class="dot bg-success me-1"></div>
                                                    <span>Success</span>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <div class="dot bg-warning me-1"></div>
                                                    <span>Pending</span>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <div class="dot bg-danger me-1"></div>
                                                    <span>Failed</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let step of reconcile.steps">
                                            <th class="border-0" scope="row">{{step.stepName}}</th>
                                            <td class="border-0 text-center">
                                                <a href="javascript:void(0)"
                                                    (click)="newTabTransaction(reconcile.processType, step.stepName, 'success')"
                                                    class="nounderline">{{step.success}}</a>
                                            </td>
                                            <td class="border-0 text-center">
                                                <a href="javascript:void(0)"
                                                    (click)="newTabTransaction(reconcile.processType, step.stepName, 'pending')"
                                                    class="nounderline">{{step.pending}}</a>
                                            </td>
                                            <td class="border-0 text-center">
                                                <a href="javascript:void(0)"
                                                    (click)="newTabTransaction(reconcile.processType, step.stepName, 'failed')"
                                                    class="nounderline text-danger">{{step.failed}}</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </c-card-body>
                        </c-card>
                    </c-col>
                </c-row>
            </c-card-body>
        </c-card>
    </c-col>
</c-row>

<div class="modal fade" id="loadingModal" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-message-alert-modal [messageAlert]="messageAlert"></app-message-alert-modal>
