<c-row>
    <c-col>

        <c-card class="mb-3">
            <c-card-body>
                <form [classList]="'mt-2'" cForm>
                    <c-row [classList]="'px-2'">
                        <c-col [lg]="6">
                            <c-row class="mb-3">
                                <c-col [lg]="5">
                                    <label for="search_start_date" class="p-1 float-end">Start Date :</label>
                                </c-col>
                                <c-col [lg]="6">
                                    <p-calendar [id]="'search_start_date'" [name]="'search_start_date'"
                                        dateFormat="dd/mm/yy" [(ngModel)]="searchOption.startDate"
                                        [readonlyInput]="true"
                                        [styleClass]="'date-form'">
                                    </p-calendar>
                                </c-col>
                            </c-row>
                        </c-col>
                        <c-col [lg]="6">
                            <c-row class="mb-3">
                                <c-col [lg]="3">
                                    <label for="search_end_date" class="p-1 float-end">End Date :</label>
                                </c-col>
                                <c-col [lg]="6">
                                    <p-calendar [id]="'search_end_date'" [name]="'search_end_date'"
                                        dateFormat="dd/mm/yy" [(ngModel)]="searchOption.endDate"
                                        [readonlyInput]="true"
                                        [styleClass]="'date-form'">
                                    </p-calendar>
                                </c-col>
                            </c-row>
                        </c-col>
                        <c-col [lg]="6">
                            <c-row class="mb-3">
                                <c-col [lg]="5">
                                    <label for="search_start_date" class="p-1 float-end">Document Type :</label>
                                </c-col>
                                <c-col [lg]="6">
                                    <select name="{{elementname}}docType" class="form-select"
                                        [(ngModel)]="searchOption.docType" cSelect>
                                        <option *ngFor="let option of listDocType" value="{{ option.value }}">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                </c-col>
                            </c-row>
                        </c-col>
                        <c-col [lg]="6">
                            <c-row class="mb-3">
                                <c-col [lg]="3">
                                    <label for="search_end_date" class="p-1 float-end">File Name :</label>
                                </c-col>
                                <c-col [lg]="6">
                                    <input required class="form-control" name="{{elementname}}fileName"
                                        id="{{elementname}}fileName" type="text" [(ngModel)]="searchOption.fileName" />
                                </c-col>
                            </c-row>
                        </c-col>

                        <c-col [lg]="6">
                            <c-row class="mb-3">
                                <c-col [lg]="5">
                                    <label for="search_start_date" class="p-1 float-end">ABO :</label>
                                </c-col>
                                <c-col [lg]="6">
                                    <input required class="form-control" name="{{elementname}}aboNumber"
                                        id="{{elementname}}aboNumber" type="text"
                                        [(ngModel)]="searchOption.aboNumber" />
                                </c-col>
                            </c-row>
                        </c-col>
                        <c-col [lg]="6"></c-col>
                        <c-col [lg]="6">
                            <c-row class="mb-3">
                                <c-col [offset]="{lg: 5}" [lg]="3"  >
                                    <input name="search_search_btn" cButton color="primary" type="button" value="Search"
                                        class="px-4 me-2 w-100 rounded" (click)="searchLogOndemand()">
                                </c-col>
                                <c-col [lg]="3">
                                    <input name="search_search_btn" cButton color="primary-50" type="button"
                                        value="Reset" class="rounded w-100" (click)="resetSearch()"> <!--px-4 me-2 -->
                                </c-col>
                            </c-row>
                        </c-col>
                    </c-row>
                </form>
            </c-card-body>
        </c-card>
    </c-col>
</c-row>


<c-row *ngIf="searched">
    <c-col>
        <c-card class="mb-3">
            <c-card-body>
                <c-row>
                    <c-col [sm]="6">
                        <h6>Total {{pageinfo.totalRecord}} records Update on {{currentDatetime}}</h6>
                    </c-col>
                </c-row>
                <c-row>
                    <c-col [sm]="12">
                        <ag-grid-angular class="ag-theme-alpine" domLayout="autoHeight" [rowData]="agGridOption.rowData"
                            [columnDefs]="agGridOption.columnDefs"
                            [frameworkComponents]="agGridOption.frameworkComponents" [enableCellTextSelection]="true">
                        </ag-grid-angular>
                    </c-col>
                    <c-col [classList]="'mt-2'" [sm]="12">
                        <app-paging-tool [pageinfo]="pageinfo" (onClickPage)="gotoPage($event)">
                        </app-paging-tool>
                    </c-col>
                </c-row>
            </c-card-body>
        </c-card>
    </c-col>
</c-row>





<div class="modal fade" id="ondemand_detail_modal" tabindex="-1" aria-labelledby="Detail Modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" style="word-break: break-all;">
                <div *ngIf="detailLog" class="table_data_modal" aria-hidden="true">
                    <c-row [gutter]="{gy: 3}">

                        <c-col class="col-3">
                            <h6>UID : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{detailLog.id}}
                        </c-col>

                        <c-col class="col-3">
                            <h6>File Name : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{detailLog.fileName}}
                        </c-col>

                        <c-col class="col-3">
                            <h6>Import type : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{detailLog.importId}}
                        </c-col>

                        <c-col class="col-3">
                            <h6>Year : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{detailLog.year}}
                        </c-col>

                        <c-col class="col-3">
                            <h6>Period : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{detailLog.period}}
                        </c-col>

                        <c-col class="col-3">
                            <h6>ABO Number : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{detailLog.aboNumber}}
                        </c-col>

                        <c-col class="col-3">
                            <h6>Error Description : </h6>
                        </c-col>
                        <c-col class="col-9">
                            {{detailLog.errorDesc}}
                        </c-col>

                    </c-row>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"> Close
                </button>
            </div> -->
        </div>
    </div>
</div>

<app-message-alert-modal [messageAlert]="messageAlert"></app-message-alert-modal>
<div class="modal fade" id="{{elementname}}loadingModal" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                </div>
            </div>
        </div>
    </div>
</div>
