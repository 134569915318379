<c-row >
    <c-col>
     <h2 cCardTitle class="mb-2"><strong class="text-primary">User Managment</strong></h2>
    </c-col>
  </c-row>
  <c-row class="mb-2">
    <c-col>
      <c-card>
        <c-card-body>
          <c-row>
            <label cLabel="col" cCol [xl]="2" [lg]="3" for="inputUserName">
              User Name :
            </label>
            <c-col [xl]="6" [lg]="5" class="mb-2">
              <input #inputUserName cFormControl id="inputUserName" name="inputUserName" type="text"
                [(ngModel)]="searchOptions.userName" />
            </c-col>
            <c-col [xl]="4" [lg]="4">
              <div class="d-grid gap-2 d-md-flex">
                <button (click)="onClickSearch()" class="me-md-2" cButton type="submit">Search</button>
                <button (click)="onClickClear()" cButton color="secondary">Clear</button>
              </div>
            </c-col>
          </c-row>
        </c-card-body>
      </c-card>
    </c-col>
  </c-row>
  <c-row>
    <c-col>
      <c-card>
        <c-card-body>
          <!-- <c-row class="mb-2">
            <c-col>
              <div class="d-grid gap-2 d-md-flex">
                <button (click)="onClickAddCondition()" *ngIf="actionBtnOptions.addCondition" class="me-md-2" cButton
                  type="submit" color="dark">Add New Condition</button>
                <input hidden id="browseAboCsv" name="browseAboCsv" class="input-hide"
                  (change)="changeSelectedFile($event)" type="file" [accept]="allowedTypeFile" />
                <button class="me-2" (click)="clickBrowesFileBtn('browseAboCsv')" cButton type="submit">Upload ABO
                  csv</button>
                <button class="me-2" cButton type="submit">Refresh</button>
              </div>
            </c-col>
          </c-row> -->
          <c-row class="mb-2">
            <c-col>
              <ag-grid-angular style="width: 100%; height: 50vh;" class="ag-theme-alpine" [rowHeight]="50"
                [columnDefs]="ruleColumnDefs" [frameworkComponents]="frameworkComponents"  [rowData]="usernameRoleList" [enableCellTextSelection]="true"
                [enableRangeSelection]="true" (gridReady)="onGridReady($event)"
                [suppressRowClickSelection]="true" >
              </ag-grid-angular>
            </c-col>
          </c-row>
          <c-row>
            <c-col>
              <app-paging-tool [pageinfo]="pageInfo" (onClickPage)="gotoPage($event)">
              </app-paging-tool>
            </c-col>
          </c-row>
        </c-card-body>
      </c-card>
    </c-col>
  </c-row>
<!-- alignment="center" -->
  <c-modal   [visible]="asingRoleConditionModal"
  backdrop="static"
  id="asingRoleConditionModalId"
  class="modal fade" tabindex="-1"
>
  <c-modal-header>
    <h5 cModalTitle><span style="color: green;">EDIT : </span> User Role
    </h5>
    <button  (click)="closeAsingRoleConditionModal()" cButtonClose></button>
  </c-modal-header>
  <c-modal-body>

    <c-row class="mb-3">
        <label cLabel="col" cCol [sm]="3" for="inputUserName">
          User Name :
        </label>
        <c-col [sm]="5" class="mb-2">
          <input cFormControl id="inputUserRowName" name="inputUserRowName" type="text" [disabled]="true" [(ngModel)]="usernameRole.username" />
        </c-col>
      </c-row>
      <c-row class="mb-3">
        <label cLabel="col" cCol [sm]="3" for="inputUserCurrentRoleName">
          Current Role :
        </label>
        <c-col  [sm]="5" class="mb-2">
          <input cFormControl id="inputUserCurrentRoleName" name="inputUserCurrentRoleName" type="text" [disabled]="true" [(ngModel)]="usernameRole.role" />
        </c-col>
      </c-row>
      <c-row class="mb-3">
        <label cLabel="col" cCol [sm]="3" for="select_newrow">
          New Role :
        </label>
        <c-col  [sm]="5" class="mb-2">
        <select name="select_newrow" class="form-select"
            aria-label="select_newrow" [(ngModel)]="selectedRole.name"
            cSelect>
            <option *ngFor="let option of roles" [value]="option.name">
                {{ option.name }}
            </option>
        </select>
        </c-col>
      </c-row>


  </c-modal-body>
  <c-modal-footer>
    <button (click)="saveCondition(selectedRole)" cButton color="primary" [disabled]="!isValidAbo">Save</button>
    <button (click)="closeAsingRoleConditionModal()" cButton color="secondary">
      Cancel
    </button>
  </c-modal-footer>
</c-modal>


<div class="modal fade" id="loadingModal" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-message-alert-modal [messageAlert]="messageAlert"></app-message-alert-modal>
