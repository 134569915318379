import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { DateTimeRenderer } from '../components/datetime-cell-renderer.component';
import { ApilogComponent } from './apilog.component';
import { DetailBtnCellRenderer } from '../components/detail-button-cell-renderer.component';
import { ShareModule } from './../../share.module';
import { ActionButtonApiLog } from '../components/action-button-api-log.component';

@NgModule({
    declarations: [
        ApilogComponent,
        ActionButtonApiLog
    ],
    imports: [
        CommonModule,
        ShareModule,
        RouterModule,
        AgGridModule.withComponents(
            [
                DetailBtnCellRenderer,
                DateTimeRenderer,
                ActionButtonApiLog
            ]
        ),
    ]
})
export class ApilogModule { }
