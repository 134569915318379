<c-row >
    <c-col>
     <h2 cCardTitle class="mb-2"><strong class="text-primary">Ondemand PDF</strong></h2>
    </c-col>
</c-row>

<c-row>
    <c-col>
        <c-card class="mb-3">
            <c-card-body>
                <form novalidate cForm class="needs-validation mt-2" cForm>
                    <c-row [classList]="'px-2'">
                        <c-col [lg]="12">
                            <c-row>
                                <c-col [lg]="6">
                                    <c-row class="mb-3">
                                        <c-col [lg]="5">
                                            <label for="{{elementname}}docType" class="p-1 float-end">Document Type :</label>
                                        </c-col>
                                        <c-col [lg]="7">
                                            <select disabled (change)="changeDoctype()" name="{{elementname}}docType" class="form-select" [(ngModel)]="formData.docType"
                                                    cSelect>
                                                    <option *ngFor="let option of listDocType" value="{{ option.value }}">
                                                        {{ option.name }}
                                                    </option>
                                                </select>
                                        </c-col>
                                    </c-row>
                                </c-col>
                                <c-col [lg]="6">
                                    <c-row class="mb-3">
                                        <c-col [lg]="3">
                                            <label for="{{elementname}}year" class="p-1 float-end">Tax Year <span class="star-required"></span>:</label>
                                        </c-col>
                                        <c-col [lg]="6">
                                            <select required   name="{{elementname}}year" class="form-select" [(ngModel)]="formData.year"
                                                    [ngClass]="{'is-invalid': !formData.year && checkValideSearch}"
                                                    cSelect (change)="changeYear()">
                                                    <option *ngFor="let option of last10MonthsList" value="{{ option.value }}">
                                                        {{ option.label }}
                                                    </option>
                                                </select>
                                                <small id="{{elementname}}aboNumberfeedback" class="invalid-feedback"
                                                *ngIf="!formData.year && checkValideSearch">
                                                Required value.
                                            </small>
                                        </c-col>
                                    </c-row>
                                </c-col>

                                <c-col [lg]="6">
                                    <c-row class="mb-3">
                                        <c-col [lg]="5">
                                            <label for="{{elementname}}aboNumber" class="p-1 float-end">ABO No <span  class="star-required"></span> :</label>
                                        </c-col>
                                        <c-col [lg]="4">
                                            <input required class="form-control"  name="{{elementname}}aboNumber" id="{{elementname}}aboNumber" type="text"
                                                [ngClass]="{'is-invalid': !formData.aboNumber && checkValideSearch}"
                                                placeholder="number only"  [(ngModel)]="formData.aboNumber"  [pKeyFilter]="'int'" (ngModelChange)="accountLocalName = '';" />
                                                <small id="{{elementname}}aboNumberfeedback" class="invalid-feedback"
                                                *ngIf="!formData.aboNumber && checkValideSearch">
                                                Required value.
                                            </small>
                                            <small id="{{elementname}}aboNumberCheckName"    *ngIf="accountLocalName">
                                                {{accountLocalName}}
                                            </small>
                                        </c-col>
                                        <!-- <c-col [lg]="2">
                                            <c-row> -->
                                                <c-col [lg]="3">
                                                    <input [disabled]="!formData.aboNumber" name="check_name_btn" cButton color="primary" type="button" value="ตรวจสอบชื่อ"
                                                        class="rounded mb-2 w-100" (click)="checkName()">
                                                </c-col>
                                            <!-- </c-row>
                                        </c-col> -->
                                    </c-row>
                                </c-col>

                                <c-col [hidden]="formData.docType == 'wht'" [lg]="6">
                                    <c-row class="mb-3">
                                        <c-col [lg]="3">
                                            <label for="{{elementname}}period" class="p-1 float-end">Period
                                                :</label>
                                        </c-col>
                                        <c-col [lg]="6">
                                            <select required [disabled]="formData.year == ''"  name="{{elementname}}period" class="form-select" [(ngModel)]="formData.period"
                                                    cSelect>
                                                    <option *ngFor="let option of periodList" value="{{ option.value }}">
                                                        {{ option.month }}
                                                    </option>
                                                </select>
                                        </c-col>
                                    </c-row>
                                </c-col>

                            </c-row>
                        </c-col>
                        <c-col [lg]="6">
                            <c-row>
                                <c-col [lg]="5"></c-col>
                                <c-col [lg]="3">
                                    <input name="search_btn" cButton color="primary" type="button" value="Search"
                                        class="rounded mb-2 w-100" (click)="searchListOndemand()">
                                </c-col>
                                <c-col [lg]="3" >
                                    <input name="reset_btn" cButton color="primary-50" type="button"
                                        value="Reset" class="rounded w-100" (click)="resetSearch()"> <!--px-4 me-2 -->
                                </c-col>
                            </c-row>
                        </c-col>
                        <!-- <c-col [lg]="6">
                            <c-row>
                                <c-col [lg]="3">
                                    <input [disabled]="!formData.aboNumber" name="check_name_btn" cButton color="primary" type="button" value="ตรวจสอบชื่อ"
                                        class="rounded mb-2 w-100" (click)="checkName()">
                                </c-col>
                            </c-row>
                        </c-col> -->
                    </c-row>
                </form>
            </c-card-body>
        </c-card>
    </c-col>
</c-row>


    <c-row *ngIf="searched">
        <c-col>
            <c-card class="mb-3">
                <c-card-body>
                    <c-row>
                        <c-col [sm]="6">
                            <h6>Total {{pageinfo.totalRecord}} records Update on {{currentDatetime}}</h6>
                        </c-col> </c-row>
                    <c-row>
                        <c-col [sm]="2">
                            <input [disabled]="searchRowData.length <= 0" name="search_search_btn" cButton color="primary" type="button" value="Export PDF"
                            class="rounded mb-2 w-100" (click)="modalDownloadZipPdf()">
                        </c-col>

                    </c-row>
                    <c-row>
                        <c-col [sm]="12">
                            <ag-grid-angular style="width: 100%; height: 50vh" class="ag-theme-alpine"
                                [rowData]="searchRowData" [columnDefs]="listSearchOndemand.columnDefs"
                                [frameworkComponents]="listSearchOndemand.frameworkComponents" [enableCellTextSelection]="true">
                            </ag-grid-angular>
                        </c-col>
                        <c-col [classList]="'mt-2'" [sm]="12">
                            <app-paging-tool [pageinfo]="pageinfo" (onClickPage)="gotoPage($event)">
                            </app-paging-tool>
                        </c-col>
                    </c-row>
                </c-card-body>
            </c-card>
        </c-col>
    </c-row>


<app-message-alert-modal [messageAlert]="messageAlert"></app-message-alert-modal>
<div class="modal fade" id="loadingModal" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                </div>
            </div>
        </div>
    </div>
</div>
