<div class="container-fluid bg-light">
    <div class="container pt-5">
        <c-row>
            <c-col>
                <h2 cCardTitle class="mb-2"><strong class="text-primary">Order No : {{orderDetail.orderNo}}</strong>
                </h2>
                <c-card class="mb-3">
                    <c-card-body>
                        <c-row>
                            <c-col [sm]="12">
                                <c-row>
                                    <label [sm]="2" cCol cLabel="col" class="text-end"><strong>Order date
                                            :</strong></label>
                                    <label [sm]="10" cCol cLabel="col">{{orderDetail.orderDateTime}}</label>
                                </c-row>
                                <c-row>
                                    <label [sm]="2" cCol cLabel="col" class="text-end"><strong>Order for (volume)
                                            :</strong></label>
                                    <label [sm]="10" cCol cLabel="col">{{orderDetail.orderFor}}</label>
                                </c-row>
                                <c-row>
                                    <label [sm]="2" cCol cLabel="col" class="text-end"><strong>Tax ID
                                            :</strong></label>
                                    <label [sm]="10" cCol cLabel="col">{{orderDetail.taxId}}</label>
                                </c-row>
                                <c-row>
                                    <label [sm]="2" cCol cLabel="col" class="text-end"><strong>Order by
                                            :</strong></label>
                                    <label [sm]="10" cCol cLabel="col">{{orderDetail.orderAccount}}</label>
                                </c-row>
                                <c-row>
                                    <label [sm]="2" cCol cLabel="col" class="text-end"><strong> Group order (Parent)
                                            :</strong></label>
                                    <label [sm]="10" cCol cLabel="col">{{orderDetail.groupOrderId}}</label>
                                </c-row>
                            </c-col>
                        </c-row>
                    </c-card-body>
                </c-card>
            </c-col>
        </c-row>

        <c-row class="mt-3">
            <c-col>
                <h3 cCardTitle class="mb-2"><strong class="text-primary">Document</strong></h3>
                <c-card class="mb-3">
                    <c-card-body>
                        <c-row>
                            <c-col [sm]="12" class="mb-2">
                                <div class="float-end">
                                    <span class="me-1">Show</span>
                                    <select class="me-1" id="search_option_limit"
                                        [(ngModel)]="orderRevision.pageinfo.rowPerPage" (change)="getOrderRevision()">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                    </select>
                                    <span>entries</span>
                                </div>
                            </c-col>
                            <c-col [sm]="12" class="mb-2">
                                <ag-grid-angular style="width: 100%; height: 50vh;" class="ag-theme-alpine"
                                    [columnDefs]="orderDetailColumnDefs" [rowData]="orderRevision.data"
                                    [frameworkComponents]="frameworkComponents" [enableCellTextSelection]="true"
                                    [enableRangeSelection]="true">
                                </ag-grid-angular>
                            </c-col>
                            <c-col [sm]="12">
                                <app-paging-tool [pageinfo]="orderRevision.pageinfo"
                                    (onClickPage)="gotoPage($event.target.value)">
                                </app-paging-tool>
                            </c-col>
                        </c-row>
                    </c-card-body>
                </c-card>
            </c-col>
        </c-row>
    </div>
</div>
<input id="input_upload_kinesis" type="file" name="input_upload_kinesis" style="display: none;"
    (change)="getKinesisFile($event)" accept=".json,application/json">
<div class="modal fade" id="loadingModal" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                        aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade "  id="confirmManageModalAlert" tabindex="-1" aria-labelledby="confirmManageModalAlert"
    aria-hidden="true">
    <div class="modal-dialog" [ngClass]="{'modal-lg': updateinetConfirm.isShowUpdateinet}">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmManageModalAlert">{{messageAlert.title}}</h5>
            </div>
            <div class="modal-body">
                <div [innerHTML]="messageAlert.message"></div>

                <form [classList]="'mt-2'" cForm>

                  <c-row *ngIf="typeDeleteConfirm.isShowDeleteType" class="mb-3 mt-2">
                    <c-col [sm]="12" class="py-2">
                        <label for="typeDeleteConfirm py-2" >Type "delete" to confirm</label>
                    </c-col>
                    <c-col [sm]="12">
                        <input class="form-control" name="typeDeleteConfirm" id="typeDeleteConfirm" type="text"
                        [ngClass]="{'is-invalid': typeDeleteConfirm.deleteSubmit && typeDeleteConfirm.deleteText != 'delete'}"
                            [(ngModel)]="typeDeleteConfirm.deleteText" #isTnvalid="ngModel" />
                        <small id="feedback_order_no" class="invalid-feedback"
                            *ngIf="typeDeleteConfirm.deleteSubmit && typeDeleteConfirm.deleteText  != 'delete'" >
                            Please enter correct information.
                        </small>
                    </c-col>
                </c-row>

                <c-row *ngIf="updateinetConfirm.isShowUpdateinet" class="mb-3 mt-2">
                    <c-col [sm]="'auto'" class="py-2">
                        <b for="updateinetConfirm py-2" style="color: #009589;" >NEW transaction code :</b>
                    </c-col>
                    <c-col [sm]="7">
                        <input class="form-control" name="updateinetConfirm" id="updateinetConfirm" type="text"
                        [ngClass]="{'is-invalid': updateinetConfirm.updateinetSubmit && !updateinetConfirm.transactionCode}"
                            [(ngModel)]="updateinetConfirm.transactionCode" #isTnvalid="ngModel" />
                        <small id="feedback_order_no" class="invalid-feedback"
                            *ngIf="updateinetConfirm.updateinetSubmit && !updateinetConfirm.transactionCode" >
                            Please enter value.
                        </small>
                    </c-col>
                </c-row>


                </form>


            </div>
            <div class="modal-footer">
                <button *ngFor="let btn of messageAlert.button" type="button" class="btn"
                    [ngClass]="{'btn-primary': btn.color == 'primary','btn-secondary':btn.color == 'secondary' || !btn.color,'btn-danger-50':btn.color == 'danger'}"
                    (click)="btn.action()">{{btn.label}}</button>
            </div>
        </div>
    </div>
</div>

<app-message-alert-modal [messageAlert]="messageAlert"></app-message-alert-modal>
